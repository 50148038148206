<div class="bg-gray-100 p-6">
  <mat-icon
    id="close-icon"
    style="float: right; cursor: pointer"
    class="close-icon"
    [mat-dialog-close]="true"
  >
    close
  </mat-icon>

  <div class="mx-auto max-w-md">
    <h1 class="text-2xl font-bold mb-8">
      {{ addedit ? "Edit" : "Add" }} Media
    </h1>

    <form (ngSubmit)="save()" [formGroup]="form">
      <mat-form-field appearance="outline" class="w-full">
        <mat-select
          placeholder="Select Media Channel"
          formControlName="mediaChannelId"
        >
          <mat-option
            *ngFor="let option of mediaChannelNameList"
            [value]="option.id"
          >
            {{ option.mediaChannelName }}
          </mat-option>
          <!-- *ngIf="mediaChannelNameList.length === 0" -->
          <mat-option [value]="'noOptions'">No options available</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('mediaChannelId').hasError('required')">
          Media Channel is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Enter Media Name</mat-label>
        <input matInput formControlName="mediaName" required />
        <mat-error *ngIf="form.get('mediaName').hasError('required')">
          Media Name is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Enter Address</mat-label>
        <input matInput formControlName="address" required />
        <mat-error *ngIf="form.get('address').hasError('required')">
          Address is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Enter Concerned Person</mat-label>
        <input matInput formControlName="concernedPerson" required />
        <mat-error *ngIf="form.get('concernedPerson').hasError('required')">
          Concerned Person is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Enter Contact Number</mat-label>
        <input
          matInput
          formControlName="contactNumber"
          type="number"
          required
        />
        <mat-error *ngIf="form.get('contactNumber').hasError('required')">
          Contact Number is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Enter Website</mat-label>
        <input matInput formControlName="website" required />
        <mat-error *ngIf="form.get('website').hasError('required')">
          Website is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Enter e-Mail</mat-label>
        <input matInput formControlName="email" required />
        <mat-error *ngIf="form.get('email').hasError('required')">
          E-mail is required.
        </mat-error>
      </mat-form-field>

      <button
        type="submit"
        class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
        [disabled]="!form.valid"
      >
        Save
      </button>
      <div class="text-center text-red-500 mt-2">
        {{ errorMessage }}
      </div>
    </form>
  </div>
</div>
