
  
  <mat-card [class.mat-elevation-z8]="true" style="margin: 1.4rem;">

    <mat-card-content>
      <div class=" w-full  p-4">
        <div class="lg:flex lg:items-center lg:justify-between">
          <div class="min-w-0 flex-1">
            <h2 class="text-2xl font-bold">User Module Mapping</h2>
  
          </div>
       
        </div>
  
      </div>
      <div class="w-full">
        <form class="w-full" [formGroup]="searchForm" (ngSubmit)="onSearch()">   
            <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
            <div class="relative">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search User"
                onKeyPress="if(this.value.length==10) return false;" type="number"
                 formControlName="mobileNumber" required />
                 <div *ngIf="searchForm.get('mobileNumber').hasError('pattern') && searchForm.get('mobileNumber').touched" class="text-red-500">
                    Mobile number must be 10 digits.
                </div>
                <button type="submit"  class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
            </div>
        </form>
      </div>
      <!-- <div class="w-full p-2">
        <mat-accordion *ngIf="user.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                User data
                </mat-panel-title>
                <mat-panel-description>
                Details and information about user.
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="border-t border-gray-200">
                <dl>
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                            Full name
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                           {{user[0].userFirstName}} {{user[0].userLastName}}
                        </dd>
                    </div>
                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                            Username
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {{user[0].userName}}
                        </dd>
                    </div>
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                            User type
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" *ngIf="user[0].isCfcUser">
                            CFC User
                        </dd>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" *ngIf="user[0].isDptUser">
                            Department User
                        </dd>
                    </div>
                   
                </dl>
            </div>
           
            </mat-expansion-panel>
          </mat-accordion>
      </div> -->
      <div class="w-full p-4" *ngIf="user.length != 0">
        <h1>Module List<br>
            <label class="text-sm text-gray-400">Please select below modules</label>

        </h1>
        <div class="container mx-auto p-4">
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <button 
              *ngFor="let module of modules"
              (click)="toggleSelected(module)"
              [class.selected]="module.selected"
              class="p-4 bg-neutral-200 text-dark rounded shadow hover:bg-indigo-300"
            >
            {{module.name}}
            </button>
          </div>
        </div>
        <!-- <div class="card-grid">

            <div class="card" *ngFor="let module of modules"
            (click)="toggleSelected(module)" 
            [class.selected]="module.selected">
                <i class="fas fa-star"></i> 
                <h2>{{module.name}}</h2>
                <p>{{module.url}}</p>
            </div>
        </div> -->
      </div>
      <div class="float-right p-4" >
        <button type="button" type="button" (click)="cancel()" class="text-sky-600 bg-sky-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
              Go back
            </button>
            <button type="submit"
            [disabled]="selectedModules.length == 0" (click)="saveUserModuleMapping()" class="disabled:bg-gray-300  text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Submit</button>
    </div>
     
  
  
    </mat-card-content>
  
  </mat-card>