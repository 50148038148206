import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ZoneService {
  constructor(private httpClient: HttpClient) {}

  getZone() {
    var url = `${environment.commonUrl}` + "/api/master/zone/getAllDashboard";

    return new Promise((resolve) => {
      this.httpClient
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve(err);
        });
    });
  }

  saveZone(
    gisId: any,
    latitude: any,
    longitude: any,
    zoneName: any,
    zoneNameMr: any,
    activeFlag: any,
    id: any,
    stateId: any,
    districtId: any,
    talukaId: any
  ) {
    let body = {};

    body = {
      gisId: gisId,
      // latitude : latitude,
      // longitude : longitude,
      zoneName: zoneName,
      zoneNameMr: zoneNameMr,
      activeFlag: activeFlag,
      id: id,
      stateId: stateId,
      districtId: districtId,
      talukaId: talukaId,
    };

    // const formData = new FormData();
    // formData.append("mstZoneDao", JSON.stringify(body));

    var url = `${environment.commonUrl}` + "/api/master/zone/save";

    return new Promise((resolve) => {
      this.httpClient
        .post(url, body, { responseType: "text" })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve(err);
        });
    });
  }

  getZoneByTalukaId(talukaId: any) {
    return this.httpClient.get(
      `${environment.commonUrl}/api/master/zone/getZoneByTalukaId?talukaId=${talukaId}`
    );
  }

  activateInactivateZone(id, data) {
    return this.httpClient.post(
      `${environment.commonUrl}/api/master/zone/active/${id}`,
      data
    );
  }
}
