import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { WardService } from 'src/app/_services/ward.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

interface corporate {
  id:number;
  dob: string;
  nominatedCorporator: boolean;
  wardId: number;
  wardName:string;
  electoralWardId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  email: string;
  mobile: string;
  religionId: number;
  castId: number;
  subCastId: number;
  partyId: number;
  identificationProofId: number;
  identificationProofNo: number;
  electedDate: string;
  resignDate: string;
  resignReason: string;
  activeFlag: string;
  createdUserId:any;
  updateUserId:any;
  addedit:any;
} 

@Component({
  selector: 'app-add-corporator',
  templateUrl: './add-corporator.component.html',
  styleUrls: ['./add-corporator.component.scss']
})
export class AddCorporatorComponent {
  electoralWards: any;
  castes: any;
  wards: any;
  religions: any;
  subCastes: any;
  parties: any;
  identificationProofs:any;
  errorMessage:string=""



  corporate: corporate = {
    nominatedCorporator: false,
    wardId: 0,
    wardName: '',
    electoralWardId: 0,
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    mobile: '',
    email: '',
    dob: '',
    religionId: 0,
    castId: 0,
    subCastId: 0,
    partyId: 0,
    identificationProofId: 0,
    identificationProofNo: undefined,
    electedDate: '',
    activeFlag: 'Y',
    id: null,
    addedit: null,
    createdUserId:null,
    updateUserId:null,
    resignDate: '',
    resignReason: '',

  };
  userId: string;

  constructor(
    private msmService: MsmamServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCorporatorComponent>,
    public wardService:WardService,
    @Inject(MAT_DIALOG_DATA) public data1: any
  ) {
  
  }

  ngOnInit() {
    this.userId = localStorage.getItem("userId");
    this.corporate.createdUserId=this.userId;
    this.corporate.updateUserId=this.userId;
    this.corporate.addedit = this.data1['isEditMode'];
    if (this.corporate.addedit) {
      const data12 = this.data1['row'];
      this.corporate.createdUserId=data12['createdUserId'];
    this.corporate.updateUserId=this.userId;
      this.loadEditModeData(data12);
    }
    this.getWards();
    this.getReligion();
    this.getCaste();
    this.getSubCastes();
    this.getElectoralWards();
    this.getParties();
    this.getidentificationProofs();
   
  }
 
  getWards(){
    this.wardService.getWard2().subscribe(
      (data) => this.wards = data['wardMasterList'],
      (err) => this._snackBar.error('Ward List is Empty')
    );
  }
  wardChange(data: any) {
    this.corporate.wardId = data;
    this.corporate.wardName = this.wards.find((item) => item.id === data)?.wardName || '';
  }
  
  getElectoralWards(){
    this.msmService.getByActiveFlagElectoralward().subscribe(
      (data) => this.electoralWards = data['electoralWardMasterList'],
      (err) => this._snackBar.error('Electoral Ward List is Empty')
    );
  }
  
  getCaste() {
    this.msmService.getByActiveFlagCast().subscribe(
      (data) => this.castes = data['castMasterList'],
      (err) => this._snackBar.error('Cast List is Empty')
    );
  }

  getSubCastes(){
    this.msmService.getByActiveFlagSubcast().subscribe(
      (data) => this.subCastes = data['subCastMasterList'],
      (err) => this._snackBar.error('SubCast List is Empty')
    );
  }
  getParties(){
    this.msmService.getByActiveFlagParty().subscribe(
      (data) => this.parties = data['partyMasterList'],
      (err) => this._snackBar.error('Party List is Empty')
    );
  }
  getidentificationProofs(){
    this.msmService.getByActiveFlagIdentificationproof().subscribe(
      (data) => this.identificationProofs = data['identificationProofMasterList'],
      (err) => this._snackBar.error('Identification Proof List is Empty')
    );
  }
 
  getReligion() {
    this.msmService.getByActiveFlagReligion().subscribe(
      (data) => this.religions = data['religion'],
      (err) => this._snackBar.error('Religion List is Empty')
    );
  }

  religinChange($event) {
    // Handle religion change logic here
  }

  saveCorporator() {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '550px',
      data: {
        mode: this.corporate.addedit ? 'Edit' : 'Add',
        component: 'Corporator'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'success') {
        this.msmService.saveCorporate(this.corporate).subscribe(
          res => {
            if (res['status'] === 'Success') {
              setTimeout(() => {
                this.dialogRef.close({ data: 'Success' });
              }, 1000);
            }else{
              this.errorMessage=res['status']
            }
          },
          err => {
            this._snackBar.error('Corporator not added');
          }
        );
      }
    });
  }

  private loadEditModeData(data12: any): void {
    // Load data in edit mode
    Object.assign(this.corporate, data12);
  }
}
