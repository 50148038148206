<div class=" bg-gray-100 p-6">
    <mat-icon id="close-icon" style="float: right;cursor: pointer;" class="close-icon" [mat-dialog-close]="true">close</mat-icon>
    
      <div class="mx-auto max-w-md ">
        <h1 class="text-2xl font-bold mb-8">{{action}} Title</h1>
        <form [formGroup]="form" (ngSubmit)="saveTitle()">
  
          <div class="relative z-0 w-full mb-5">
            <label for="title" class="block text-gray-600">Title</label>
            <input
              type="title"
              id="title"
              name="title"
              class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400"
              formControlName="titleName"
              required=""/>
        </div>

        <mat-form-field class="w-full">
            <mat-label>Valid</mat-label>
            <mat-select formControlName="valid">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>
  
     
  
          <button
            id="button"
            type="submit"
            [disabled]="!form.valid"
            class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
          >
            Save
          </button>
         
          </form>
      </div>
    </div>