import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private httpClient : HttpClient) { }

  uploadFile(file : any){
    let body = {}
  
    const formData = new FormData();
    formData.append("file",file);
    formData.append("appName", 'NMMC');
    formData.append("serviceName", 'Common');

    
    var url = `${environment.commonUrl}` + '/api/file/uploadAllTypeOfFile'
  
    return new Promise(resolve => {
      this.httpClient.post(url,formData,{responseType:'text'}).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })
  
  }
  
  
  
  uploadqrFile(file : any){
    let body = {}
  
    const formData = new FormData();
    formData.append("file",file);
    formData.append("appName", 'NMMC');
    formData.append("serviceName", 'Common');

    
    var url = `${environment.commonUrl}` + '/api/file/uploadAllTypeOfFileV1'
  
    return new Promise(resolve => {
      this.httpClient.post(url,formData,{responseType:'text'}).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })
  
  }
  

  uploadLifeCertFile(file : any){
    let body = {}
  
    const formData = new FormData();
    formData.append("file",file);
    formData.append("appName", 'NMMC');
    formData.append("serviceName", 'HRMS');

    
    var url = `${environment.commonUrl}` + '/api/file/uploadAllTypeOfFile'
  
    return new Promise(resolve => {
      this.httpClient.post(url,formData,{responseType:'text'}).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })
  
  }

  getFilePreviewSingle(filePath){
    
    var url = `${environment.commonUrl}` + '/api/file/preview?filePath=' + filePath
    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
          console.log(err)
        });     
      });
  }

  getPdfAllList(){
    return this.httpClient.get(`${environment.commonUrl}/api/uploadedDocuments/getAll`)
  }

  // getGenerateQrCode(data){
  //   return this.httpClient.post(`${environment.commonUrl}/api/uploadedDocuments/generateQRCode?docUrl=${data}`,null)
  // }

  getGenerateQrCode(data: string): Observable<Blob> {
    return this.httpClient.post(`${environment.commonUrl}/api/uploadedDocuments/generateQRCode?docUrl=${data}`, null, { responseType: 'blob' });
}

  

}
