import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NodeMasterService {

  constructor(private httpClient: HttpClient) { }

  getNode(){
    var url = `${environment.commonUrl}` + '/api/master/node/getAllDashBoard'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  getActiveNode(){
    var url = `${environment.commonUrl}` + '/api/master/node/getAll'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  getNodeByZoneId(zoneId : any){
    return this.httpClient.get(`${environment.commonUrl}/api/master/node/getNodeByZoneId?zoneId=${zoneId}`)
  }

  saveNode(nodeMr : any,node : any,activeFlag : any,stateId : any,districtId : any,talukaId : any,zoneId : any,wardId : any,id : any){
    let body = {}
    

    body = {
      nodeNameMr : nodeMr,
      nodeName : node,
      activeFlag : activeFlag,
      stateId : stateId,
      districtId : districtId,
      talukaId : talukaId,
      zoneId : zoneId,
      id : id

    }

    // const formData = new FormData();
    // formData.append("mstDepartmentDao", JSON.stringify(body));

    var url = `${environment.commonUrl}` + '/api/master/node/save'

    return new Promise(resolve => {
      this.httpClient.post(url,body).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })

  }

  

  activateInactivateNode(id,data){
    return this.httpClient.post(`${environment.commonUrl}/api/master/node/active/${id}`,data)
  }

 
}



