import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { CommonService } from 'src/app/_services/Common-Services/common.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-user-module-mapping',
  templateUrl: './user-module-mapping.component.html',
  styleUrls: ['./user-module-mapping.component.scss']
})
export class UserModuleMappingComponent {
  applicationForm: FormGroup;
  searchForm : FormGroup;
  users: any[];
  user: any[] = []
  modules: any[] = []
  selectedUser: string;
  error : any
  userId : any = localStorage.getItem('userId')
  activeFlag : any = 'Y'
  searchedUserName : any = ''
  selectedModules : any[] = []

  constructor(private fb: FormBuilder, private commonService: CommonService,
    private location: Location,
    private snackbar : MatSnackBar,
    private dialog : MatDialog,
    private router : Router,
    
    ) { }

  ngOnInit() {
    this.searchForm = this.fb.group(
      {
        mobileNumber: ['', [Validators.required, Validators.pattern('[0-9]{10}')]]

      }
    )

    this.searchForm.get('mobileNumber')!.valueChanges.subscribe(value => {
      if (value === '') {
        this.onClear();
      } else {
        this.onChange(value);
      }
    });

    this.initializeModules();

  }

  initializeModules() {
    this.modules = [
      { name: 'Assets Management', url: '/asset-dashboard', selected: false },
      { name: 'Content Management', url: '/content-management/cms-dashboard', selected: false },
      { name: 'Central Workshop Management', url: '/central-workshop', selected: false },
      { name: 'Core Module', url: '/masterDashboard', selected: false },
      { name: 'Disaster Management', url: '/disaster-management/disaster-dashboard', selected: false },
      { name: 'Enterprise Workflow Management', url: '/EnterpriceWorkflowManagement-dashboard', selected: false },
      { name: 'Financial Accounting', url: '/financial-accounting', selected: false },
      { name: 'Grievance Management', url: '/grievance-management', selected: false },
      { name: 'HR & Payroll Management', url: '/hrms-module/hrms-dashboard', selected: false },
      { name: 'Land and Estate', url: '/land-Estate', selected: false },
      { name: 'Material Management', url: '/mm-dashboard', selected: false },
      { name: 'Municipality Secretary & Agenda Management', url: '/msmam-dashboard', selected: false },
      { name: 'Online Services (RTS)', url: '/online-rts', selected: false },
      { name: 'Property Tax Management', url: '/property-tax/propertyTax-scrutiny', selected: false },
      { name: 'Solid Waste Management', url: '/solid-waste-management', selected: false },
      { name: 'Survey Database Creation', url: '/survey-database/survey-dashboard', selected: false },
      { name: 'Water Tax Management', url: '/water-tax/verificatin/dashboard', selected: false },
      { name: 'Work Management', url: '/work-management', selected: false },
    ];
  }

  onSearch() {
    const mobileNumber = this.searchForm.value.mobileNumber;
    this.commonService.getUserByMobileNumber(mobileNumber).subscribe(
      data => {
        this.user = JSON.parse(JSON.stringify(data))
        console.log(this.user)
        this.error = null;
        this.getPreviouslyMappedModules(this.user[0].id)

      },
      err => {
        this.error = 'User not found';
        this.user = null;
      }
    );
  }

  onClear() {
    this.user = [];
    
  }

  onChange(value: string) {
    // Perform any action you want on input change
    // For example, you might want to reset some state or call a different function
    console.log('Input changed:', value);
    this.user = [];

  }

  getPreviouslyMappedModules(userId: string) {
    console.log(userId)
    this.commonService.getModulesByUserId(userId).subscribe((res: any) => {
      console.log(res)
      this.modules.forEach(module => {
        if (res.some((mappedModule: any) => mappedModule.moduleName === module.name)) {
          module.selected = true;
          this.selectedModules.push({
            userId: userId,
            moduleName: module.name,
            moduleUrl: module.url,
            activeFlag: 'Y'
          });
        }
      });
      this.updateSelectedCount();
    });
  }

  selectedCount = 0;

  toggleSelected(module: any): void {
    module.selected = !module.selected;
    this.updateSelectedCount();
    // Remove the module if it's deselected
      if (!module.selected) {
        const index = this.selectedModules.findIndex(item => item.moduleName === module.name);
        if (index !== -1) {
          this.selectedModules.splice(index, 1);
        }
      }else {
        this.selectedModules.push(
          {
            'userId' : this.user[0].id,
            'moduleName' : module.name,
            'moduleUrl' : module.url,
            'activeFlag' : 'Y'
          }
        )
      }
      console.log(this.selectedModules)
    
  }

  updateSelectedCount(): void {
    this.selectedCount = this.modules.filter(module => module.selected).length;
  }
  

 

  saveUserModuleMapping() {
    

    let finalData = {
        userModuleMappingList: this.selectedModules,
        createdUserId: this.userId,
        updateUserId: this.userId,
        activeFlag: 'Y'
    }

    this.commonService.saveUserModuleMapping(finalData).subscribe((data: any) => {
        console.log(data);
        if (data['status'] === 'success') {
            this.showSnackBar('User Module Mapping added successfully', 'success');
            window.location.reload();

        } else {
            this.showSnackBar('Error: User Module Mapping addition failed', 'error');
        }
    })
}


 
  cancel(){

  }
  

  showSnackBar(message: string, type: 'success' | 'error') {
    this.snackbar.open(message, 'Close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: type === 'success' ? ['success-snackbar'] : ['error-snackbar'],
    });
    }

}

