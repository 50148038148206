import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-view-img-doc",
  templateUrl: "./view-img-doc.component.html",
  styleUrls: ["./view-img-doc.component.scss"],
})
export class ViewImgDocComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {
    
  }

  isImageType(data: string): boolean {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
    const fileExtension = data.substring(data.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(fileExtension);
  }

  isPdfType(data: string): boolean {
    const pdfExtensions = [".pdf", ".xls", ".xlsx", ".csv"];
    const fileExtension = data.substring(data.lastIndexOf(".")).toLowerCase();
    return pdfExtensions.includes(fileExtension);
  }
}
