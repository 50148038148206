import { NgModule } from "@angular/core";

import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { WebcamModule } from "ngx-webcam";

import { AddCalenderShedulingComponent } from "./MSMAM/calender-scheduling-and-holiday/add-calender-sheduling/add-calender-sheduling.component";
import { ConfirmationModalComponent } from "./MSMAM/calender-scheduling-and-holiday/add-calender-sheduling/confirmation-modal/confirmation-modal.component";
import { CalenderSchedulingAndHolidayComponent } from "./MSMAM/calender-scheduling-and-holiday/calender-scheduling-and-holiday.component";
import { AddCastComponent } from "./MSMAM/caste-master/add-cast/add-cast.component";
import { CasteMasterComponent } from "./MSMAM/caste-master/caste-master.component";
import { AddCommitteeEstablishmentComponent } from "./MSMAM/committee-establishment-master/add-committee-establishment/add-committee-establishment.component";
import { CommitteeEstablishmentMasterComponent } from "./MSMAM/committee-establishment-master/committee-establishment-master.component";
import { AddCommitteeComponent } from "./MSMAM/committee-master/add-committee/add-committee.component";
import { CommitteeMasterComponent } from "./MSMAM/committee-master/committee-master.component";
import { AddCommitteeMembersComponent } from "./MSMAM/committee-members-master/add-committee-members/add-committee-members.component";
import { CommitteeMembersMasterComponent } from "./MSMAM/committee-members-master/committee-members-master.component";
import { AddCorporatorComponent } from "./MSMAM/corporator-master/add-corporator/add-corporator.component";
import { CorporatorMasterComponent } from "./MSMAM/corporator-master/corporator-master.component";
import { AddElectoralWardComponent } from "./MSMAM/electoral-ward-master/add-electoral-ward/add-electoral-ward.component";
import { ElectoralWardMasterComponent } from "./MSMAM/electoral-ward-master/electoral-ward-master.component";
import { AddIdentifiactionproofComponent } from "./MSMAM/identifiactionproof-master/add-identifiactionproof/add-identifiactionproof.component";
import { IdentifiactionproofMasterComponent } from "./MSMAM/identifiactionproof-master/identifiactionproof-master.component";
import { AddMeetingSheduleComponent } from "./MSMAM/meeting-schedule-master/add-meeting-shedule/add-meeting-shedule.component";
import { MeetingScheduleMasterComponent } from "./MSMAM/meeting-schedule-master/meeting-schedule-master.component";
import { AddMeetingTypeComponent } from "./MSMAM/meeting-type-master/add-meeting-type/add-meeting-type.component";
import { MeetingTypeMasterComponent } from "./MSMAM/meeting-type-master/meeting-type-master.component";
import { MsmamDashboardComponent } from "./MSMAM/msmam-dashboard/msmam-dashboard.component";
import { AddOficesComponent } from "./MSMAM/ofiices-master/add-ofices/add-ofices.component";
import { OfiicesMasterComponent } from "./MSMAM/ofiices-master/ofiices-master.component";
import { AddPartyComponent } from "./MSMAM/party-master/add-party/add-party.component";
import { PartyMasterComponent } from "./MSMAM/party-master/party-master.component";
import { AddReligionComponent } from "./MSMAM/religion-master/add-religion/add-religion.component";
import { ReligionMasterComponent } from "./MSMAM/religion-master/religion-master.component";
import { AddSubcastComponent } from "./MSMAM/subcast-master/add-subcast/add-subcast.component";
import { SubcastMasterComponent } from "./MSMAM/subcast-master/subcast-master.component";
import { AddNewDocketentryComponent } from "./MSMAM/transactions/new-docketentry/add-new-docketentry/add-new-docketentry.component";
import { YearPickerComponent } from "./MSMAM/transactions/new-docketentry/add-new-docketentry/year-picker/year-picker.component";
import { NewDocketentryComponent } from "./MSMAM/transactions/new-docketentry/new-docketentry.component";
import { AddMeetingAgendaComponent } from "./MSMAM/transactions/prepare-meeting-agenda-and-finalization/add-meeting-agenda/add-meeting-agenda.component";
import { PrepareMeetingAgendaAndFinalizationComponent } from "./MSMAM/transactions/prepare-meeting-agenda-and-finalization/prepare-meeting-agenda-and-finalization.component";

import { AddEditPropertyTaxComponent } from "./PROPERTY-TAX/property-master/add-edit-property-tax/add-edit-property-tax.component";
import { PropertyMasterComponent } from "./PROPERTY-TAX/property-master/property-master.component";
import { PropertyTaxDashboardComponent } from "./PROPERTY-TAX/property-tax-dashboard/property-tax-dashboard.component";
import { PropertyTaxScrutinyActionsComponent } from "./PROPERTY-TAX/property-tax-scrutiny/property-tax-scrutiny-actions/property-tax-scrutiny-actions.component";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ApplicationFormDocumentsComponent } from "./application-form-documents/application-form-documents.component";
import { ApplicationFormComponent } from "./application-form/application-form.component";
import { AssetDashboardComponent } from "./assetManagement/asset-dashboard/asset-dashboard.component";
import { AddEditAssetComponent } from "./assetManagement/master/asset-asset/add-edit-asset/add-edit-asset.component";
import { AssetAssetComponent } from "./assetManagement/master/asset-asset/asset-asset.component";
import { AddAssetCategoryComponent } from "./assetManagement/master/asset-category/add-asset-category/add-asset-category.component";
import { AssetCategoryComponent } from "./assetManagement/master/asset-category/asset-category.component";
import { AddEditEqpBreakdownTypeComponent } from "./assetManagement/master/asset-eqp-breakdown-type/add-edit-eqp-breakdown-type/add-edit-eqp-breakdown-type.component";
import { AssetEqpBreakdownTypeComponent } from "./assetManagement/master/asset-eqp-breakdown-type/asset-eqp-breakdown-type.component";
import { AddAssetEvaluatorComponent } from "./assetManagement/master/asset-evaluator/add-asset-evaluator/add-asset-evaluator.component";
import { AssetEvaluatorComponent } from "./assetManagement/master/asset-evaluator/asset-evaluator.component";
import { AddAssetSubCategoryComponent } from "./assetManagement/master/asset-sub-category/add-asset-sub-category/add-asset-sub-category.component";
import { AssetSubCategoryComponent } from "./assetManagement/master/asset-sub-category/asset-sub-category.component";
import { AddAssetSubTypeComponent } from "./assetManagement/master/asset-sub-type/add-asset-sub-type/add-asset-sub-type.component";
import { AssetSubTypeComponent } from "./assetManagement/master/asset-sub-type/asset-sub-type.component";
import { AddAssetTypeComponent } from "./assetManagement/master/asset-type/add-asset-type/add-asset-type.component";
import { AssetTypeComponent } from "./assetManagement/master/asset-type/asset-type.component";
import { CheckboxFieldOptionsDialogComponent } from "./checkbox-field-options-dialog/checkbox-field-options-dialog.component";
import { ConfirmationPopupComponent } from "./confirmation-popup/confirmation-popup.component";
import { ApplicationModalComponent } from "./dashboard/application-modal/application-modal.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DynamicEditableTableComponent } from "./dynamic-editable-table/dynamic-editable-table.component";
import { FileUploadComponent } from "./file-upload/file-upload/file-upload.component";
import { FooterComponent } from "./footer/footer.component";
import { AddFieldDialogComponent } from "./form-builder/add-field-dialog/add-field-dialog.component";
import { FormBuilderComponent } from "./form-builder/form-builder.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./login/register/register.component";
import { MasterServiceComponent } from "./master-service/master-service.component";
import { RadioFieldOptionsDialogComponent } from "./radio-field-options-dialog/radio-field-options-dialog.component";
import { LoaderComponent } from "./shared/component/loader/loader.component";
import { Materialsharedmodule } from "./shared/module/material-shared.module";
import { SafePipe } from "./shared/pipe/safe.pipe";
import { TextFieldOptionsDialogComponent } from "./text-field-options-dialog/text-field-options-dialog.component";
import { TextareaFieldOptionsDialogComponent } from "./textarea-field-options-dialog/textarea-field-options-dialog.component";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { QrCodeComponent } from "./qr-code/qr-code.component";
import { ShowQrCodeComponent } from "./qr-code/show-qr-code/show-qr-code.component";
import { AddEditReportV1Component } from "./HRMS/Transactions/confidential-report-v1/add-edit-report-v1/add-edit-report-v1.component";
import { LifeCertificateTransactionComponent } from "./HRMS/Transactions/life-certificate-transaction/life-certificate-transaction.component";
import { AddEditLifeCertificateTransactionComponent } from "./HRMS/Transactions/life-certificate-transaction/add-edit-life-certificate-transaction/add-edit-life-certificate-transaction.component";
import { LifeCertificateTransactionScrutinyComponent } from "./HRMS/Transactions/life-certificate-transaction/life-certificate-transaction-scrutiny/life-certificate-transaction-scrutiny.component";
import { UserModuleMappingComponent } from "./common/user-module-mapping/user-module-mapping.component";
import { MappedDashboardComponent } from "./mapped-dashboard/mapped-dashboard.component";
import { MappedToolbarComponent } from "./mapped-toolbar/mapped-toolbar.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { MediaChannelMasterComponent } from "./MSMAM/media-channel-master/media-channel-master-component.component";
import { AddMediaChannelMasterComponent } from "./MSMAM/media-channel-master/add-media-channel-master/add-media-channel-master/add-media-channel-master.component";
import { MediaMasterComponent } from "./MSMAM/media-master/media-master.component";
import { AddMediaMasterComponent } from "./MSMAM/media-master/add-media-master/add-media-master.component";
import { JournalistMasterComponent } from "./MSMAM/journalist-master/journalist-master.component";
import { AddJournalistMasterComponent } from "./MSMAM/journalist-master/add-journalist-master/add-journalist-master.component";
import { ClerkToCommitteeMaster1Component } from "./MSMAM/clerk-to-committee-master1/clerk-to-committee-master1.component";
import { AddClerkToCommitteeComponent1 } from "./MSMAM/clerk-to-committee-master1/add-clerk-to-committee/add-clerk-to-committee.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./_services/AuthInterceptor";
import { InfoPopUpComponent } from "./shared/component/info-pop-up/info-pop-up.component";
import { DocumentViewerModalComponent } from "./document-viewer-modal/document-viewer-modal.component";
import { ReactiveFormsModule } from "@angular/forms";
import { UserProfileComponent } from "./master-data/user-profile/user-profile.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ToolbarComponent,
    FooterComponent,
    MasterServiceComponent,
    ApplicationFormComponent,
    ApplicationFormDocumentsComponent,
    FormBuilderComponent,
    TextFieldOptionsDialogComponent,
    TextareaFieldOptionsDialogComponent,
    RadioFieldOptionsDialogComponent,
    CheckboxFieldOptionsDialogComponent,
    AddFieldDialogComponent,
    ApplicationModalComponent,
    RegisterComponent,
    FileUploadComponent,
    ConfirmationPopupComponent,
    LoaderComponent,
    SafePipe,
    PropertyTaxDashboardComponent,
    PropertyMasterComponent,
    AddEditPropertyTaxComponent,
    MsmamDashboardComponent,
    ElectoralWardMasterComponent,
    OfiicesMasterComponent,
    CommitteeMasterComponent,
    CommitteeEstablishmentMasterComponent,
    ReligionMasterComponent,
    CasteMasterComponent,
    SubcastMasterComponent,
    PartyMasterComponent,
    IdentifiactionproofMasterComponent,
    CommitteeMembersMasterComponent,
    MeetingScheduleMasterComponent,
    CorporatorMasterComponent,
    MeetingTypeMasterComponent,
    AddMeetingTypeComponent,
    AddCastComponent,
    AddCommitteeEstablishmentComponent,
    AddCommitteeComponent,
    AddCommitteeMembersComponent,
    AddCorporatorComponent,
    AddElectoralWardComponent,
    AddIdentifiactionproofComponent,
    AddMeetingSheduleComponent,
    AddOficesComponent,
    AddPartyComponent,
    AddReligionComponent,
    AddSubcastComponent,
    AssetDashboardComponent,
    AssetTypeComponent,
    AddAssetTypeComponent,
    AssetSubTypeComponent,
    AddAssetSubTypeComponent,
    AssetCategoryComponent,
    AddAssetCategoryComponent,
    AssetSubCategoryComponent,
    AddAssetSubCategoryComponent,
    AssetEvaluatorComponent,
    AddAssetEvaluatorComponent,
    CalenderSchedulingAndHolidayComponent,
    AddCalenderShedulingComponent,
    NewDocketentryComponent,
    AddNewDocketentryComponent,
    PrepareMeetingAgendaAndFinalizationComponent,
    AddMeetingAgendaComponent,
    YearPickerComponent,
    ConfirmationModalComponent,
    PropertyTaxScrutinyActionsComponent,
    AssetAssetComponent,
    AddEditAssetComponent,
    AssetEqpBreakdownTypeComponent,
    AddEditEqpBreakdownTypeComponent,
    DynamicEditableTableComponent,
    QrCodeComponent,
    ShowQrCodeComponent,
    AddEditReportV1Component,
    LifeCertificateTransactionComponent,
    AddEditLifeCertificateTransactionComponent,
    LifeCertificateTransactionScrutinyComponent,
    UserModuleMappingComponent,
    MappedDashboardComponent,
    MappedToolbarComponent,
    NotFoundComponent,
    MediaChannelMasterComponent,
    AddMediaChannelMasterComponent,
    MediaMasterComponent,
    AddMediaMasterComponent,
    JournalistMasterComponent,
    AddJournalistMasterComponent,
    ClerkToCommitteeMaster1Component,
    AddClerkToCommitteeComponent1,
    ForgotPasswordComponent,
    InfoPopUpComponent,
    DocumentViewerModalComponent,
    UserProfileComponent,
  ],
  imports: [
    AppRoutingModule,
    Materialsharedmodule,
    MatTabsModule,
    BrowserAnimationsModule,
    WebcamModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatIconModule,
    DragDropModule,
    ReactiveFormsModule,
  ],
  exports: [],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
