import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  // styleUrls: ['./confirmation-modal.component.css'],
  })
export class ConfirmationModalComponent {
  action : any = ''
  component : any = ''
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ConfirmationModalComponent>,) { }

  ngOnInit(): void {
    this.action = this.data.mode
    this.component = this.data.component
  }

  onConfirm() {
    this.dialogRef.close('success');
  }

  onNoClick(): void {
    this.dialogRef.close('delete');
  }
}
