import { Component } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UsersService } from "../../_services/master-data/users.service";
import { SnackbarMsgService } from "../../shared/Toast-service/snackbar-msg.service";
import { CustomValidatorService } from "src/app/_services/custom-validator.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent {
  form!: FormGroup;
  isEmailOTPRequested = false;
  isMobileOTPRequested = false;
  isEmailVerified = false;
  isMobileVerified = false;
  isOTPRequested = false;

  hoverButton: boolean = false; 

  resp = {
    essentials: {
      requestId: null,
    },
    id: "652f83749a481c0028e3dbb6",
    patronId: "651ee0f56b624b002a5c08e7",
    url: null,
    requestId: null,
    task: "url",
    result: {
      url: "https://api.digitallocker.gov.in/public/oauth2/1/authorize?client_id=3DF9D55E&code_challenge=8LXkRuOlYU1kUCSUgp5FEE4HcJbHcenofwot6FFnDfw&code_challenge_method=S256&dl_flow&redirect_uri=https%3A%2F%2Fdigilocker.signzy.tech%2Fdigilocker-auth-complete&response_type=code&state=652f8374e59f510012c714bb",
      requestId: "652f8374e59f510012c714bb",
    },
  };

  constructor(
    private fb: FormBuilder,
    private regService: UsersService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private customValidator: CustomValidatorService,
    private snackbar: SnackbarMsgService,
  ) { }

  ngOnInit() {
    if (localStorage.getItem('userId')) {
      this.router.navigate(['/dashboard']); // Stay on dashboard if authenticated
    };

    this.form = this.fb.group(
      {
        userFirstName: ['', [Validators.required, Validators.pattern('[a-zA-Z]*'), Validators.minLength(2), Validators.maxLength(25)]],
        userLastName: ['', [Validators.required, Validators.pattern('[a-zA-Z]*'), Validators.minLength(2), Validators.maxLength(25)]],
        userEmail: ["", [Validators.required, Validators.email, this.customValidator.customEmailValidator()]],
        userMobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]], // Assumes 10-digit mobile number
        emailOTP: [""],  // New control for email OTP
        mobileOTP: [""],
        usrPassword: ["", [Validators.required, Validators.minLength(6), this.strongPasswordValidator()]],
        usrConfirmPassword: ["", Validators.required],
        activeFlag: ["Y"],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
  }

  strongPasswordValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;

      if (value && value.length > 5) {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /\d/.test(value);
        const hasSpecialChar = /[@$!%*?&]/.test(value);

        const isValid = hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;

        return isValid ? null : { strongPassword: true };
      }

      return null;
    };
  }

  passwordMatchValidator(form: FormGroup) {
    const password = form.get("usrPassword")?.value;
    const confirmPassword = form.get("usrConfirmPassword")?.value;

    if (password === confirmPassword) {
      form.get("usrConfirmPassword")?.setErrors(null);
    } else {
      form.get("usrConfirmPassword")?.setErrors({ mismatch: true });
    }
  }

  requestEmailOTP() {
    const userFirstName = this.form.get('userFirstName').value;
    const userLastName = this.form.get('userLastName').value;
    const email = this.form.get('userEmail').value;
    const payload = { userEmail: email,
      userFirstName: userFirstName,
      userLastName: userLastName
    };
    console.log(payload);
    this.regService.requestEmailOTP(payload).subscribe(response => {
      if (response && response.status === 'Success') {
        this.isEmailOTPRequested = true;
        this.isEmailVerified = false;
        this.snackbar.success("Email OTP sent successfully.");
      } else {
        this.snackbar.error("Failed to send Email OTP.");
      }
    }, error => {
      console.error('Error sending OTP:', error);
    });
  }

  verifyEmailOTP() {
    const email = this.form.get('userEmail')?.value;
    const otp = this.form.get('emailOTP')?.value;

    const payload = {
      userEmail: email,
      otp: otp
    };

    this.regService.verifyEmailOTP(payload).subscribe(
      response => {
        if (response.status === 'Success') {
          this.snackbar.success("Email verified successfully.");
          this.isEmailVerified = true;
          this.isEmailOTPRequested = false; // Hide buttons
        } else {
          this.snackbar.error("Invalid Email OTP.");
        }
      },
      (error) => this.snackbar.error("Invalid Email OTP.")
    );
  }

  requestMobileOTP() {
    const mobile = this.form.get('userMobile').value;
    const payload = { userMobile: mobile };

    this.regService.requestMobileOTP(payload).subscribe(response => {
      if (response && response.status === 'Success') {
        this.isMobileOTPRequested = true;
        this.snackbar.success("Mobile OTP sent successfully.");
      } else if(response && response.status === 'Failed') {
        this.snackbar.error("Mobile number already exists.");
      }
      else
        this.snackbar.error("Failed to send Mobile OTP.");
      }
    , error => {
      console.error('Error sending OTP:', error);
    });
  }

  verifyMobileOTP() {
    const mobile = this.form.get('userMobile')?.value;
    const otp = this.form.get('mobileOTP')?.value;
    const payload = {
      userMobile: mobile,
      otp: otp
    };
    this.regService.verifyMobileOTP(payload).subscribe(
      response => {
        if (response.status === 'Success') {
          this.snackbar.success("Mobile verified successfully.");
          this.isMobileVerified = true;
          this.isMobileOTPRequested = false; // Hide buttons
        } else {
          this.snackbar.error("Invalid Mobile OTP.");
        }
      },
      (error) => this.snackbar.error("Invalid Mobile OTP.")
    );
  }

  requestOTPs(): void {
    if (this.form.get('userEmail')?.valid && this.form.get('userMobile')?.valid) {
      const userFirstName = this.form.get('userFirstName')?.value;
      const userLastName = this.form.get('userLastName')?.value;
      const email = this.form.get('userEmail')?.value;
      const mobile = this.form.get('userMobile')?.value;
      
      this.isOTPRequested = true;

      const emailPayload = {
        userFirstName: userFirstName,
        userLastName: userLastName,
        userEmail: email
      };

      this.regService.requestEmailOTP(emailPayload).subscribe(response => {
        if (response && response.status === 'Success') {
          this.isEmailOTPRequested = true;
          this.isEmailVerified = false;
          this.snackbar.success("Email OTP sent successfully.");
        } else {
          this.snackbar.error("Failed to send Email OTP.");
        }
      }, error => {
        console.error('Error sending OTP:', error);
      });

      const mobilePayload = {
        userMobile: mobile
      };

      this.regService.requestMobileOTP(mobilePayload).subscribe(response => {
        if (response && response.status === 'Success') {
          this.isMobileOTPRequested = true;
          this.snackbar.success("Mobile OTP sent successfully.");
        } else {
          this.snackbar.error("Failed to send Mobile OTP.");
        }
      }, error => {
        console.error('Error sending OTP:', error);
      });
    }
  }

  // register() {
  //   this.regService
  //     .registerUser(
  //       this.form.get("userFirstname")?.value,
  //       this.form.get("userFirstname")?.value,
  //       this.form.get("userLastname")?.value,
  //       this.form.get("userLastname")?.value,
  //       this.form.get("userEmail")?.value,
  //       this.form.get("userMobile")?.value,
  //       this.form.get("activeFlag")?.value,
  //       this.form.get("usrPassword")?.value
  //     )
  //     .then((data: any) => {
  //       const data1 = JSON.parse(data);

  //       if (data1.status == "Success") {
  //         localStorage.setItem("userId", data1.userId);
  //         this.router.navigateByUrl("");
  //         // localStorage.setItem('reqId',this.resp["result"]["requestId"])
  //         // window.location.href = this.resp["result"]["url"];
  //         // this.regService.redirectDigi(data1["userId"]).subscribe(
  //         //   (res) => {

  //         //     localStorage.setItem("reqId", res["result"]["requestId"]);
  //         //     window.location.href = res["result"]["url"];
  //         //   },
  //         //   (error) => {

  //         //     this.snackbar.error("Error: " + error.message);
  //         //   }
  //         // );

  //         // setTimeout(() => {}, 1000);
  //       } else if (data1.status == "Failed") {
  //         this.snackbar.error("Error : " + data1["message"]);
  //       } else if (data1.message == "Mobile number already exist.") {
  //         this.snackbar.error("Error : " + data1["message"]);
  //       }
  //     });
  // }

  // register() {
  //   if (!this.isEmailVerified || !this.isMobileVerified) {
  //     // Show a snackbar indicating what is missing
  //     let message = 'Please complete the following verification(s):';
  //     if (!this.isEmailVerified) {
  //       message += ' Email';
  //     }
  //     if (!this.isMobileVerified) {
  //       message += ' Mobile';
  //     }
  //     this.snackbar.error(message);
  //     return;
  //   }

  //   this.regService
  //     .registerUser(
  //       this.form.get("userFirstname")?.value,
  //       this.form.get("userFirstname")?.value,
  //       this.form.get("userLastname")?.value,
  //       this.form.get("userLastname")?.value,
  //       this.form.get("userEmail")?.value,
  //       this.form.get("userMobile")?.value,
  //       this.form.get("activeFlag")?.value,
  //       this.form.get("usrPassword")?.value
  //     ) 
  //     .then((data: any) => {
  //       const data1 = JSON.parse(data);

  //       if (data1.status == "Success") {
  //         localStorage.setItem("userId", data1.userId);
  //         this.router.navigateByUrl("");
  //       } else if (data1.status == "Failed") {
  //         this.snackbar.error("Error: " + data1["message"]);
  //       } else if (data1.message == "Mobile number already exists.") {
  //         this.snackbar.error("Error: " + data1["message"]);
  //       }
  //     });
  // }

  onRegister() {

    console.log("Register button clicked");
    if (this.form.invalid) {
      console.log("Form is invalid");
      this.snackbar.error('Please complete the form correctly.');
      return;
    }

    if (!this.isEmailVerified) {
      console.log("Email is not verified");
      this.snackbar.error('Email verification is incomplete.');
      return;
    }

    if (!this.isMobileVerified) {
      console.log("Mobile is not verified");
      this.snackbar.error('Mobile verification is incomplete.');
      return;
    }

    console.log("Form is valid, proceeding with registration");
    this.registerUser();
  }

  registerUser() {
    this.regService
      .registerUser(
        this.form.get("userFirstName")?.value,
        this.form.get("userFirstName")?.value,
        this.form.get("userLastName")?.value,
        this.form.get("userLastName")?.value,
        this.form.get("userEmail")?.value,
        this.form.get("userMobile")?.value,
        this.form.get("activeFlag")?.value,
        this.form.get("usrPassword")?.value
      )
      .then((data: any) => {
        const data1 = JSON.parse(data);

        if (data1.status == "Success") {
          localStorage.setItem("userId", data1.userId);
          this.snackbar.success("Registration successful.");
          this.router.navigateByUrl("");
        } else if (data1.status == "Failed") {
          this.snackbar.error("Error: " + data1["message"]);
        } else if (data1.message == "Mobile number already exists.") {
          this.snackbar.error("Error: " + data1["message"]);
        }
      });
  }


  goToDigiLocker() {
    this.router.navigateByUrl("/digiLockerLogin");
  }

  alphaOnly(event: KeyboardEvent): boolean {
    const key = event.key;
    const isAlpha = /^[a-zA-Z]$/.test(key);
    return isAlpha;
  }

}
