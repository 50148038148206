import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from "../../_services/master-data/users.service";
import { SnackbarMsgService } from "../../shared/Toast-service/snackbar-msg.service";
import { CustomValidatorService } from "src/app/_services/custom-validator.service";
import { CommonService } from "src/app/_services/Common-Services/common.service";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {
  form!: FormGroup;
  userId: string | null = '';
  designations: any[] = [];
  designationName: string = '';
  ugpPrevId = Number(localStorage.getItem('ugpPrevId'));
  resp = {
    essentials: {
      requestId: null,
    },
    id: "652f83749a481c0028e3dbb6",
    patronId: "651ee0f56b624b002a5c08e7",
    url: null,
    requestId: null,
    task: "url",
    result: {
      url: "https://api.digitallocker.gov.in/public/oauth2/1/authorize?client_id=3DF9D55E&code_challenge=8LXkRuOlYU1kUCSUgp5FEE4HcJbHcenofwot6FFnDfw&code_challenge_method=S256&dl_flow&redirect_uri=https%3A%2F%2Fdigilocker.signzy.tech%2Fdigilocker-auth-complete&response_type=code&state=652f8374e59f510012c714bb",
      requestId: "652f8374e59f510012c714bb",
    },
  };

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private commonService: CommonService,
  ) {}

  ngOnInit() {

    this.route.queryParamMap.subscribe(params => {
      this.userId = params.get('userId');
      // Use the userId as needed
      console.log('User ID:', this.userId);
    });
  
  this.getDesignationData();
  this.getUsersDetails();
 

    this.form = this.fb.group(
      {
        userFirstname: [""],
        userMiddleName: [""],
        userLastname: [""],
        userName: [""],
        userEmail: [""],
        userMobile: [''],
        // usrPassword: [""],
        dptName: [""],
        gender: [""],
        dob: [""],
        adress: [""],
        state: [''],
        city: [""],
        designationId :  [""],
        designationName :  [""],
      },
    );

    console.log("ugpPrevId",this.ugpPrevId);

    this.form.disable();
  }

  getDesignationData() {
    this.commonService.getDesignation().subscribe((data: any) => {
      this.designations = data["designationMasterList"] as any[];
    });
  }
  getUsersDetails() {
  this.commonService.getUsersById(this.userId).then((data : any) => {
    this.form.patchValue(
      {
        'dptName' : data.dptName,
        'subDepId' : data.subDptId,
        'adress' : data.adress,
        'designationId' : data.designationId,
        'userMiddleName' : data.userMiddleName,
        'usrId' : data.id,
        'wardId' : data.wardId,
        'userFirstname' : data.userFirstName,
        'firstNameMr' : data.userFirstNameMr,
        'userLastname' : data.userLastName,
        'userName' : data.userName,
        'lastNameMr' : data.userLastNameMr,
        'gender' : data.gender,
        'userEmail' : data.userEmail,
        'userMobile' : data.userMobile,
        // 'usrPassword' : data.userPassword,    

      });
      this.setDesignationName(data.designationId);

  });
}

setDesignationName(designationId: string) {
  const designation = this.designations.find(d => d.id === designationId);
  console.log("designation",designation);
  if (designation) {
    this.designationName = designation.designation; // Adjust the property name if necessary
    this.form.get('designationName')?.setValue(this.designationName);
  }
}
}
