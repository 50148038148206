import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AssetServiceService {
  constructor(private http: HttpClient) {}

  getAssetType() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assettype/getAllDashboard`
    );
  }

  getActiveAssetType() {
    return this.http.get(`${environment.assetUrl}/api/master/assettype/getAll`);
  }

  activateInactivateAssetType(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assettype/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAssetType(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assettype/save`,
      data
      
    );
  }

  getAssetSubType() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetsubtype/getAllDashboard`
    );
    
  }

  activateInactivateAssetSubType(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetsubtype/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAssetSubType(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetsubtype/save`,
      data
    );
  }

  getAssetCategory() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetcategory/getAllDashboard`
    );
  }

  activateInactivateAssetCategory(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetcategory/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAssetCategory(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetcategory/save`,
      data
    );
  }

  getAssetSubCategory() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetsubcategory/getAllDashboard`
    );
  }
  getActviveAssetSubCategory() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetsubcategory/getAll`
    );
  }

  activateInactivateAssetSubCategory(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetsubcategory/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAssetSubCategory(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetsubcategory/save`,
      data
    );
  }

  getActiveAssetCategory() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetcategory/getAll`
    );
  }

  getAssetEvaluator() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetevaluator/getAllDashboard`
    );
  }

  activateInactivateAssetEvaluator(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetevaluator/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAssetEvaluator(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetevaluator/save`,
      data
    );
  }

  //asset master
  getAsset() {
    return this.http.get(
      `${environment.assetUrl}/api/master/asset/getAllDashboard`
    );
  }

  getActiveAsset() {
    return this.http.get(`${environment.assetUrl}/api/master/asset/getAll`);
  }

  activateInactivateAsset(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/asset/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAsset(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/asset/save`,
      data
    );
  }

  //equipment breakdown type
  getEquipmentBreakdownType() {
    return this.http.get(
      `${environment.assetUrl}/api/master/equipmentbreakdowntype/getAllDashboard`
    );
  }

  getActiveEquipmentBreakdownType() {
    return this.http.get(`${environment.assetUrl}/api/master/equipmentbreakdowntype/getAll`);
  }

  activateInactivateEquipmentBreakdownType(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/equipmentbreakdowntype/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveEquipmentBreakdownType(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/equipmentbreakdowntype/save`,
      data
    );
  }

  // Asset status
  getAssetStatus() {
    return this.http.get(
      `${environment.assetUrl}/api/master/status/getAll`
    );
  }
  getActiveAssetStatus() {
    return this.http.get(
      `${environment.assetUrl}/api/master/status/getAll?activeFlag=Y`
    );
  }
  
  activeInactiveAssetStatus(id,flag) {
    return this.http.post(
      `${environment.assetUrl}/api/master/status/activeInActive?id=${id}&activeFlag=${flag}`,{}
    );
  }

  saveAssetStatus(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/status/save`,
      data
    );
  }

  

  getAllAssetEvaluator() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetevaluator/getAll`
    );
  }


  getAssetSubTypeById(data) {
    return this.http.get(`${environment.assetUrl}/api/master/assetsubtype/getByAssetTypeId?assetTypeId=${data}`)
  }
 getAssetSubCategoryBy(data) {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetsubcategory/getByAssetCategoryId?assetCategoryId=${data}`,
    );
  }

  getAllAsset() {
    return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getAllActiveList`);
  }
  getActiveAssetEvaluator() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetevaluator/getAllActiveList`
    );
  }
  saveAssetValuation(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/requestValuation/save`,
      data
    );
  }

  // getAllAssetValuator() {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/requestValuation/getAll`
  //   );
  // }

    getAllAssetValuator() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/requestValuation/getAllV3`
    );
  }

  getAssetValuatorById(id) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/requestValuation/getById?id=${id}`
    );
  }
  getAssetInsuranceById(id) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/assetInsurance/getById?id=${id}`
    );
  }

  // saveEBreakDown(data: any) {
  //   return this.http.post(
  //     `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/save`,
  //     data
  //   );
  // }
  saveEBreakDown(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/saveV1`,
      data
    );
  }

  assetMaintenanceApproved(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/scrutinyForApprove/`,
      data
      
    );
  }
  assetMaintenanceReject(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/scrutinyForReject/`,
      data
    );
  }

  getAllEBreakdownDetails() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/getAll`
    );
  }
  getAllEBreakdownById(id) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/getById?id=${id}`
    );
  }
  // getAllAssetInsurance() {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/assetInsurance/getAll`
  //   );
  // }

    getAllAssetInsurance() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/assetInsurance/getAllV1`
    );
  }

  saveAssetInsurance(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/assetInsurance/save`,
      data
    );
  }
  saveInsuranceIncident(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/insuranceIncident/save`,
      data
    );
  }
  // getAllInsuranceIncident() {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/insuranceIncident/getAll`
  //   );
  // }
  // getAllInsuranceIncident() {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/insuranceIncident/getAllV1`
  //   );
  // }
   getAllInsuranceIncident() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/insuranceIncident/getAllV2`
    );
  }
  // getInsuranceIncidentById(id) {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/insuranceIncident/getById?id=${id}`
  //   );
  // }

    getInsuranceIncidentById(id) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/insuranceIncident/getByIdV1?id=${id}`
    );
  }

  //asset-record
  getAllUsagetype(){
    return this.http.get(
      `${environment.assetUrl}/api/master/usageType/getAll`,
    );
  }
// createOrUpdateAssetRecord(data: any)  {
//     return this.http.post(`${environment.assetUrl}/api/transaction/assetCreateUpdate/save`,data);
//   }
createOrUpdateAssetRecord(data: any)  {
    return this.http.post(`${environment.assetUrl}/api/transaction/assetCreateUpdate/saveV1`,data);
  }

  // getAllAssetRecords(){
  //   return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getAll `);
  // }

    getAllAssetRecords(){
    return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getAllV1 `);
  }

  // getById(id) {
  //   return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getById?id=${id}`);
  // }

  getById(id) {
     return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getByIdV1?id=${id}`);
  }

  // Maintenance checklist

  getCheckList() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/maintenanceChecklist/getAll`
    );
  }
  getActiveCheckList() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/maintenanceChecklist/getAll?activeFlag=Y`
    );
  }

  saveCheckList(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/maintenanceChecklist/save`,
      data
    );
  }

  activeInactiveCheckListStatus(data) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/maintenanceChecklist/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  getByIdCheckList(id)
  {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/maintenanceChecklist/getById?id=${id}`
    );
  }

  //CheckList Item
  
  getCheckListItem() {
    return this.http.get(
      `${environment.assetUrl}/api/master/mstMaintenanceChecklistItem/getAll`
    );
  }
  saveCheckListItem(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/mstMaintenanceChecklistItem/save`,
      data
    );
  }

  activeInactiveCheckListStatusItem(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/mstMaintenanceChecklistItem/save`,
      data
    );
  }

  //Equipment Part master

  getEquipmentPart() {
    return this.http.get(
      `${environment.assetUrl}/api/master/equipment/getAll`
    );
  }
  saveEquipmentPart(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/equipment/save`,
      data
    );
  }

  activeInactiveEquipmentPart(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/equipment/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      data
    );
  }
  getAllActiveEquipmentPart() {
    return this.http.get(
      `${environment.assetUrl}/api/master/equipment/getAllActiveList`
    );
  }

  scrutinyForRegistrationApprove(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/scrutinyForApprove`,
      data
    );
  }

  scrutinyForRegistrationReject(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/scrutinyForReject`,
      data
    );
  }


  //Asset create-update

  getPlotType() {
    return this.http.get(
      `${environment.assetUrl}/api/master/plotType/getAll`
    );
  }


  getPlotNumber() {
    return this.http.get(
      `${environment.assetUrl}/api/master/plotNumber/getAll`
    );
  }
  getByIdentificationNumber(identificationNo: string) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/assetCreateUpdate/getByIdentificationNo?identificationNo=${identificationNo}`
    );
  }
getByIdentificationNoAndFinancialYear(identificationNo: string, financialYearId: string) {
  return this.http.get(
    `${environment.assetUrl}/api/transaction/requestValuation/getByIdentificationNoAndFinancialYear?IdentificationNo=${identificationNo}&financialYearId=${financialYearId}`
  );
}

getAllByIdentificationNo(identificationNo : string) {
  return this.http.get(
    `${environment.assetUrl}/api/transaction/requestValuation/getAllByIdentificationNo?identificationNo=${identificationNo}`
  );
}
// getInsuranceDetailsV1(identificationNo : string){
//   return this.http.get(
//     `${environment.assetUrl}/api/transaction/assetInsurance/getByIdentificationNo?identificationNo=${identificationNo}`
//   );
// }

getInsuranceDetails(identificationNo : string){
  return this.http.get(
    `${environment.assetUrl}/api/transaction/assetInsurance/getAllByIdentificationNoV1?identificationNo=${identificationNo}`
  );
}

// getInsuranceIncidentDetails(identificationNo : string){
//   return this.http.get(
//     `${environment.assetUrl}/api/transaction/insuranceIncident/getByIdentificationNo?identificationNo=${identificationNo}`
//   );
// }

getInsuranceIncidentDetails(identificationNo : string){
  return this.http.get(
    `${environment.assetUrl}/api/transaction/insuranceIncident/getByIdentificationNoV1?identificationNo=${identificationNo}`
  );
}
}
