import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SectorMasterService {

  constructor(private httpClient: HttpClient) { }

  getSector(){
    var url = `${environment.commonUrl}` + '/api/master/sector/getAllSector'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  getSector1() {
    const url = `${environment.commonUrl}/api/master/sector/getAllSector`;
  
    return this.httpClient.get(url);
  }
  

  saveSector(sectorMr : any,sector : any,activeFlag : any,zoneId,wardId : any, stateId : any,districtId : any,talukaId : any,nodeId : any,id : any){
    let body = {}
    

    body = {
      sectorNameMr : sectorMr,
      sectorName : sector,
      activeFlag : activeFlag,
      zoneId : zoneId,
      wardId : wardId,
      stateId : stateId,
      districtId : districtId,
      talukaId : talukaId,
      nodeId : nodeId,
      id : id


    }

    // const formData = new FormData();
    // formData.append("mstDepartmentDao", JSON.stringify(body));

    var url = `${environment.commonUrl}` + '/api/master/sector/save'

    return new Promise(resolve => {
      this.httpClient.post(url,body).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })

  }

  activateInactivateSector(id,data){
    return this.httpClient.post(`${environment.commonUrl}/api/master/sector/active/${id}`,data)
  }
 
}




