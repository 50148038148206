import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent {

  formId: string = '';
  formData: any = {
    "form_name": "भाडेकरू नोंदणी फॉर्म", // Form Name
    "form_description": "किरायादाराची माहिती भरा.", // Form Description
    "fields": [
      {
        "field_name": "आपलं नाव", // Your Name
        "field_type": "text",
        "field_label": "आपलं नाव",
        "field_options": {
          "placeholder": "आपलं पूर्ण नाव",
          "required": true
        }
      },
      {
        "field_name": "पत्ता", // Address
        "field_type": "textarea",
        "field_label": "पत्ता",
        "field_options": {
          "placeholder": "आपल्याच्या पत्त्याचा विस्तार करा",
          "required": true
        }
      },
      {
        "field_name": "मोबाइल नंबर", // Mobile Number
        "field_type": "tel",
        "field_label": "मोबाइल नंबर",
        "field_options": {
          "placeholder": "मोबाइल नंबर",
          "required": true
        }
      },
      {
        "field_name": "ईमेल पत्ता", // Email Address
        "field_type": "email",
        "field_label": "ईमेल पत्ता",
        "field_options": {
          "placeholder": "ईमेल पत्ता",
          "required": true
        }
      },
      {
        "field_name": "किरायादाराचे नाव", // Landlord's Name
        "field_type": "text",
        "field_label": "किरायादाराचे नाव",
        "field_options": {
          "placeholder": "किरायादाराचे पूर्ण नाव",
          "required": true
        }
      },
      {
        "field_name": "पूर्ण पत्ता", // Landlord's Address
        "field_type": "textarea",
        "field_label": "पूर्ण पत्ता",
        "field_options": {
          "placeholder": "किरायादाराच्या पत्त्याचा विस्तार करा",
          "required": true
        }
      },
      {
        "field_name": "किरायादाराचा मोबाइल नंबर", // Landlord's Mobile Number
        "field_type": "tel",
        "field_label": "किरायादाराचा मोबाइल नंबर",
        "field_options": {
          "placeholder": "किरायादाराचा मोबाइल नंबर",
          "required": true
        }
      },
      {
        "field_name": "प्रतिसाद व्यक्त करा", // Additional Comments
        "field_type": "textarea",
        "field_label": "प्रतिसाद व्यक्त करा",
        "field_options": {
          "placeholder": "अधिक माहिती असल्यास ती तपासा",
          "required": false
        }
      }
    ]
  };

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.formId = params['id'];
      //alert(`Form ID: ${this.formId}`);
    });

    // if formId is FORM0001, then the form data will be as follows: else formdata will be blank
    if(this.formId === 'FORM001') {
      this.formData = {
        "form_name": "भाडेकरू नोंदणी फॉर्म", // Form Name
        "form_description": "किरायादाराची माहिती भरा.", // Form Description
        "fields": [
          {
            "field_name": "आपलं नाव", // Your Name
            "field_type": "text",
            "field_label": "आपलं नाव",
            "field_options": {
              "placeholder": "आपलं पूर्ण नाव",
              "required": true
            }
          },
          {
            "field_name": "पत्ता", // Address
            "field_type": "textarea",
            "field_label": "पत्ता",
            "field_options": {
              "placeholder": "आपल्याच्या पत्त्याचा विस्तार करा",
              "required": true
            }
          },
          {
            "field_name": "मोबाइल नंबर", // Mobile Number
            "field_type": "tel",
            "field_label": "मोबाइल नंबर",
            "field_options": {
              "placeholder": "मोबाइल नंबर",
              "required": true
            }
          },
          {
            "field_name": "ईमेल पत्ता", // Email Address
            "field_type": "email",
            "field_label": "ईमेल पत्ता",
            "field_options": {
              "placeholder": "ईमेल पत्ता",
              "required": true
            }
          },
          {
            "field_name": "किरायादाराचे नाव", // Landlord's Name
            "field_type": "text",
            "field_label": "किरायादाराचे नाव",
            "field_options": {
              "placeholder": "किरायादाराचे पूर्ण नाव",
              "required": true
            }
          },
          {
            "field_name": "पूर्ण पत्ता", // Landlord's Address
            "field_type": "textarea",
            "field_label": "पूर्ण पत्ता",
            "field_options": {
              "placeholder": "किरायादाराच्या पत्त्याचा विस्तार करा",
              "required": true
            }
          },
          {
            "field_name": "किरायादाराचा मोबाइल नंबर", // Landlord's Mobile Number
            "field_type": "tel",
            "field_label": "किरायादाराचा मोबाइल नंबर",
            "field_options": {
              "placeholder": "किरायादाराचा मोबाइल नंबर",
              "required": true
            }
          },
          {
            "field_name": "प्रतिसाद व्यक्त करा", // Additional Comments
            "field_type": "textarea",
            "field_label": "प्रतिसाद व्यक्त करा",
            "field_options": {
              "placeholder": "अधिक माहिती असल्यास ती तपासा",
              "required": false
            }
          }
        ]
      };
    }
    else{
      this.formData = {
        "form_name": "Birth Certificate Request",
        "form_description": "Please fill out this form to request a birth certificate.",
        "fields": [
          {
            "field_name": "full_name",
            "field_label": "Full Name",
            "field_type": "text",
            "field_options": {
              "placeholder": "Enter your full name",
              "required": true
            }
          },
          {
            "field_name": "date_of_birth",
            "field_label": "Date of Birth",
            "field_type": "date",
            "field_options": {
              "placeholder": "Select your date of birth",
              "required": true
            }
          },
          {
            "field_name": "place_of_birth",
            "field_label": "Place of Birth",
            "field_type": "text",
            "field_options": {
              "placeholder": "Enter your place of birth",
              "required": true
            }
          },
          {
            "field_name": "mother_name",
            "field_label": "Mother's Name",
            "field_type": "text",
            "field_options": {
              "placeholder": "Enter your mother's full name",
              "required": true
            }
          },
          {
            "field_name": "father_name",
            "field_label": "Father's Name",
            "field_type": "text",
            "field_options": {
              "placeholder": "Enter your father's full name",
              "required": true
            }
          },
          {
            "field_name": "gender",
            "field_label": "Gender",
            "field_type": "select",
            "field_options": {
              "placeholder": "Select your gender",
              "required": true,
              "choices": ["Male", "Female", "Other"]
            }
          },
          {
            "field_name": "address",
            "field_label": "Address",
            "field_type": "textarea",
            "field_options": {
              "placeholder": "Enter your current address",
              "required": true
            }
          },
          {
            "field_name": "identification_proof",
            "field_label": "Identification Proof",
            "field_type": "file",
            "field_options": {
              "label": "Upload a scanned copy of your identification proof",
              "required": true
            }
          },
          {
            "field_name": "additional_information",
            "field_label": "Additional Information",
            "field_type": "textarea",
            "field_options": {
              "placeholder": "Enter any additional information or instructions",
              "required": false
            }
          },
          {
            "field_name": "request_type",
            "field_label": "Request Type",
            "field_type": "select",
            "field_options": {
              "placeholder": "Select request type",
              "required": true,
              "choices": ["Standard Request", "Urgent Request"]
            }
          },
          {
            "field_name": "contact_number",
            "field_label": "Contact Number",
            "field_type": "tel",
            "field_options": {
              "placeholder": "Enter your contact number",
              "required": true
            }
          },
          {
            "field_name": "requested_delivery_date",
            "field_label": "Requested Delivery Date",
            "field_type": "date",
            "field_options": {
              "placeholder": "Select your preferred delivery date",
              "required": false
            }
          },
          // Fields related to hospital, birth center, or home birth
          {
            "field_name": "birth_location_type",
            "field_label": "Birth Location Type",
            "field_type": "radio",
            "field_options": {
              "choices": ["Hospital", "Birth Center", "Home"]
            }
          },
          {
            "field_name": "hospital_name",
            "field_label": "Hospital Name",
            "field_type": "text",
            "field_options": {
              "placeholder": "Enter the hospital name",
              "required": false
            }
          },
          {
            "field_name": "birth_center_name",
            "field_label": "Birth Center Name",
            "field_type": "text",
            "field_options": {
              "placeholder": "Enter the birth center name",
              "required": false
            }
          },
          {
            "field_name": "home_birth_details",
            "field_label": "Home Birth Details",
            "field_type": "textarea",
            "field_options": {
              "placeholder": "Enter details about the home birth (if applicable)",
              "required": false
            }
          }
          // Add more fields as needed...
        ]
      };
      

    }

  }

  

  
  

}
