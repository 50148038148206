import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { AssetServiceService } from 'src/app/_services/assetService/asset-service.service';
import { WardService } from 'src/app/_services/ward.service';
import { ZoneService } from 'src/app/_services/zone.service';

@Component({
  selector: 'app-add-edit-asset',
  templateUrl: './add-edit-asset.component.html',
  styleUrls: ['./add-edit-asset.component.scss']
})
export class AddEditAssetComponent {

  userId : any = ''
  createdUserId : any = ''
  updateUserId : any = ''
  addedit : boolean = false
  id : any = ''
  assetForm: FormGroup; 
  zones : any[] = []
  wards : any[] = []
  nodes : any[] = []
  sectors : any[] = []
  areas : any[] = []
  constructor(
    private fb: FormBuilder, // Inject the FormBuilder
    private assetService: AssetServiceService,
    private _snackBar: SnackbarMsgService,
    private snackbar : MatSnackBar,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditAssetComponent>,
    private wardsService : WardService,
    private zoneService : ZoneService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.userId = localStorage.getItem("userId");
    this.createdUserId=this.userId;
    this.updateUserId=this.userId;
    this.addedit = this.data['isEditMode'];

    this.assetForm = this.fb.group({
      wardId: ['', Validators.required],
      nodeId: ['', Validators.required],
      sectorId: ['', Validators.required],
      areaId: ['', Validators.required],
      departmentId: ['', Validators.required],
      subDepartmentId: ['', Validators.required],
      assetName: ['', Validators.required],
      assetDescription: ['', Validators.required],
      assetAddress: ['', Validators.required],
      assetPincode: ['', Validators.required],
      assetTypeId: ['', Validators.required],
      assetSubTypeId: ['', Validators.required],
      assetCategoryId: ['', Validators.required],
      assetSubCategoryId: ['', Validators.required],
      aquisitionDate: ['', Validators.required],
      aquisitionCost: ['', Validators.required],
      assetEvaluatorId: ['', Validators.required],
      usageInformation: ['', Validators.required],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      documents: ['', Validators.required],
      remark: ['', Validators.required],
      organizationId: ['', Validators.required],
      indivisual: ['', Validators.required],
      internal: ['', Validators.required],
      previousOwnerName: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      ownerSince: ['', Validators.required],
      previousDepartmentId: ['', Validators.required],
      previousSubDepartmentId: ['', Validators.required],
      previousOwnerLatitude: ['', Validators.required],
      previousOwnerLongitude: ['', Validators.required],
      previousOwnerDocuments: ['', Validators.required],
      previousOwnerRemark: ['', Validators.required],
      activeFlag: ['Y'],



        });

        this.getZone()
    if (this.addedit) {
      const data12 = this.data['row'];
      this.createdUserId=data12['createdUserId'];
      this.updateUserId=this.userId;
      this.id = data12['id'];
      // Patch values to the form if in edit 
      this.assetForm.patchValue({
        wardId: data12['wardId'],
      nodeId: data12['nodeId'],
      sectorId: data12['sectorId'],
      areaId: data12['areaId'],
      departmentId: data12['departmentId'],
      subDepartmentId: data12['subDepartmentId'],
      assetName: data12['assetName'],
      assetDescription: data12['assetDescription'],
      assetAddress: data12['assetAddress'],
      assetPincode: data12['assetPincode'],
      assetTypeId: data12['assetTypeId'],
      assetSubTypeId: data12['assetSubTypeId'],
      assetCategoryId: data12['assetCategoryId'],
      assetSubCategoryId: data12['assetSubCategoryId'],
      aquisitionDate: data12['aquisitionDate'],
      aquisitionCost: data12['aquisitionCost'],
      assetEvaluatorId: data12['assetEvaluatorId'],
      usageInformation: data12['usageInformation'],
      latitude: data12['latitude'],
      longitude: data12['longitude'],
      documents: data12['documents'],
      remark: data12['remark'],
      organizationId: data12['organizationId'],
      indivisual: data12['indivisual'],
      internal: data12['internal'],
      previousOwnerName: data12['previousOwnerName'],
      firstName: data12['firstName'],
      middleName: data12['middleName'],
      lastName: data12['lastName'],
      gender: data12['gender'],
      email: data12['email'],
      mobile: data12['mobile'],
      ownerSince: data12['ownerSince'],
      previousDepartmentId: data12['previousDepartmentId'],
      previousSubDepartmentId: data12['previousSubDepartmentId'],
      previousOwnerLatitude: data12['previousOwnerLatitude'],
      previousOwnerLongitude: data12['previousOwnerLongitude'],
      previousOwnerDocuments: data12['previousOwnerDocuments'],
      previousOwnerRemark: data12['previousOwnerRemark4'],
      activeFlag: ['Y'],


        });
    }
  }

  getZone(){
    this.zoneService.getZone().then((data : any) => {
      this.zones = JSON.parse(JSON.stringify(data))
    })
  }

  getWards(event : any){
    this.wardsService.getWardByZoneId(event).subscribe(
      (res) => {
        this.wards = res as any[]
      },
      (err) => {

      }
    )
  }
}
