<div class="bg-gray-100 p-6">
    <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
      close
    </mat-icon>
  
    <div class="mx-auto max-w-md">
      <h1 class="text-2xl font-bold mb-8">{{addedit?'Edit':'Add'}} Caste</h1>
  
      <form (ngSubmit)="saveCaste()" #meetingForm="ngForm">
         
        <mat-form-field class="w-full">
          <mat-label for="zoneSelect">Select Religion</mat-label>
          <mat-select  [(ngModel)]="religionId" (ngModelChange)="religinChange($event)" name="zoneId" id="zoneSelect">
            <mat-option *ngFor="let religion of religions" [value]="religion.id">
                  {{religion.religion}}
              </mat-option>
              <mat-option *ngIf="religions?.length === 0" [value]="'noOptions'">No options available</mat-option>
          </mat-select>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>Enter Caste</mat-label>
        <input matInput [(ngModel)]="cast" name="cast" required>
      </mat-form-field>

      <div class="relative z-0 w-full mb-5" >
        <label for="description" class="block text-gray-600">Description</label>
        <input [(ngModel)]="description" type="text" id="description" name="description"
          class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required />
      </div>
     
        <button type="submit" class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none" 
        [disabled]="!meetingForm.valid">
          Save
        </button>
        <div class="text-center text-red-500 mt-2">
          {{errorMessage}}
        </div>
      </form>
    </div>
  </div>
  