<div class=" bg-white p-6 ">
  <mat-horizontal-stepper [linear]="true" #stepper>
    <!-- Step 1 -->
    <form [formGroup]="form" (ngSubmit)="savePropTax($event)">
      <mat-step [stepControl]="form.controls['step1']">
        <ng-template matStepLabel></ng-template>
        <div formGroupName="step1">
          <!-- Screen 1 -->
          <h2>Property Holder Details</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">

            <div class="relative z-0 w-full">
              <!-- <label for="title" class="block mt-2 text-sm font-medium text-gray-900 dark:text-white">Title</label> -->

              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="title" id="title" placeholder="Select Title">
                  <mat-option value="Mr.">Mr.</mat-option>
                  <mat-option value="Mrs.">Mrs.</mat-option>
                  <!-- <mat-option value="Mrs.">Ms.</mat-option> -->
                </mat-select>
              </mat-form-field>

              <div *ngIf="form.get('step1.title').hasError('required') && form.get('step1.title').touched"
                class="text-red-500 text-sm mt-1 ml-1">
                Title is required
              </div>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Firstname</mat-label>
                  <input matInput formControlName="firstName" placeholder="firstName" />
                  <mat-error *ngIf="form.get('step1.firstName').hasError('required') && form.get('step1.firstName').touched">
                      First Name is required
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Middle Name</mat-label>
                  <input matInput formControlName="middleName" placeholder="middleName" />
                  <mat-error *ngIf="form.get('step1.middleName').hasError('required') && form.get('step1.middleName').touched">
                      Middle Name is required
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastName" placeholder="lastName" />
                  <mat-error *ngIf="form.get('step1.lastName').hasError('required') && form.get('step1.lastName').touched">
                      Last Name is required
                  </mat-error>
              </mat-form-field>
          </div>

            <div class="relative z-0 w-full">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="gender" placeholder="Select Gender">
                  <mat-option value="Male">Male</mat-option>
                  <mat-option value="Female">Female</mat-option>
                  <mat-option value="Other">Other</mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="form.get('step1.gender').hasError('required') && form.get('step1.gender').touched"
                class="text-red-500 text-sm mt-1 ml-1">
                Gender is required
              </div>
            </div>


            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Mobile</mat-label>
                  <input matInput formControlName="mobile" placeholder="Mobile" 
                         (keypress)="keyPress($event)"
                         onKeyPress="if(this.value.length==10) return false;" />
                  <mat-error *ngIf="form.get('step1.mobile')?.touched && form.get('step1.mobile')?.hasError('required')">
                      Mobile is required
                  </mat-error>
                  <mat-error *ngIf="form.get('step1.mobile')?.touched && form.get('step1.mobile')?.errors">
                      Please enter a valid Mobile Number
                  </mat-error>
              </mat-form-field>
          </div>
          

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Email</mat-label>
                  <input matInput type="email" formControlName="email" placeholder="john@gmail.com" />
                  <mat-error *ngIf="form.get('step1.email')?.touched && form.get('step1.email')?.hasError('required')">
                      Email is required
                  </mat-error>
                  <mat-error *ngIf="(form.get('step1.email')?.touched && form.get('step1.email')?.invalid)">
                      Enter a valid email address
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Aadhar No</mat-label>
                  <input matInput formControlName="adharNo" placeholder="adharNo" />
                  <mat-error *ngIf="form.get('step1.adharNo').hasError('required') && form.get('step1.adharNo').touched">
                      Aadhar No is required
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Pan No</mat-label>
                  <input matInput formControlName="panNo" placeholder="panNo" />
                  <mat-error *ngIf="form.get('step1.panNo').hasError('required') && form.get('step1.panNo').touched">
                      Pan No is required
                  </mat-error>
              </mat-form-field>
          </div>

          </div>

          <h2>Property Address Details</h2>

          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            <div class="relative z-0 w-full ">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select placeholder="Select Ward" formControlName="wardId">
                  <mat-option *ngFor="let ward of wards" [value]="ward.id">
                    {{ward.wardName}}
                  </mat-option>
                  <mat-option *ngIf="wards.length === 0" [value]="'noOptions'">No options available</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('step1.wardId')?.hasError('required')">
                  Please select ward
                </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="sectorId" placeholder="Select Sector">
                  <mat-option *ngFor="let zone of sectors" [value]="zone.id">
                    {{ zone.sectorName }}
                  </mat-option>
                  <mat-option *ngIf="sectors?.length === 0" [value]="'noOptions'">No options available</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('step1.sectorId')?.hasError('required')">
                  Please select sector
                </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full">
              <mat-form-field class="w-full" appearance="outline">
                <mat-select formControlName="nodeId" placeholder="Select Node" (selectionChange)="onNodeChange($event)">
                  <mat-option *ngFor="let node of nodes" [value]="node.id">
                    {{ node.nodeName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('step1.nodeId')?.hasError('required')">
                  Please select node
                </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="plotId" placeholder="Select Plot">
                  <mat-option *ngFor="let plot of plots" [value]="plot.id">
                    {{ plot.plotName }}
                  </mat-option>
                  <mat-option *ngIf="plots?.length === 0" [value]="'noOptions'">No options available</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('step1.plotId')?.hasError('required')">
                  Please select plot
                </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Survey</mat-label>
                  <input matInput formControlName="survey" placeholder="survey" />
                  <mat-error *ngIf="form.get('step1.survey').hasError('required') && form.get('step1.survey').touched">
                      survey is required.
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Survey No</mat-label>
                  <input matInput formControlName="surveyNo" placeholder="surveyNo" />
                  <mat-error *ngIf="form.get('step1.surveyNo').hasError('required') && form.get('step1.surveyNo').touched">
                      survey No is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Flat No</mat-label>
                  <input matInput formControlName="flatNo" placeholder="flatNo" />
                  <mat-error *ngIf="form.get('step1.flatNo').hasError('required') && form.get('step1.flatNo').touched">
                      Flat No is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="buildingId" placeholder="Select Building">
                  <mat-option *ngFor="let bd of building" [value]="bd.id">
                    {{ bd.building }}
                  </mat-option>
                  <mat-option *ngIf="building?.length === 0" [value]="'noOptions'">No options available</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('step1.buildingId')?.hasError('required')">
                  Please select building
                </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Society Name</mat-label>
                  <input matInput formControlName="societyName" placeholder="societyName" />
                  <mat-error
                      *ngIf="form.get('step1.societyName').hasError('required') && form.get('step1.societyName').touched">
                      Society Name is required
                  </mat-error>
              </mat-form-field>
            </div>

            <!-- <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Area</mat-label>
                  <input matInput formControlName="area" placeholder="area" />
                  <mat-error
                      *ngIf="form.get('step1.area').hasError('required') && form.get('step1.area').touched">
                      Area is required
                  </mat-error>
              </mat-form-field>
            </div> -->

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="areaId" placeholder="Select Area">
                  <mat-option *ngFor="let bd of area" [value]="bd.id">
                    {{ bd.areaName }}
                  </mat-option>
                  <mat-option *ngIf="area?.length === 0" [value]="'noOptions'">No options available</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('step1.areaId')?.hasError('required')">
                  Please select Area
                </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Landmark</mat-label>
                  <input matInput formControlName="landmark" placeholder="landmark" />
                  <mat-error
                      *ngIf="form.get('step1.landmark').hasError('required') && form.get('step1.landmark').touched">
                      Landmark is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Village</mat-label>
                  <input matInput formControlName="village" placeholder="village" />
                  <mat-error
                      *ngIf="form.get('step1.village').hasError('required') && form.get('step1.village').touched">
                      Village is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>City</mat-label>
                  <input matInput formControlName="city" placeholder="city" />
                  <mat-error
                      *ngIf="form.get('step1.city').hasError('required') && form.get('step1.city').touched">
                      City is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Pin Code</mat-label>
                  <input matInput formControlName="pinCode" placeholder="pinCode" />
                  <mat-error
                      *ngIf="form.get('step1.pinCode').hasError('required') && form.get('step1.pinCode').touched">
                      Pin Code is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <label for="search-gis"
                  class="block text-sm font-medium mt-2 text-gray-900 dark:text-white"></label>
              <button type="button"
                  class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">GIS</button>

              <!-- <div *ngIf="prioritization.get('searchGis').hasError('required') && prioritization.get('searchGis').touched"
                  class="text-red-500 text-sm mt-1 ml-1">
                  Search GIS is required
              </div> -->
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Lattitude</mat-label>
                  <input matInput formControlName="latitude" placeholder="latitude" />
                  <mat-error
                      *ngIf="form.get('step1.latitude').hasError('required') && form.get('step1.latitude').touched">
                      Lattitude is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Longitude</mat-label>
                  <input matInput formControlName="longitude" placeholder="longitude" />
                  <mat-error
                      *ngIf="form.get('step1.longitude').hasError('required') && form.get('step1.longitude').touched">
                      Longitude is required
                  </mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            <div class="col-span-full relative z-0 w-full ">
                <label for="license-number" class="block text-sm font-medium text-gray-900 dark:text-white">Postal
                  Address</label>
                <textarea type="text" id="license-number" formControlName="postalAddress"
                  class="w-full border rounded py-2 px-3"></textarea>
              </div>
          </div>
          <!-- Screen 1 -->
          <button matStepperNext class="float-right w-24" mat-raised-button color="primary"
            (click)="nextClicked(stepper)">Next</button>
        </div>

      </mat-step>
      <mat-step [stepControl]="form.controls['step2']" [completed]="form.get('step2').valid">
        <ng-template matStepLabel></ng-template>
        <div formGroupName="step2">
          <h2>Property Information</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            <div class="relative z-0 w-full mb-5">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="usageTypeId" placeholder="Select Usage">
                  <mat-option *ngFor="let bd of usageData" [value]="bd.id">
                    {{ bd.usagetype }}
                  </mat-option>
                  <mat-option *ngIf="usageData?.length === 0" [value]="'noOptions'">No options available</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full mb-5">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="subUsageTypeId" placeholder="Select Subusage">
                  <mat-option *ngFor="let bd of subUsageData" [value]="bd.id">
                    {{ bd.subusagetype }}
                  </mat-option>
                  <mat-option *ngIf="subUsageData?.length === 0" [value]="'noOptions'">No options available</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div></div>
            <div></div>
            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Area(In Sq
                      Mtr)</mat-label>
                  <input matInput formControlName="areaSqMtr" placeholder="areaSqMtr" />
                  <mat-error
                      *ngIf="form.get('step2.areaSqMtr').hasError('required') && form.get('step2.areaSqMtr').touched">
                      Area(In Sq Mtr) is required
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Parking Area(In Sq Mtr)</mat-label>
                  <input matInput formControlName="parkingAreaSqMtr" placeholder="parkingAreaSqMtr" />
                  <mat-error
                      *ngIf="form.get('step2.parkingAreaSqMtr').hasError('required') && form.get('step2.parkingAreaSqMtr').touched">
                      Parking Area(In Sq Mtr) is required
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Total Area(Area + Parking Area)</mat-label>
                  <input matInput formControlName="totalArea" placeholder="totalArea" />
                  <mat-error
                      *ngIf="form.get('step2.totalArea').hasError('required') && form.get('step2.totalArea').touched">
                      Total Area(Area + Parking Area) is required
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Construction Type</mat-label>
                  <input matInput formControlName="constructionType" placeholder="constructionType" />
                  <mat-error
                      *ngIf="form.get('step2.constructionType').hasError('required') && form.get('step2.constructionType').touched">
                      Construction Type is required
                  </mat-error>
              </mat-form-field>
          </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="propertyTypeId" placeholder="Select Property Type"
                  (selectionChange)="changepropertytype($event.value)">
                  <mat-option *ngFor="let type of propertyTypeData" [value]="type.id">
                    {{ type.propertytypeName }}
                  </mat-option>
                  <mat-option *ngIf="propertyTypeData?.length === 0" [value]="'noOptions'">No options
                    available</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="form.get('step2.propertyTypeId')?.hasError('required')   && form.get('step2.propertyTypeId').touched">
                  Please select Property Type
                </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="propertySubTypeId" placeholder="Select Property Sub Type">
                  <mat-option *ngFor="let type of propertySubtypeData" [value]="type.id">
                    {{ type.propertysubtypeName }}
                  </mat-option>
                  <mat-option *ngIf="propertySubtypeData?.length === 0" [value]="'noOptions'">No options
                    available</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="form.get('step2.propertySubTypeId')?.hasError('required')  && form.get('step2.propertySubTypeId').touched">
                  Please select property SubType
                </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Occupancy</mat-label>
                  <input matInput formControlName="occupancy" placeholder="occupancy" />
                  <mat-error
                      *ngIf="form.get('step2.occupancy').hasError('required') && form.get('step2.occupancy').touched">
                      Occupancy is required
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Ownership Type</mat-label>
                  <input matInput formControlName="ownershipType" placeholder="ownershipType" />
                  <mat-error
                      *ngIf="form.get('step2.ownershipType').hasError('required') && form.get('step2.ownershipType').touched">
                      Ownership Type is required
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Business Type</mat-label>
                  <input matInput formControlName="bussinessType" placeholder="bussinessType" />
                  <mat-error
                      *ngIf="form.get('step2.bussinessType').hasError('required') && form.get('step2.bussinessType').touched">
                      Business Type is required
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Industry Type</mat-label>
                  <input matInput formControlName="industryType" placeholder="industryType" />
                  <mat-error
                      *ngIf="form.get('step2.industryType').hasError('required') && form.get('step2.industryType').touched">
                      Industry Type is required
                  </mat-error>
              </mat-form-field>
          </div>      

          <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Building Permission No.</mat-label>
                  <input matInput formControlName="buildingPermissionNo" placeholder="buildingPermissionNo" />
                  <mat-error
                      *ngIf="form.get('step2.buildingPermissionNo').hasError('required') && form.get('step2.buildingPermissionNo').touched">
                      Building Permission No. is required
                  </mat-error>
              </mat-form-field>
          </div>

            <div class="relative z-0 w-full mb-5">
              <label for="buildingPermissionDate"
                class="block  text-sm font-medium text-gray-900 dark:text-white">Building
                Permission Date</label>
              <input type="date" formControlName="buildingPermissionDate" name="buildingPermissionDate"
                id="buildingPermissionDate"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="">
              <div
                *ngIf="form.get('step2.buildingPermissionDate').hasError('required') && form.get('step2.buildingPermissionDate').touched"
                class="text-red-500 text-sm mt-1 ml-1">
                Building Permission Date is required
              </div>
            </div>

            <div class="relative z-0 w-full mb-5">
              <label for="buildingCompletionDate"
                class="block  text-sm font-medium text-gray-900 dark:text-white">Building
                Completion Date</label>
              <input type="date" formControlName="buildingCompletionDate" name="buildingCompletionDate"
                id="buildingCompletionDate"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="">
              <div
                *ngIf="form.get('step2.buildingCompletionDate').hasError('required') && form.get('step2.buildingCompletionDate').touched"
                class="text-red-500 text-sm mt-1 ml-1">
                Building CompletionDate Date is required
              </div>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Occupancy Occupancy</mat-label>
                  <input matInput formControlName="occupancyCertificateNo" placeholder="occupancyCertificateNo" />
                  <mat-error
                      *ngIf="form.get('step2.occupancyCertificateNo').hasError('required') && form.get('step2.occupancyCertificateNo').touched">
                      Occupancy Occupancy is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full mb-5">
              <label for="occupancyCertificateDate"
                class="block  text-sm font-medium text-gray-900 dark:text-white">Occupancy
                Certificate Date</label>
              <input type="date" formControlName="occupancyCertificateDate" name="occupancyCertificateDate"
                id="occupancyCertificateDate"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="">
              <div
                *ngIf="form.get('step2.occupancyCertificateDate').hasError('required') && form.get('step2.occupancyCertificateDate').touched"
                class="text-red-500 text-sm mt-1 ml-1">
                Occupancy Certificate Date is required
              </div>
            </div>

            <div class="relative z-0 w-full mb-5">
              <label for="actualDateOfBuildingUsageTime"
                class="block  text-sm font-medium text-gray-900 dark:text-white">Actual building usage date</label>
              <input type="date" formControlName="actualDateOfBuildingUsageTime" name="actualDateOfBuildingUsageTime"
                id="actualDateOfBuildingUsageTime"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="">
              <div
                *ngIf="form.get('step2.actualDateOfBuildingUsageTime').hasError('required') && form.get('step2.actualDateOfBuildingUsageTime').touched"
                class="text-red-500 text-sm mt-1 ml-1">
                actual Date Of BuildingUsageTime is required
              </div>
            </div>
            <div class="relative z-0 w-full mb-5">
              <mat-form-field appearance="outline" class="w-full">
                <mat-select formControlName="amenities" placeholder="Select Amenities"
                  (selectionChange)="ammenitiesName($event.value)" multiple>
                  <mat-option *ngFor="let item of AmmenitiesData" [value]="item.id">
                    {{ item.ammenitiesName }}
                  </mat-option>
                  <mat-option *ngIf="AmmenitiesData?.length === 0" [value]="'noOptions'">No options
                    available</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="form.get('step2.amenities')?.hasError('required')  && form.get('step2.amenities').touched">
                  Please select amenities
                </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Property Status</mat-label>
                  <input matInput formControlName="propertyStatus" placeholder="propertyStatus" />
                  <mat-error
                      *ngIf="form.get('step2.propertyStatus').hasError('required') && form.get('step2.propertyStatus').touched">
                      Property Status is required
                  </mat-error>
              </mat-form-field>
            </div>

          </div>
          <button matStepperNext class="float-right w-24" mat-raised-button color="primary"
            (click)="nextClicked2(stepper)">Next</button>
          <button mat-raised-button color="primary" matStepperPrevious>Back</button>

        </div>
      </mat-step>
      <mat-step [stepControl]="form.controls['step3']">
        <ng-template matStepLabel></ng-template>
        <div formGroupName="step3">
          <h2>In case water connection available</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Water Connection Type</mat-label>
                  <input matInput formControlName="waterConnectionType" placeholder="waterConnectionType" />
                  <mat-error *ngIf="form.get('step3.waterConnectionType').hasError('required') && form.get('step3.waterConnectionType').touched">   
                      Water Connection Type is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>No. of Water Connections</mat-label>
                  <input matInput formControlName="noOfWaterConnections" placeholder="noOfWaterConnections" />
                  <mat-error *ngIf="form.get('step3.noOfWaterConnections').hasError('required') && form.get('step3.noOfWaterConnections').touched">
                    No. of Water Connections is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Water Connections No.</mat-label>
                  <input matInput formControlName="waterConnectionNo" placeholder="waterConnectionNo" />
                  <mat-error *ngIf="form.get('step3.waterConnectionNo').hasError('required') && form.get('step3.waterConnectionNo').touched">
                    Water Connections No. is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Diameter</mat-label>
                  <input matInput formControlName="diameter" placeholder="diameter" />
                  <mat-error *ngIf="form.get('step3.diameter').hasError('required') && form.get('step3.diameter').touched">
                    Diameter is required
                  </mat-error>
              </mat-form-field>
            </div>
          </div>
          <h2>Bank Details</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Bank Name</mat-label>
                  <input matInput formControlName="bankName" placeholder="bankName" />
                  <mat-error *ngIf="form.get('step3.bankName').hasError('required') && form.get('step3.bankName').touched">
                    Bank Name is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Bank IFSC</mat-label>
                  <input matInput formControlName="bankIfsc" placeholder="bankIfsc" />
                  <mat-error *ngIf="form.get('step3.bankIfsc').hasError('required') && form.get('step3.bankIfsc').touched">
                    Bank IFSC is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Bank Account Number</mat-label>
                  <input matInput formControlName="bankAccountNumber" placeholder="bankAccountNumber" />
                  <mat-error *ngIf="form.get('step3.bankAccountNumber').hasError('required') && form.get('step3.bankAccountNumber').touched">
                    Bank Account Number is required
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="relative z-0 w-full ">
              <mat-form-field appearance="fill" class="relative z-0 w-full ">
                  <mat-label>Bank Branch</mat-label>
                  <input matInput formControlName="bankBranch" placeholder="bankBranch" />
                  <mat-error *ngIf="form.get('step3.bankBranch').hasError('required') && form.get('step3.bankBranch').touched">
                    Bank Branch is required
                  </mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>
        <button mat-raised-button color="primary" matStepperPrevious>Back</button>
        <button type="submit" mat-raised-button color="primary" (click)="savePropTax(1)" class="float-right">
          Save
        </button>

      </mat-step>
    </form>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>


  </mat-horizontal-stepper>
  <!-- <h1 class="text-2xl font-bold ">Add Property Tax</h1> -->
  <form [formGroup]="form" (ngSubmit)="savePropTax($event)">
    
    <div class="flex gap-6">

    </div>
 
  </form>
  <br><br><br><br>
</div>