import { Component } from "@angular/core";

@Component({
  selector: "app-msmam-dashboard",
  templateUrl: "./msmam-dashboard.component.html",
  styleUrls: ["./msmam-dashboard.component.scss"],
})
export class MsmamDashboardComponent {
  masterItems = [
    { title: "Electoral Ward", routerLink: "/msmam-electoral-ward-master" },
    { title: "Meeting Type", routerLink: "/msmam-meeting-type-master" },
    { title: "offices", routerLink: "/msmam-offices-master" },
    { title: "Committee ", routerLink: "/msmam-committee-master" },
    {
      title: "Committee Establishment",
      routerLink: "/msmam-committee-establishment-master",
    },
    { title: "Religion ", routerLink: "/msmam-religion-master" },
    { title: "Caste", routerLink: "/msmam-caste-master" },
    { title: "Subcaste", routerLink: "/msmam-subcast-master" },
    { title: "Party", routerLink: "/msmam-party-master" },
    {
      title: "Identifiaction proof",
      routerLink: "/msmam-identifiactionproof-master",
    },
    {
      title: "Committee Members",
      routerLink: "/msmam-committee-members-master",
    },
    { title: "Meeting Schedule", routerLink: "/msmam-meeting-schedule-master" },
    { title: "Corporator", routerLink: "/msmam-corporator-master" },
    {
      title: "Calender Scheduling Holiday ",
      routerLink: "/msmam-calender-scheduling-master",
    },
    {
      title: "Media Channel",
      routerLink: "/msmam-media-channel-master",
    },
    {
      title: "Media",
      routerLink: "/msmam-media-master",
    },
    {
      title: "Journalist",
      routerLink: "/msmam-journalist-master",
    },
    {
      title: "Clerk to Committee",
      routerLink: "/msmam-clerk-to-committee-master",
    },
    // {title: 'New Docketentry',routerLink:'/msmam-new-docketentry-transaction'},
    // {title: 'Meeting Agenda',routerLink:'/msmam-prepare-meeting-agenda-transaction'},

    // {title: 'Victim Details',routerLink:'/disaster-victim-details'}
    // Add more master items as needed
  ];
}
