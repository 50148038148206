import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-scrutiny-actions-confirmation',
  templateUrl: './scrutiny-actions-confirmation.component.html',
  styleUrls: ['./scrutiny-actions-confirmation.component.scss']
})
export class ScrutinyActionsConfirmationComponent {

  action : any = ''
  text : any = ''
  remark : any = ''
  component : any = ''
  status : any = ''
  loadActions : boolean = false

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ScrutinyActionsConfirmationComponent>,) { }

  ngOnInit(): void {
    this.action = this.data.status
    console.log(this.action)
    if(this.action == 'Approval for Home Loan(TECHNICAL ENGINEER APPROVAL - (TOWN PLANNING))'){
      this.loadActions = true
    }
    console.log(this.loadActions)
    this.text = this.data.text
    this.component = this.data.component
    
  }

  onConfirm() {
    this.dialogRef.close({status :'success',remark : this.remark,file : this.file});
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  file : any
  showDocUpload : boolean = false
  uploadDocUpload(event : any){
    for(var i = 0; i < event.target.files.length; i++){
      this.file = event.target.files[i]
      if(this.file){
        this.showDocUpload = true
      }
    }
  }
}
