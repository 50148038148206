import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonService } from '../_services/Common-Services/common.service';

@Component({
  selector: 'app-mapped-dashboard',
  templateUrl: './mapped-dashboard.component.html',
  styleUrls: ['./mapped-dashboard.component.scss']
})
export class MappedDashboardComponent {

  allModules: any[] = [];
  menuItems = [
    {
      moduleName: "Assets Management",
      routerLink: "/asset-management/asset-dashboard",
      ugpPrevId: "2",
      icon: "perm_data_setting",
    },
    {
      moduleName: "Content Management System",
      routerLink: "/content-management/cms-dashboard",
      ugpPrevId: "2",
      icon: "content_paste",
    },
    {
      moduleName: "Central Workshop Management",
      routerLink: "/central-workshop",
      ugpPrevId: "2",
      icon: "directions_car",
    },
    {
      moduleName: "Core Module",
      routerLink: "/core-module/masterDashboard",
      ugpPrevId: "2",
      icon: "settings",
    },
    {
      moduleName: "Disaster Management",
      routerLink: "/disaster-management/disaster-dashboard",
      ugpPrevId: "2",
      icon: "warning",
    },
    {
      moduleName: "Enterprise Workflow Management",
      routerLink: "/enterprise-workflow-management",
      ugpPrevId: "2",
      icon: "compare_arrows",
    },
    {
      moduleName: "Financial Accounting",
      routerLink: ["/financial-accounting"],
      ugpPrevId: "2",
      icon: "account_balance",
    },
    {
      moduleName: "Financial Accounting",
      routerLink: ["/financial-accounting"],
      ugpPrevId: "7",
      icon: "account_balance",
    },
    {
      moduleName: "Grievance Management",
      routerLink: ["/grievance-management"],
      ugpPrevId: "2",
      icon: "transfer_within_a_station",
    },
    {
      moduleName: "Grievance Management",
      routerLink: ["/grievance-management/register"],
      ugpPrevId: "3",
      icon: "transfer_within_a_station",
    },
    {
      moduleName: "HR and Payroll Management",
      routerLink: "/hrms-module/hrms-dashboard",
      ugpPrevId: "2",
      icon: "people",
    },
    {
      moduleName: "Land And Estate",
      routerLink: "/land-Estate",
      ugpPrevId: "2",
      icon: "assignment",
    },
    {
      moduleName: "Material Management",
      routerLink: "/material-management",
      ugpPrevId: "2",
      icon: "file_copy",
    },
    {
      moduleName: "Municipal Secretary & Agenda Management",
      routerLink: "/msmam-dashboard",
      ugpPrevId: "2",
      icon: "settings_voice",
    },
    {
      moduleName: "Online Services(RTS)",
      routerLink: "/online-rts",
      icon: "online_prediction",
    },
    {
      moduleName: "Property Tax Management",
      routerLink: ["/property-tax/propertyTax-scrutiny"],
      ugpPrevId: "2",
      icon: "home",
    },
    {
      moduleName: "Solid Waste Management",
      routerLink: ["/solid-waste-management"],
      ugpPrevId: "2",
      icon: "delete",
    },
    {
      moduleName: "Survey Database Creation",
      routerLink: "/survey-database/survey-service-list",
      ugpPrevId: "3",
      icon: "map",
    },
    {
      moduleName: "Survey Database Creation",
      routerLink: "/survey-database/survey-dashboard",
      ugpPrevId: "2",
      icon: "map",
    },
    {
      moduleName: "Water Tax Management",
      routerLink: ["/water-tax/verificatin/dashboard"],
      ugpPrevId: "2",
      icon: "opacity",
    },
    {
      moduleName: "Works Management",
      routerLink: ["/work-management"],
      ugpPrevId: "2",
      icon: "work_outline",
    },
    {
      moduleName: "CFC",
      routerLink: ["/cfc"],
      ugpPrevId: "2",
      icon: "work_outline",
    },
  ];
  accessibleModules: any[] = [];
  currentModules : any[] = []
  showPandal : boolean = false
  userFullName : any = ''
  rolePrevId : any = ''

  constructor(
    private commonService: CommonService,
    private router: Router,
    private snackBar: MatSnackBar,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,


  ) {
    iconRegistry.addSvgIcon(
      "ganpati-pandal",
      sanitizer.bypassSecurityTrustResourceUrl("assets/ganesh.svg")
    );
   }

  ngOnInit() {
    this.showPandal = JSON.parse(localStorage.getItem('showPandalService'))
    console.log(this.showPandal)
    this.userFullName = localStorage.getItem('loginName')
    this.rolePrevId = localStorage.getItem('rolePrevId')
    this.getAllMOdules()
    this.getModules();
  }

  getAllMOdules(){
    this.commonService.getModules().subscribe((res : any) => {
      this.allModules = res
    })
  }

  getModules(): any[] {
    this.currentModules = JSON.parse(localStorage.getItem('modules'));
    this.menuItems.forEach(menu =>{
      this.currentModules.forEach(mod => {
        if(menu.moduleName == mod.moduleName){
          const modId = mod.moduleId
          mod.icon = menu.icon
        }

      })
    })
    this.currentModules.sort((a, b) => a.moduleName.localeCompare(b.moduleName));
    console.log(this.currentModules)
    try {
        return this.currentModules ? this.currentModules : [];
    } catch (error) {
        return [];
    }
  }

  getModuleByName(id: any) {
    const modules = this.getModules();
    // modules.forEach(module => {
  
    //   if(module['moduleName'] == id){
    //   }
    // })
    return modules.find(module =>
         module['moduleName'] == id
    );
  }


  navigateToModule(moduleName : any) {
    const module = this.getModuleByName(moduleName);
    if (module) {
      this.router.navigate([module['url']]);
    } else {
      this.snackBar.open(`You do not have access to ${moduleName}`, 'Close', {
        duration: 3000
      });
    }
  }
}
