import { AddIdentifiactionproofComponent } from "../identifiactionproof-master/add-identifiactionproof/add-identifiactionproof.component";
import { Component, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MsmamServiceService } from "src/app/_services/MSMAM/msmam-service.service";
import { MatDialog } from "@angular/material/dialog";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { AddMediaChannelMasterComponent } from "./add-media-channel-master/add-media-channel-master/add-media-channel-master.component";
import { ActivationInactivationConfirmationComponent } from "src/app/master-data/activation-inactivation-confirmation/activation-inactivation-confirmation.component";
@Component({
  selector: "app-media-channel-master-component",
  templateUrl: "./media-channel-master-component.component.html",
  styleUrls: ["./media-channel-master-component.component.scss"],
})
export class MediaChannelMasterComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;
  columnNames: any[] = [
    {
      columnDef: "sno",
      header: "Sr.No.",
    },
    {
      columnDef: "mediaChannelName",
      header: "Media Channel Name",
    },
    {
      columnDef: "actions",
      header: "Actions",
    },
  ];
  statusAction: any = "";
  mediaChannel = new MatTableDataSource<any>();
  displayedColumns: any[] = [];
  contentLoaded = false;
  checked: boolean = false;

  constructor(
    private msmService: MsmamServiceService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService
  ) {}
  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(
      this.columnNames.map((x) => x.columnDef)
    );
    this.getMediaChannelList();
  }

  getMediaChannelList() {
    this.msmService.getMediaChannelList().subscribe(
      (data) => {
        this.mediaChannel.data = data["mediaChannelList"];
        this.mediaChannel.data = this.mediaChannel.data.sort(
          (a, b) => a.id - b.id
        );
        setTimeout(() => {
          this.mediaChannel.sort = this.sort;
          this.mediaChannel.paginator = this.paginator;
        });

        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      },
      (err) => {
        this.contentLoaded = true;
      }
    );
  }

  addIdentificationProof() {
    const dialogRef = this.dialog.open(AddMediaChannelMasterComponent, {
      width: "550px",
      data: {
        isEditMode: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getMediaChannelList();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.mediaChannel.filter = filterValue.trim().toLowerCase();
  }

  onSlideToggleChange(row: any, event: any): void {
    if (event.checked == true) {
      this.statusAction = "Activate";
      row.activeFlag = "Y";
    } else {
      this.statusAction = "Deactivate";
      row.activeFlag = "N";
    }
    const checkFlag = row.activeFlag;
    var formData = {
      activeFlag: row.activeFlag,
    };

    const dialogRef = this.dialog.open(
      ActivationInactivationConfirmationComponent,
      {
        width: "550px",
        data: {
          mode: this.statusAction,
          component: "Media Channel",
        },
        autoFocus: false,
        restoreFocus: false,
      }
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        if (this.statusAction == "Activate") {
          this.msmService
            .activeInactiveMediaChannel(row.id, checkFlag)
            .subscribe(
              (res) => {
                this._snackBar.success("Selected Media Channel activated");
                this.getMediaChannelList();
              },
              (err) => {
                this._snackBar.error("Activation Failed");
                this.getMediaChannelList();
              }
            );
        } else {
          this.msmService
            .activeInactiveMediaChannel(row.id, checkFlag)
            .subscribe(
              (res) => {
                this._snackBar.success("Selected Media Channel Deactivated");
                this.checked = !this.checked;
                this.getMediaChannelList();
              },
              (err) => {
                this._snackBar.error("Deactivation Failed");
                this.getMediaChannelList();
              }
            );
        }
      } else {
        this.getMediaChannelList();
      }
    });
  }
  onEdit(row: any) {
    const dialogRef = this.dialog.open(AddMediaChannelMasterComponent, {
      width: "550px",
      data: {
        isEditMode: true,
        row: row,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getMediaChannelList();
      }
    });
  }
}
