<div class="flex-shrink-0 w-full sm:w-auto px-4 py-2 bg-gray-200 shadow-md rounded-md" *ngIf="showPandal == true">
  <h1 class="text-2xl font-bold text-gray-900">Welcome, {{userFullName}}</h1>
</div>

<div class="flex flex-col drop-shadow-lg p-6 mt-4 bg-white rounded-lg">
  <div class="most-favourite-services" *ngIf="showPandal == true || rolePrevId == '1'">
    <h1 class="text-2xl font-bold mb-4 text-gray-900">Most Favourite Services</h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
      <ng-container>
        <div
          [routerLink]="['/online-rts/application-service-form/01904e12-fd01-73ec-af71-2afe6b0a2ffb']"
          class="bg-white border-l-4 border-l-blue-700 p-6 rounded-lg shadow-md flex flex-col items-center justify-center text-center hover:bg-gray-200 transition duration-300 ease-in-out cursor-pointer transform hover:-translate-y-1 hover:shadow-lg"
          style="height: 180px;"
        >
          <div class="flex items-center justify-center" style="height: 60%; margin-top: 20px;">
            <mat-icon
              class="mb-2 sm:mb-4"
              style="color: #015296;"
              svgIcon="ganpati-pandal"
            ></mat-icon>
          </div>
          <h3 class="text-lg md:text-xl font-bold mt-4 text-gray-800 leading-snug">
            GANPATI PANDAL PERMISSION
          </h3>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 pt-4">
    <ng-container *ngFor="let module of currentModules">
      <div
        (click)="navigateToModule(module['moduleName'])"
        class="bg-white border-l-4 border-l-blue-700 p-6 rounded-lg shadow-md flex flex-col items-center justify-center text-center hover:bg-gray-200 transition duration-300 ease-in-out cursor-pointer transform hover:-translate-y-1 hover:shadow-lg"
        style="height: 180px;"
      >
        <div class="flex items-center justify-center" style="height: 60%; margin-top: 20px;">
          <!-- Keeping your original icon code -->
          <mat-icon
            class="mb-2 sm:mb-4"
            style="color: #015296;"
          >
            {{ module.icon }}
          </mat-icon>
        </div>
        <h3 class="text-lg md:text-xl font-bold mt-4 text-gray-800 leading-snug">
          {{ module['moduleName'] | titlecase }}
        </h3>
      </div>
    </ng-container>
  </div>
</div>
