import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from "../_services/auth.service";
import { MatSidenav } from "@angular/material/sidenav";
import { CommonService } from "../_services/Common-Services/common.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit {
  isAuthenticated: boolean = false;
  isSideNavOpen: boolean = false;
  isRTSServiceRoute: boolean = false;
  isSurveyRoute: boolean = false;
  isCWMRoute: boolean = false;
  isProtax: boolean = false;
  isDisaster: boolean = false;
  isMSMRoute: boolean = false;
  isCMSRoute: boolean = false;
  isEWMRoute: boolean = false;
  isMMRoute: boolean = false;
  isHrmsRoute: boolean = false;
  isSWMRoute: boolean = false;
  isLandEstateRoute: boolean = false;
  isWorkManagementRoute: boolean = false;
  panelOpenState: boolean = false;
  isGrivence: Boolean = false;
  isCommon: boolean = false;
  isAssetManagement: boolean = false;
  isFinancialAccounting: boolean = false;
  isWTRoute: boolean = false;
  @ViewChild("sidenav")
  sidenav!: MatSidenav;
  loginUser: any;
  loginName: any = "";
  designationName = "";
  departmentName = "";
  ugpId: any;
  ugpPrevId: any;
  dptId: any = "";
  desId: any = "";
  isLoggedIn = false;
  userId: any = localStorage.getItem("userId");

  constructor(
    private authService: AuthService,
    private router: Router,
    private commService: CommonService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loginUser = localStorage.getItem("login");
    this.userId = localStorage.getItem("userId");
    // this.authService.isAuthenticated().subscribe((isAuthenticated: any) => {
    //   this.isAuthenticated = isAuthenticated;
    //   // this.loginUser=localStorage.getItem('login');
    //   this.ugpPrevId = localStorage.getItem("ugpPrevId");
    //   this.loginName = localStorage.getItem("loginName");
    //   this.ugpId = localStorage.getItem("ugpId");

    // });

    // this.authService.currentUser.subscribe((loggedIn) => {
    //   this.isLoggedIn = loggedIn;
    //   if (this.isLoggedIn) {
    //     this.authService.isAuthenticated().subscribe((isAuthenticated: any) => {
    //       this.isAuthenticated = isAuthenticated;
    //       this.ugpPrevId = localStorage.getItem("ugpPrevId");
    //       this.loginName = localStorage.getItem("loginName");
    //       this.ugpId = localStorage.getItem("ugpId");
    //       if (this.ugpPrevId == "3") {
    //         this.loginUser = `citizen`;
    //       } else if (this.ugpPrevId == "2") {
    //         this.loginUser = `department`;
    //       } else if (this.ugpPrevId == "5") {
    //         this.loginUser = `Driver`;
    //       }
    //       this.updateUserDetails();
    //     });
    //   }
    // });

    this.authService.currentUser.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
      if (this.isLoggedIn) {
        this.authService.isAuthenticated().subscribe((isAuthenticated: any) => {
          this.isAuthenticated = isAuthenticated;
          this.ugpPrevId = localStorage.getItem("ugpPrevId");
          this.userId = localStorage.getItem("userId");
          this.loginName = localStorage.getItem("loginName");
          this.ugpId = localStorage.getItem("ugpId");
          this.dptId = localStorage.getItem("dptId");
          this.desId = localStorage.getItem("designationId");
          if (this.ugpPrevId == "3") {
            this.loginUser = `citizen`;
          } else if (this.ugpPrevId == "2") {
            this.loginUser = `department`;
          } else if (this.ugpPrevId == "5") {
            this.loginUser = `Driver`;
          }
          this.updateUserDetails();
        });
      } else {
        this.resetUserDetails();
      }
    });

    this.router.events.subscribe((event) => {
      // console.log(this.loginUser)
      if (event instanceof NavigationEnd) {
        this.isRTSServiceRoute = this.router.url.includes("online-rts");
      }
      if (event instanceof NavigationEnd) {
        this.isSurveyRoute = this.router.url.includes("survey-database");
      }

      if (event instanceof NavigationEnd) {
        // this.isProtax =
        this.isProtax =
          this.router.url.includes("property-master") ||
          this.router.url.includes("property-tax");
        // if (this.ugpPrevId == 3) {
        //   this.isProtax = true;
        // }
      }

      if (event instanceof NavigationEnd) {
        this.isDisaster = this.router.url.includes("disaster-management");
      }

      if (event instanceof NavigationEnd) {
        this.isCMSRoute = this.router.url.includes("content-management");
      }

      if (event instanceof NavigationEnd) {
        this.isMSMRoute =
          this.router.url.includes("msmam-dashboard") ||
          this.router.url.includes("msmam-caste-master") ||
          this.router.url.includes("msmam-committee-establishment-master") ||
          this.router.url.includes("msmam-committee-master") ||
          this.router.url.includes("msmam-committee-members-master") ||
          this.router.url.includes("msmam-corporator-master") ||
          this.router.url.includes("msmam-electoral-ward-master") ||
          this.router.url.includes("msmam-identifiactionproof-master") ||
          this.router.url.includes("msmam-meeting-schedule-master") ||
          this.router.url.includes("msmam-meeting-type-master") ||
          this.router.url.includes("msmam-offices-master") ||
          this.router.url.includes("msmam-party-master") ||
          this.router.url.includes("msmam-religion-master") ||
          this.router.url.includes("msmam-subcast-master") ||
          this.router.url.includes("msmam-calender-scheduling-master") ||
          this.router.url.includes("msmam-new-docketentry-transaction") ||
          this.router.url.includes(
            "msmam-prepare-meeting-agenda-transaction"
          ) ||
          this.router.url.includes("msmam-media-channel-master") ||
          this.router.url.includes("msmam-media-master") ||
          this.router.url.includes("msmam-journalist-master") ||
          this.router.url.includes("msmam-clerk-to-committee-master") ||
          this.router.url.includes("new-prastav-entry") ||
          this.router.url.includes("counsellor-proposal");
      }

      if (event instanceof NavigationEnd) {
        this.isEWMRoute = this.router.url.includes(
          "enterprise-workflow-management"
        );
      }

      if (event instanceof NavigationEnd) {
        this.isCWMRoute = this.router.url.includes("central-workshop");
      }
      // if (event instanceof NavigationEnd) {
      //   const CWMroute = ['cwmDashboard','vehicleMaster','cwm-driver','repair','item','cwm-maintenance','fuelRecord']
      //   // this.isCWMRoute = this.router.url.includes('cwmDashboard') ||
      //   //   this.router.url.includes('vehicleMaster') ||
      //   //   this.router.url.includes('cwm-driver') ||
      //   //   this.router.url.includes('repair') ||
      //   //   this.router.url.includes('item') ||
      //   //   this.router.url.includes('vendor') ||
      //   //   this.router.url.includes('cwm-maintenance') ||
      //   //   this.router.url.includes('fuelRecord')
      //     const currentRoute = this.router.url.split('/')[1]; // Extract the current route
      //    this.isCWMRoute = CWMroute.includes(currentRoute);
      // }

      if (event instanceof NavigationEnd) {
        this.isMMRoute = this.router.url.includes("material-management");
      }

      if (event instanceof NavigationEnd) {
        const hrmsRoutes = [
          "hrms-dashboard",
          "hrms-bank-branch",
          "hrms-bank",
          "hrms-review-rating",
          "hrms-dept-head",
          "hrms-dpc",
          "hrms-permission",
          "hrms-holiday",
          "hrms-leave-type",
          "hrms-roaster-setting",
          "hrms-salary-item",
          "hrms-shift",
          "hrms-week-off",
          "hrms-Loan",
          "hrms-pay-scale",
          "hrms-pay-band",
          "hrms-pay-scale-to-pay-band",
          "hrms-pay-commission",
          "hrms-increment",
          "hrms-group",
          "hrms-examination-details",
          "hrms-handicap-type",
          "leave-allocation",
          "departmental-posts",
          "leave-management",
          "life-certificate",
          "loan-application",
          "medical-reimbursement-dashboard",
          "medical-reimbursement-actions",
          "medical-reimbursement-scrutiny",
          "confidential-report",
          "recruitment-process",
          "pensioner-flow",
          "transfer-process",
          "task-management",
          "assign-task",
          "complete-task",
          "add-urgent-task",
          "pay-hike-7th-pay",
          "appendix-A",
          "appendix-B",
          "appendix-A-For-Caste",
          "appendix-B-For-Caste",
          "appendix-C-For-Caste",
          "appendix-C",
          "pay-hike-6th-pay",
          "shift-process",
          "hrms-employee",
          "employee-class",
          "hrms-role",
          "hrms-question-master",
          "getQuestions",
          "service-pension-proposal",
          "payroll",
          "fractionationTable",
          "family-pension",
          "doctors-pension",
          "leaving-process",
          "ref-book",
          "govt-sanctioned-posts",
          "vacant-posts",
          "loan-application-scrutiny",
          "vehcileLoan-scrutiny-actions",
          "computerLoan-application-scrutiny",
          "vehicleLoan-application-actions",
          "loan-application-actions",
          "computerLoan-application-actions",
          "hrms-pension-withdrawal",
          "hrms-pension-withdrawal-scrutiny",
          "hrms-cr-forms-question-master",
          "hrms-confidential-report",
          "suspended-employee",
          "seniority-list",
          "hrms-question-bank-master",
          "master-allowence",
          "master-deduction",
          "pay-band-new",
          "grade-pay",
          "grand-pay-level",
          "pay-matrix",
          "hrms-note-description",
        ];

        this.isHrmsRoute = hrmsRoutes.some((route) =>
          this.router.url.includes(route)
        );
      }

      if (event instanceof NavigationEnd) {
        const swmMasters = [
          "solid-waste-management",
          "solid-waste-management/location",
          "solid-waste-management/contractor",
          "solid-waste-management/location-type",
          "solid-waste-management/bin",
          "solid-waste-management/vehicle-parking",
          "solid-waste-management/contractor-vehicle",
          "solid-waste-management/waste-classification",
          "solid-waste-management/vehicle-type",
          "solid-waste-management/bin-allotment",
          "solid-waste-management/bin-allotment-actions",
          "solid-waste-management/bin-allotment-scrutiny",
          "solid-waste-management/route-create",
          "solid-waste-management/route-create-actions",
          "solid-waste-management/route-create-scrutiny",
        ];

        this.isSWMRoute = swmMasters.some((route) =>
          this.router.url.includes(route)
        );
      }

      if (event instanceof NavigationEnd) {
        const workManagementRoutes = ["work-management"];
        this.isWorkManagementRoute = workManagementRoutes.some((route) =>
          this.router.url.includes(route)
        );
      }

      if (event instanceof NavigationEnd) {
        const landEstateRoutes = ["land-Estate"];
        this.isLandEstateRoute = landEstateRoutes.some((route) =>
          this.router.url.includes(route)
        );
        const matchedRoute = landEstateRoutes.find((route) =>
          this.router.url.includes(route)
        );

        if (matchedRoute) {
          console.log("Matched Route land and estate:", matchedRoute);
        }
      }

      if (event instanceof NavigationEnd) {
        this.isGrivence = this.router.url.includes("grievance-management");
      }

      if (event instanceof NavigationEnd) {
        const commonRoutes = [
          "masterDashboard",
          "state",
          "district",
          "taluka",
          "village",
          "cfcCenter",
          "zoneMaster",
          "wardMaster",
          "sectorMaster",
          "nodeMaster",
          "designationMaster",
          "subDepartmentMaster",
          "departmentMaster",
          "areaMaster",
          "roleMaster",
          "userGroupMaster",
          "userMaster",
          "city",
          "caste",
          "sub-caste",
          "category-master",
          "location",
          "bank",
          "bank-branch",
          "financial-year-master",
          "electoral-ward",
          "common-document-master",
          "common-document-mapping",
          "common-node",
          "common-zone",
          "common-ward",
          "common-sector",
          "zone-ward-mapping",
          "ward-node-mapping",
          "node-sector-mapping",
          "despartment-designation-mapping",
          "bank-master",
          "core-module",
        ];
        const currentRoute = this.router.url.split("/")[1]; // Extract the current route
        this.isCommon = commonRoutes.includes(currentRoute);
      }

      if (event instanceof NavigationEnd) {
        const assetMRoutes = ["asset-management"];
        this.isAssetManagement = assetMRoutes.some((route) =>
          this.router.url.includes(route)
        );
      }
      if (event instanceof NavigationEnd) {
        this.isFinancialAccounting =
          this.router.url.includes("financial-accounting") ||
          this.router.url.includes("financial-accounting");
      }

      if (event instanceof NavigationEnd) {
        const wtMasters = [
          "/water-tax/water-tax-dashboard",
          "/water-tax/water-tax-master",
          "/water-tax/verificatin/dashboard",
          "/water-tax/Disconnection",
          "/water-tax/ConsumerSuit",
          "/water-tax/Reconnection",
          "/water-tax/TrnPlumberLicenseComponent",
          "/water-tax/TrnNoDueCertificateComponent",
          "/water-tax/VerifyPlumberLicenseComponent",
          "/water-tax/PlumberLicenseFormComponent",
          "/water-tax/new-connection-registration",
          "/water-tax/WaterConnectionComplaint",
          "/water-tax/FrmRefundDepositComponent",
          "/water-tax/DepartmentalDisconnection",
          "/water-tax/BillGenerationandReassessment",
          "/water-tax/BillCollectionComponent",
          "/water-tax/TrnBillGenerationTableComponent",

          "/water-tax/VerifyBillReassessmentComponent",
          "/water-tax/TrnBillReassessmentFormComponent",
          "/water-tax/TrnBillReassessmentComponent",

          "/water-tax/TrnGenerateReassessmentbillComponent",

          "/water-tax/TrnF1billGenerationComponent",
          "/water-tax/ViewReceiptComponent",
          "/water-tax/CollectPaymentComponent",
          "/water-tax/TrnF2billGenerationComponent",
          "/water-tax/TrnViewBillGenerationComponent",
          "/water-tax/TrnJaldharPaymentComponent",
          "/water-tax/TrnCMRBillGenerationComponent",
          "/water-tax/TrnDGBillGenerationComponent",
          "/water-tax/JaldarPayment",
          "/water-tax/TrnRefundDeposit",
          "/water-tax/ChangeConnectionSize",
          "/water-tax/ChangeConsumerType",
          "/water-tax/ChangeBillType",
          "/water-tax/NameTransferChangeofOwnership",

          "/water-tax/TrnConnectionComplaintComponent",
          "/water-tax/NoduesCertificateforPermitsandLicenses",
          "/water-tax/NewWaterConnection",
          "/water-tax/verify/connection",
          "/water-tax/VerifyNoDueCertificateComponent",
          "water-tax/TrnBillReassessmentCmrComponent",
          "/water-tax/VerifyDepositRefundComponent",
          "/water-tax/VerifyConnectionComplaintComponent",
          "/water-tax/VerifyDepartmentalDisconnectionComponent",
          "/water-tax/search-consumer-no",
          "/water-tax/master/MstMeterStatusComponent",
          "/water-tax/master/connectionSize",
          "/water-tax/master/applicantType",
          "/water-tax/master/connectionType",
          "/water-tax/master/gender",
          "/water-tax/master/landmark",
          "/water-tax/master/title",
          "/water-tax/master/usageType",
          "/water-tax/master/MstApplicationTypeComponent",
          "/water-tax/master/MstChargesComponent",
          "/water-tax/master/MstUnitMeasurementComponent",
          "/water-tax/master/MstConnectionSizeComponent",
          "/water-tax/master/MstConnectionTypeComponent",
          "/water-tax/master/MstConsumerTypeComponent",
          "/water-tax/master/MstConsumerBillTypeMappingComponent",
          "/water-tax/master/MstPenaltyMasterComponent",
          "/water-tax/master/MstRegularizationPenaltyComponent",
          "/water-tax/master/MstSecurityDepositComponent",
          "/water-tax/master/MstWaterSourceTypeComponent",
          "/water-tax/master/MstBulkSanctionsComponent",
          "/water-tax/master/MstProbableDaysOfApplicationProcessingComponent",
          "/water-tax/master/MstDisconnectionTypeComponent",
          "/water-tax/master/MstDemandAndPenaltyPeriodComponent",
          "/water-tax/master/MstConsumerGroupTypeComponent",
          "/water-tax/master/MstBillingCycleComponent",
          "/water-tax/master/MstReassessmentTypeComponent",
          "/water-tax/master/MstPropertyTypeComponent",
          "/water-tax/master/MstMeterTypeComponent",
          "/water-tax/master/MstPlumberMasterComponent",
          "/water-tax/master/MstComplaintTypeComponent",
          "/water-tax/master/MstRateMasterComponent",
          "/water-tax/master/MstBillTypeComponent",
          "/water-tax/master/MstGesComponent",
          "/water-tax/master/MstFeederlineComponent",
        ];

        this.isWTRoute = wtMasters.some((route) =>
          this.router.url.includes(route)
        );
      }
    });
  }

  getDepartmentName(dptId: any) {
    this.commService.getDepartment().subscribe((res: any) => {
      let dep = [];
      dep = res["departmentMasterList"];
      for (var i = 0; i < dep.length; i++) {
        if (dep[i]["id"] == dptId) {
          this.departmentName = dep[i]["department"];
        }
      }
    });
  }

  getDesignationName(desId: any) {
    this.commService.getDesignation().subscribe((res: any) => {
      let des = [];
      des = res["designationMasterList"];
      for (var i = 0; i < des.length; i++) {
        if (des[i]["id"] == desId) {
          this.designationName = des[i]["designation"];
        }
      }
    });
  }

  updateUserDetails(): void {
    if (this.dptId) {
      this.getDepartmentName(this.dptId);
    }
    if (this.desId) {
      this.getDesignationName(this.desId);
    }

    // console.log(this.departmentName)
    // console.log(this.designationName)
  }

  resetUserDetails(): void {
    this.departmentName = "";
    this.designationName = "";
  }

  logout() {
    this.resetUserDetails();
    localStorage.clear();
    this.authService.logout();
    this.router.navigate(["/"]);
  }

  toggleSideNav() {
    // Define the toggleSideNav function
    this.sidenav.toggle();
  }
  toggleUserMenu(): void {
    // This will be handled by Angular Material's mat-menu automatically
  }

  goToUserProfile(): void {
    const userId = localStorage.getItem("userId");
    if (userId) {
      this.router.navigate(["/user-profile"], {
        queryParams: { userId },
      });
    } else {
      console.error("User ID not found in localStorage");
    }
  }

  //Works Mangement Transactions
  WorksTrnNavLinks: Array<{ path: string; label: string; icon: string }> = [
    {
      path: "work-management/define-project-table",
      label: "Define Project",
      icon: "card_membership",
    },
    {
      path: "work-management/estimation-table",
      label: "Estimation",
      icon: "card_membership",
    },
    {
      path: "work-management/tender",
      label: "Tender",
      icon: "card_membership",
    },
    {
      path: "work-management/work-order-details",
      label: "Work Order Details",
      icon: "card_membership",
    },
    // {
    //   path: "work-management/budget-spill-amount",
    //   label: "Budget & Spill Adjustment",
    //   icon: "card_membership",
    // },
    {
      path: "work-management/measurement-book-table",
      label: "Measurement Book (Lumpsum Contracts)",
      icon: "card_membership",
    },
    {
      path: "work-management/b1-tender-table",
      label: "Measurement Book(B1 Tender Contracts)",
      icon: "card_membership",
    },
    {
      path: "work-management/add-invoice",
      label: "Invoice Generation",
      icon: "card_membership",
    },

    {
      path: "work-management/billing",
      label: "Billing",
      icon: "card_membership",
    },
    {
      path: "/work-management/time-escalation",
      icon: "card_membership",
      label: "Time Extension",
    },
    {
      path: "/work-management/rate-escalation",
      icon: "card_membership",
      label: "Rate Escalation",
    },
    {
      path: "work-management/excessive-quantity",
      label: "Excessive Quantity",
      icon: "card_membership",
    },
    {
      path: "work-management/earnest-money-deposit",
      label: "Earnest Money Deposit",
      icon: "card_membership",
    },
    {
      path: "work-management/bank-guarantee-expiry",
      label: "Bank Guarantee Expiry",
      icon: "card_membership",
    },
    {
      path: "work-management/car-policy-expiry",
      label: "Car Policy Expiry",
      icon: "card_membership",
    },
    {
      path: "work-management/bank-guarantee-forfeiting",
      label: "Bank Guarantee Forfeiting",
      icon: "card_membership",
    },
    {
      path: "work-management/work-order-cancelation",
      label: "Work Order Cancelation",
      icon: "card_membership",
    },
    {
      path: "work-management/project-handover",
      label: "Project Handover",
      icon: "card_membership",
    },
    {
      path: "work-management/add-road-digging",
      label: "Road Digging",
      icon: "card_membership",
    },
    {
      path: "work-management/re-appropriation-request",
      label: "Re-Appropriation Request",
      icon: "card_membership",
    },
    // {
    //   path: "work-management/all-project-details",
    //   label: "All Project Details",
    //   icon: "card_membership",
    // },
    {
      path: "work-management/payment-integration",
      label: "Payment Integration",
      icon: "assignment",
    },
  ];

  propertyTaxReports = [
    {
      label: "Abstract Report",
      icon: "move_up",
      link: "/property-tax/abstract-report",
    },
  ];

  propertyTaxMaster = [
    {
      label: "Amnesty Scheme",
      icon: "move_up",
      link: "/property-tax/amnesty-scheme",
    },
    {
      label: "Apartment",
      icon: "apartment",
      link: "/property-tax/property-apartment",
    },
    { label: "Amenities", icon: "foundation", link: "/property-tax/amenities" },
    {
      label: "Amenities Type",
      icon: "receipt_long",
      link: "/property-tax/amenities-type",
    },
    {
      label: "Bank",
      icon: "account_balance",
      link: "/property-tax/property-bank",
    },
    {
      label: "Billing Cycle",
      icon: "home_work",
      link: "/property-tax/billing-cycle",
    },
    { label: "Blocks", icon: "category", link: "/property-tax/tax-blocks" },
    {
      label: "Building",
      icon: "home_work",
      link: "/property-tax/property-building",
    },
    {
      label: "Cidco Rate",
      icon: "category",
      link: "/property-tax/cidco-rate",
    },
    {
      label: "Cidco Rate Type",
      icon: "category",
      link: "/property-tax/cidco-rate-type",
    },
    {
      label: "Collection Center",
      icon: "category",
      link: "/property-tax/collection-center",
    },
    // {
    //   label: "Delayed Payment",
    //   icon: "tune",
    //   link: "/property-tax/delayed-payment",
    // },
    {
      label: "Delayed Payment Charges",
      icon: "category",
      link: "/property-tax/delayed-payment-charges",
    },
    {
      label: "Dishonoured Check Reason",
      icon: "disabled_by_default",
      link: "/property-tax/Dishonoured-check-reason",
    },
    {
      label: "Discount Rate / Amount",
      icon: "category",
      link: "/property-tax/discount-rate-amount",
    },
    {
      label: "Discount Type",
      icon: "category",
      link: "/property-tax/discount-type",
    },
    {
      label: "Exemption Rate / Amount",
      icon: "category",
      link: "/property-tax/exemption-rate-amount",
    },
    {
      label: "Exemption Type",
      icon: "category",
      link: "/property-tax/exemption-type",
    },
    {
      label: "Financial Year",
      icon: "home_work",
      link: "/property-tax/financial-year",
    },
    { label: "Fees", icon: "receipt_long", link: "/property-tax/fees" },
    { label: "Floor", icon: "grid_on", link: "/property-tax/property-floor" },
    {
      label: "Grouping Tax",
      icon: "groups_3",
      link: "/property-tax/property-grouping-tax",
    },
    {
      label: "House Hold",
      icon: "groups_3",
      link: "/property-tax/house-hold",
    },
    {
      label: "Letting Rate",
      icon: "grid_on",
      link: "/property-tax/letting-rate-master",
    },
    {
      label: "Letting Rate Category",
      icon: "fact_check",
      link: "/property-tax/letting-rate-category",
    },
    {
      label: "Letting Rate Sub Category",
      icon: "analytics",
      link: "/property-tax/letting-rate-sub-category",
    },
    {
      label: "Letting Rate Matrix",
      icon: "category",
      link: "/property-tax/letting-rate-matrix",
    },
    {
      label: "Location",
      icon: "location_on",
      link: "/property-tax/location-master",
    },
    {
      label: "Modification Reason",
      icon: "tune",
      link: "/property-tax/modification-reason",
    },
    { label: "Occupancy", icon: "category", link: "/property-tax/occupancy" },
    { label: "Ownership", icon: "category", link: "/property-tax/ownership" },
    {
      label: "Payment Type",
      icon: "account_balance",
      link: "/property-tax/payment-type",
    },
    { label: "Plot", icon: "vrpano", link: "/property-tax/plot-master" },
    {
      label: "Plot Type",
      icon: "scatter_plot",
      link: "/property-tax/plot-type",
    },
    {
      label: "Property Status",
      icon: "category",
      link: "/property-tax/property-status",
    },
    {
      label: "Property Sub Type",
      icon: "unfold_more",
      link: "/property-tax/property-sub-type",
    },
    {
      label: "Property Type",
      icon: "category",
      link: "/property-tax/property-type",
    },
    {
      label: "Ready Reckner Rate",
      icon: "supervisor_account",
      link: "/property-tax/ready-reckner-rate",
    },
    {
      label: "Reassessment",
      icon: "analytics",
      link: "/property-tax/property-reassessment",
    },
    {
      label: "Rectification Reason",
      icon: "category",
      link: "/property-tax/rectification-reason",
    },
    {
      label: "Rateble Value Slab",
      icon: "category",
      link: "/property-tax/rateble-value-slab",
    },
    {
      label: "Rebate Rate / Amount",
      icon: "category",
      link: "/property-tax/rebate-rate-amount",
    },
    {
      label: "Rebate Type",
      icon: "category",
      link: "/property-tax/rebate-type",
    },
    {
      label: "Roof Type",
      icon: "roofing",
      link: "/property-tax/property-rooftype",
    },
    { label: "Society", icon: "category", link: "/property-tax/tax-society" },
    {
      label: "Special Cases",
      icon: "category",
      link: "/property-tax/special-cases-dashboard",
    },
    { label: "Structure", icon: "foundation", link: "/property-tax/structure" },
    {
      label: "Sub Discount Type",
      icon: "category",
      link: "/property-tax/subdiscount-type",
    },
    {
      label: "Sub Exemption Type",
      icon: "category",
      link: "/property-tax/subexemption-type",
    },
    {
      label: "Sub Rebate Type",
      icon: "category",
      link: "/property-tax/sub-rebate-type",
    },
    {
      label: "Sub Usage Type",
      icon: "category",
      link: "/property-tax/property-sub-usage-type",
    },
    {
      label: "Tax",
      icon: "home_work",
      link: "/property-tax/tax",
    },
    {
      label: "Tax Component",
      icon: "home_work",
      link: "/property-tax/tax-component",
    },
    {
      label: "Tax Policy",
      icon: "analytics",
      link: "/property-tax/tax-policy",
    },
    {
      label: "Tax Rate",
      icon: "currency_rupee",
      link: "/property-tax/property-taxrate",
    },
    {
      label: "Tax Rate Policy",
      icon: "supervisor_account",
      link: "/property-tax/property-taxmaster",
    },
    {
      label: "Transfer Type",
      icon: "move_up",
      link: "/property-tax/transfer-type",
    },
    {
      label: "Usage Type",
      icon: "category",
      link: "/property-tax/property-usage-type",
    },
  ];

  propertyTaxTransaction = [
    {
      label: "New Registration",
      icon: "category",
      link: "/property-tax/new-property-registration-dashboard",
    },
    {
      label: "Transfer",
      icon: "category",
      link: "/property-tax/transfer-dashboard",
    },
    {
      label: "Cheque Dishonour",
      icon: "category",
      link: "/property-tax/cheque-dishonoured-dashboard",
    },
    {
      label: "Hearing On Objection",
      icon: "category",
      link: "/property-tax/hearing-on-objection-dashboard",
    },
    {
      label: "Bill Generation",
      icon: "category",
      link: "/property-tax/bill-generation-dashboard",
    },
    {
      label: "Property Services",
      icon: "category",
      link: "/property-tax/property-services-dashboard",
    },
    {
      label: "Property Attachment and Auction",
      icon: "category",
      link: "/property-tax/property-attachment-and-auction",
    },
  ];

  // RTS Masters
  rtsServices = [
    {
      label: "Document",
      icon: "category",
      link: "/online-rts/document-table",
    },
    // {
    //   label: "Stage Content Template",
    //   icon: "category",
    //   link: "/online-rts/stage-template",
    // },
    {
      label: "Stages",
      icon: "category",
      link: "/online-rts/stages",
    },
    {
      label: "Workflow",
      icon: "category",
      link: "/online-rts/workflow",
    },
    {
      label: "Workflow User Allocation",
      icon: "category",
      link: "/online-rts/workflow-user-allocation",
    },
    {
      label: "Service Level Escalation",
      icon: "category",
      link: "/online-rts/service-level-escalation",
    },
    // {
    //   label: "Hearing",
    //   icon: "category",
    //   link: "/online-rts/appeal-hearing",
    // },
    {
      label: "Ground",
      icon: "category",
      link: "/online-rts/ground-table",
    },
    {
      label: "Storage Business Type",
      icon: "category",
      link: "/online-rts/storage-business-type",
    },
    {
      label: "Factory Business Type",
      icon: "category",
      link: "/online-rts/factory-business-type",
    },
    {
      label: "Trade Business Type",
      icon: "category",
      link: "/online-rts/trade-business-type",
    },
  ];

  surveyServices = [
    {
      label: "Public Toilet",
      icon: "category",
      link: "/survey-database/public-toilet",
    },
    {
      label: "Community Toilet",
      icon: "category",
      link: "/survey-database/community-toilet",
    },
    {
      label: "Standalone urinals",
      icon: "category",
      link: "/survey-database/standalone-urinals",
    },
  ];

  // Disaster
  disasterMenuItems = [
    {
      link: "/disaster-management/disaster-dashboard",
      icon: "dashboard",
      label: "Dashboard",
    },
    {
      link: "/disaster-management/disaster-incident-type",
      icon: "whatshot",
      label: "Incident Type",
    },
    {
      link: "/disaster-management/disaster-incident-subtype",
      icon: "how_to_reg",
      label: "Incident Subtype",
    },
    {
      link: "/disaster-management/disaster-incident-level",
      icon: "bar_chart",
      label: "Incident Level",
    },
    {
      link: "/disaster-management/disaster-source-incident",
      icon: "place",
      label: "Source",
    },
    {
      link: "/disaster-management/disaster-victim-treatment",
      icon: "healing",
      label: "Victim Treatment",
    },
    {
      link: "/disaster-management/disaster-fire-station",
      icon: "local_fire_department",
      label: "Fire Station",
    },
    {
      link: "/disaster-management/disaster-hospital",
      icon: "local_hospital",
      label: "Hospital",
    },
    {
      link: "/disaster-management/disaster-Nature",
      icon: "eco",
      label: "Nature Of Call",
    },
    {
      link: "/disaster-management/disaster-resource",
      icon: "eco",
      label: "Resource",
    },
    {
      link: "/disaster-management/disaster-communicate-agency",
      icon: "eco",
      label: "Disaster Communicate Agency",
    },
  ];
  disasterTransactionMenuItems = [
    {
      link: "/disaster-management/disaster-report-complaint",
      icon: "card_membership",
      label: "Disaster report complaint",
    },
    {
      link: "/disaster-management/disaster-morbe-dam",
      icon: "card_membership",
      label: "Morbe Dam",
    },
    // { link: '/disaster-management/call-not-related', icon: 'card_membership', label: 'Call Not Related' },
    // { link: '/disaster-management/disaster-resource-allcation', icon: 'card_membership', label: 'Resource Allocation' },
  ];

  commonRoutesMenuItems = [
    {
      link: "/core-module/areaMaster",
      icon: "accessible",
      label: "Area",
    },
    {
      link: "/core-module/bank",
      icon: "accessible",
      label: "Bank",
    },
    {
      link: "/core-module/bank-master",
      icon: "accessible",
      label: "Bank Account",
    },
    {
      link: "/core-module/bank-branch",
      icon: "accessible",
      label: "Bank Branch",
    },
    {
      link: "/core-module/cfcCenter",
      icon: "accessible",
      label: "CFC Center",
    },
    {
      link: "/core-module/caste",
      icon: "accessible",
      label: "Caste",
    },
    {
      link: "/core-module/category-master",
      icon: "accessible",
      label: "Category",
    },
    {
      link: "/core-module/city",
      icon: "accessible",
      label: "City",
    },
    {
      link: "/core-module/common-document-master",
      icon: "accessible",
      label: "Document",
    },
    {
      link: "/core-module/common-document-mapping",
      icon: "accessible",
      label: "Document Mapping",
    },
    {
      link: "/core-module/departmentMaster",
      icon: "accessible",
      label: "Department",
    },

    {
      link: "/core-module/designationMaster",
      icon: "accessible",
      label: "Designation",
    },
    {
      link: "/core-module/department-designation-mapping",
      icon: "accessible",
      label: "Department-Designation",
    },
    {
      link: "/core-module/district",
      icon: "accessible",
      label: "District",
    },
    {
      link: "/core-module/electoral-ward",
      icon: "accessible",
      label: "Electoral Ward",
    },
    {
      link: "/core-module/financial-year-master",
      icon: "accessible",
      label: "Financial Year",
    },
    {
      link: "/core-module/location",
      icon: "accessible",
      label: "Location",
    },
    // {
    //   link: "/core-module/nodeMaster",
    //   icon: "accessible",
    //   label: "Node",
    // },
    {
      link: "/core-module/menu-master",
      icon: "accessible",
      label: "Menu",
    },
    {
      link: "/core-module/module-master",
      icon: "accessible",
      label: "Module",
    },
    {
      link: "/core-module/common-node",
      icon: "accessible",
      label: "Node Master",
    },
    {
      link: "/core-module/node-sector-mapping",
      icon: "accessible",
      label: "Node-Sector",
    },

    {
      link: "/core-module/roleMaster",
      icon: "accessible",
      label: "Role",
    },
    {
      link: "/core-module/role-menu-mapping",
      icon: "accessible",
      label: "Role - Menu Mapping",
    },
    {
      link: "/core-module/department-module-mapping",
      icon: "accessible",
      label: "Department - Module Mapping",
    },
    // {
    //   link: "/core-module/sectorMaster",
    //   icon: "accessible",
    //   label: "Sector",
    // },
    {
      link: "/core-module/common-sector",
      icon: "accessible",
      label: "Sector Master",
    },
    {
      link: "/core-module/state",
      icon: "accessible",
      label: "State",
    },
    {
      link: "/core-module/sub-caste",
      icon: "accessible",
      label: "Sub Caste",
    },
    {
      link: "/core-module/subDepartmentMaster",
      icon: "accessible",
      label: "Sub Department",
    },
    {
      link: "/core-module/taluka",
      icon: "accessible",
      label: "Taluka",
    },
    {
      link: "/core-module/userGroupMaster",
      icon: "accessible",
      label: "User Group",
    },
    {
      link: "/core-module/userMaster",
      icon: "accessible",
      label: "User",
    },
    {
      link: "/core-module/user-role-mapping",
      icon: "accessible",
      label: "User - Role Mapping",
    },
    // {
    //   link: "/core-module/wardMaster",
    //   icon: "accessible",
    //   label: "Ward",
    // },
    {
      link: "/core-module/village",
      icon: "accessible",
      label: "Village",
    },
    {
      link: "/core-module/common-ward",
      icon: "accessible",
      label: "Ward Master",
    },
    {
      link: "/core-module/ward-node-mapping",
      icon: "accessible",
      label: "Ward-Node",
    },
    // {
    //   link: "/core-module/zoneMaster",
    //   icon: "accessible",
    //   label: "Zone",
    // },
    {
      link: "/core-module/common-zone",
      icon: "accessible",
      label: "Zone Master",
    },
    {
      link: "/core-module/zone-ward-mapping",
      icon: "accessible",
      label: "Zone-Ward",
    },
  ];
  // HRMS
  hrmsMenuItems = [
    {
      link: "/hrms-module/appendix-A",
      icon: "accessible",
      label: "Appendix A",
    },
    {
      link: "/hrms-module/appendix-A-For-Caste",
      icon: "accessible",
      label: "Appendix A for Caste",
    },
    {
      link: "/hrms-module/appendix-B",
      icon: "accessible",
      label: "Appendix B",
    },

    {
      link: "/hrms-module/appendix-B-For-Caste",
      icon: "accessible",
      label: "Appendix B for Caste",
    },
    {
      link: "/hrms-module/appendix-C",
      icon: "accessible",
      label: "Appendix C",
    },

    // {
    //   link: "/hrms-module/appendix-C-For-Caste",
    //   icon: "accessible",
    //   label: "Appendix C for Caste",
    // },
    {
      link: "/hrms-module/hrms-dashboard",
      icon: "dashboard",
      label: "Dashboard",
    },
    {
      link: "/hrms-module/departmental-posts",
      icon: "domain",
      label: "Departmental posts",
    },
    {
      link: "/hrms-module/hrms-dept-head",
      icon: "supervisor_account",
      label: "Department Head",
    },
    { link: "/hrms-module/hrms-dpc", icon: "category", label: "DPC" },

    {
      link: "/hrms-module/hrms-employee",
      icon: "account_balance",
      label: "Employee",
    },
    {
      link: "/hrms-module/employee-class",
      icon: "account_balance",
      label: "Employee Class",
    },
    {
      link: "/hrms-module/hrms-examination-details",
      icon: "assignment",
      label: "Examination Details",
    },
    {
      link: "/hrms-module/fractionationTable",
      icon: "accessible",
      label: "Fractionation Table",
    },
    { link: "/hrms-module/hrms-group", icon: "group", label: "Group" },

    {
      link: "/hrms-module/hrms-handicap-type",
      icon: "accessible",
      label: "Handicap Type",
    },

    { link: "/hrms-module/hrms-holiday", icon: "dashboard", label: "Holiday" },
    {
      link: "/hrms-module/hrms-increment",
      icon: "trending_up",
      label: "Increment",
    },
    {
      link: "/hrms-module/leave-allocation",
      icon: "open_in_new",
      label: "Leave Allocation",
    },

    {
      link: "/hrms-module/hrms-leave-type",
      icon: "home_work",
      label: "Leave Type",
    },
    { link: "/hrms-module/hrms-Loan", icon: "money", label: "Loan" },
    {
      link: "/hrms-module/hrms-pay-band",
      icon: "attach_money",
      label: "Pay Bound",
    },
    {
      link: "/hrms-module/pay-band-new",
      icon: "attach_money",
      label: "Pay Band",
    },
    {
      link: "/hrms-module/grade-pay",
      icon: "attach_money",
      label: "Grade Pay",
    },
    {
      link: "/hrms-module/grand-pay-level",
      icon: "attach_money",
      label: "Grade Pay Level",
    },
    {
      link: "/hrms-module/pay-matrix",
      icon: "attach_money",
      label: "Pay Matrix",
    },
    {
      link: "/hrms-module/hrms-pay-commission",
      icon: "star",
      label: "Pay Commission",
    },
    {
      link: "/hrms-module/hrms-pay-scale",
      icon: "attach_money",
      label: "Pay Scale",
    },

    {
      link: "/hrms-module/hrms-pay-scale-to-pay-band",
      icon: "attach_money",
      label: "Pay Scale to Pay Bound",
    },
    {
      link: "/hrms-module/hrms-permission",
      icon: "accessible",
      label: "Permission",
    },
    {
      link: "/hrms-module/hrms-question-bank-master",
      icon: "accessible",
      label: "Question Master",
    },

    {
      link: "/hrms-module/ref-book",
      icon: "accessible",
      label: "Ref Book",
    },
    {
      link: "/hrms-module/hrms-review-rating",
      icon: "star",
      label: "Review Rating",
    },
    { link: "/hrms-module/hrms-role", icon: "accessible", label: "Role" },

    {
      link: "/hrms-module/hrms-roaster-setting",
      icon: "settings",
      label: "Roster Setting",
    },

    {
      link: "/hrms-module/hrms-salary-item",
      icon: "monetization_on",
      label: "Salary Item",
    },
    { link: "/hrms-module/hrms-shift", icon: "schedule", label: "Shift" },

    {
      link: "/hrms-module/hrms-week-off",
      icon: "dashboard",
      label: "Week Off",
    },

    {
      link: "/hrms-module/hrms-cr-forms-question-master",
      icon: "accessible",
      label: "Questions",
    },
    {
      link: "/hrms-module/suspended-employee",
      icon: "domain",
      label: "Suspended Employee",
    },
    {
      link: "/hrms-module/mst-seniority-list",
      icon: "domain",
      label: "Seniority List",
    },
    {
      link: "/hrms-module/master-allowence",
      icon: "domain",
      label: "Allowance Master",
    },
    {
      link: "/hrms-module/master-deduction",
      icon: "domain",
      label: "Deduction Master",
    },
    {
      link: "/hrms-module/add-hrms-note-description",
      icon: "domain",
      label: "Note Description",
    },
  ];
  // Solid Waste Management
  swmMenuItems = [
    { link: "/solid-waste-management", icon: "dashboard", label: "Dashboard" },
    { link: "/solid-waste-management/bin", icon: "delete", label: "Bin" },
    {
      link: "/solid-waste-management/location",
      icon: "location_on",
      label: "Location",
    },
    {
      link: "/solid-waste-management/contractor",
      icon: "work",
      label: "Contractor",
    },
    {
      link: "/solid-waste-management/location-type",
      icon: "location_city",
      label: "Location Type",
    },
    {
      link: "/solid-waste-management/vehicle-parking",
      icon: "local_parking",
      label: "Vehicle Parking",
    },
    {
      link: "/solid-waste-management/contractor-vehicle",
      icon: "directions_car",
      label: "Contractor Vehicle",
    },
    {
      link: "/solid-waste-management/waste-classification",
      icon: "delete_sweep",
      label: "Waste Classification",
    },
    {
      link: "/solid-waste-management/vehicle-type",
      icon: "directions_bus",
      label: "Vehicle Type",
    },
  ];

  // MSMAM
  msmamMenuItems = [
    { link: "/msmam-dashboard", icon: "dashboard", label: "Dashboard" },
    { link: "/msmam-caste-master", icon: "people", label: "Caste" },
    {
      link: "/msmam-committee-establishment-master",
      icon: "account_balance",
      label: "Committee Establishment",
    },
    { link: "/msmam-committee-master", icon: "groups", label: "Committee" },
    {
      link: "/msmam-committee-members-master",
      icon: "group",
      label: "Committee Members",
    },
    { link: "/msmam-corporator-master", icon: "person", label: "Corporator" },
    {
      link: "/msmam-electoral-ward-master",
      icon: "location_city",
      label: "Electoral Ward",
    },
    {
      link: "/msmam-meeting-type-master",
      icon: "location_city",
      label: "Meeting Type",
    },
    {
      link: "/msmam-identifiactionproof-master",
      icon: "credit_card",
      label: "Identification Proof",
    },
    {
      link: "/msmam-meeting-schedule-master",
      icon: "schedule",
      label: "Meeting Schedule",
    },
    { link: "/msmam-offices-master", icon: "business", label: "Office" },
    { link: "/msmam-party-master", icon: "emoji_events", label: "Party" },
    { link: "/msmam-religion-master", icon: "spa", label: "Religion" },
    {
      link: "/msmam-subcast-master",
      icon: "supervised_user_circle",
      label: "Sub Cast",
    },
    {
      link: "/msmam-calender-scheduling-master",
      icon: "event",
      label: "Calendar",
    },
    // {
    //   link: "/msmam-new-docketentry-transaction",
    //   icon: "description",
    //   label: "Docket Entry",
    // },
    // {
    //   link: "/msmam-prepare-meeting-agenda-transaction",
    //   icon: "list_alt",
    //   label: "Meeting Agenda",
    // },
    {
      link: "/msmam-media-channel-master",
      icon: "list_alt",
      label: "Media Channel",
    },
    {
      link: "/msmam-media-master",
      icon: "list_alt",
      label: "Media",
    },
    {
      link: "/msmam-journalist-master",
      icon: "person",
      label: "Journalist",
    },
    {
      link: "/msmam-clerk-to-committee-master",
      icon: "person",
      label: "Clerk to Committee",
    },
  ];

  msmamTransaction = [
    {
      link: "/msmam/new-prastav-entry",
      icon: "dashboard",
      label: "New Prastav Entry",
    },
    {
      link: "/msmam/counsellor-proposal",
      icon: "dashboard",
      label: "Counsellor Proposal",
    },
  ];

  swmTransaction = [
    {
      link: "/solid-waste-management/bin-allotment",
      icon: "dashboard",
      label: "Bin Allotment",
    },
    {
      link: "/solid-waste-management/route-create",
      icon: "dashboard",
      label: "Route Create",
    },
    {
      link: "/solid-waste-management/vehicle-shift-schedule",
      icon: "dashboard",
      label: "Vehicle Shift Schedule",
    },
  ];
  mmMenuItems = [
    { link: "/material-management", icon: "dashboard", label: "Dashboard" },
    {
      link: "/material-management/mm-supplier",
      icon: "group_work",
      label: "Supplier",
    },
    {
      link: "/material-management/mm-unit-name",
      icon: "group_work",
      label: "Unit",
    },
    {
      link: "/material-management/pack-master",
      icon: "group_work",
      label: "Pack ",
    },
    {
      link: "/material-management/mm-material-category",
      icon: "category",
      label: "Material Category ",
    },
    {
      link: "/material-management/mm-sub-category",
      icon: "group_work",
      label: "Material Sub Category ",
    },

    {
      link: "/material-management/mm-material-name",
      icon: "group_work",
      label: "Material",
    },
    {
      link: "/material-management/mm-manufacture",
      icon: "build",
      label: "Manufacture",
    },

    {
      link: "/material-management/mm-store",
      icon: "group_work",
      label: "Store",
    },

    // { link: "/material-management/mm-item", icon: "inventory", label: "Items" },
    // {
    //   link: "/material-management/mm-material-name",
    //   icon: "inventory",
    //   label: "Material",
    // },
    {
      link: "/material-management/challan",
      icon: "group_work",
      label: "Challan ",
    },
    {
      link: "/material-management/center-type",
      icon: "group_work",
      label: "Center Type",
    },
    {
      link: "/material-management/mm-Center",
      icon: "group_work",
      label: "Center",
    },

    {
      link: "/material-management/Ward",
      icon: "group_work",
      label: "Ward",
    },
  ];

  mmTransactionNavLinks = [
    {
      path: "/material-management/demand-request",
      icon: "dashboard",
      label: "Demand Request",
    },
    {
      path: "/material-management/purchase-order",
      icon: "dashboard",
      label: "Purchase Order",
    },
    {
      path: "/material-management/work-order-without-tender",
      icon: "dashboard",
      label: "Work Order Without Tender",
    },
    {
      path: "/material-management/spot-purchase",
      icon: "dashboard",
      label: "Spot Purchase",
    },
    {
      path: "/material-management/received-material",
      icon: "dashboard",
      label: "Received Material",
    },
    {
      path: "/material-management/minimum-reorder",
      icon: "dashboard",
      label: "Minimum Reorder",
    },
    {
      path: "/material-management/expired-material-disposal",
      icon: "dashboard",
      label: "Expired Material Disposal",
    },
    {
      path: "/material-management/material-distribution",
      icon: "dashboard",
      label: "Material Distribution",
    },
    {
      path: "/material-management/return-material-to-supplier",
      icon: "dashboard",
      label: "Return Material To Supplier",
    },
  ];

  ewmMenuItems = [
    {
      link: "/enterprise-workflow-management",
      icon: "dashboard",
      label: "Dashboard",
    },
    // {
    //   link: "/enterprise-workflow-management/ewm-category",
    //   icon: "inventory",
    //   label: "Category",
    // },
    // {
    //   link: "/enterprise-workflow-management/ewm-widgets",
    //   icon: "build",
    //   label: "Widgets",
    // },
    {
      link: "/enterprise-workflow-management/ewm-modules",
      icon: "category",
      label: "Modules",
    },
    {
      link: "/enterprise-workflow-management/ewm-workflow",
      icon: "group_work",
      label: "Workflow",
    },
    {
      link: "/enterprise-workflow-management/ewm-workflowstages",
      icon: "group_work",
      label: "Workflow Stages",
    },
    {
      link: "/enterprise-workflow-management/workflow-management",
      icon: "group_work",
      label: "Manage Workflow",
    },
  ];

  cmsMenuItems = [
    {
      link: "/content-management/cms-dashboard",
      icon: "dashboard",
      label: "Dashboard",
    },
    {
      link: "/content-management/cms-album",
      icon: "inventory",
      label: "Album",
    },
    {
      link: "/content-management/cms-category",
      icon: "description",
      label: "Category",
    },
    // {
    //   link: "/content-management/cms",
    //   icon: "content",
    //   label: "Content"
    // },

    {
      link: "/content-management/faq-category",
      icon: "description",
      label: "FAQ Category",
    },
    {
      link: "/content-management/faq-sub-category",
      icon: "article",
      label: "FAQ Sub Category",
    },

    // {
    //   link: "/content-management/cms-menu",
    //   icon: "group_work",
    //   label: "Menu",
    // },

    {
      link: "/content-management/news-category",
      icon: "group_work",
      label: "News Category",
    },
    {
      link: "/content-management/news-letter",
      icon: "description",
      label: "News Letter",
    },
    {
      link: "/content-management/news-sub-category",
      icon: "group_work",
      label: "News Sub Category",
    },
    {
      link: "/content-management/opinion-poll-category",
      icon: "group_work",
      label: "Opinion Poll Category",
    },
    {
      link: "/content-management/opinion-poll-sub-category",
      icon: "group_work",
      label: "Opinion Poll Sub Category",
    },

    {
      link: "/content-management/cms-widget",
      icon: "description",
      label: "Widget",
    },
    {
      link: "/content-management/cms-project",
      icon: "description",
      label: "Project",
    },
    {
      link: "/content-management/cms-event",
      icon: "description",
      label: "Event",
    },
    {
      link: "/content-management/cms-video",
      icon: "description",
      label: "video",
    },
    {
      link: "/content-management/general-information",
      icon: "description",
      label: "General Information",
    },

    // {
    //   link: "/content-management/feedback",
    //   icon: "description",
    //   label: "Feedback",
    // },
  ];

  cmstransactionMenuItems = [
    {
      link: "/content-management/banners",
      icon: "group_work",
      label: "Banners",
    },
    {
      link: "/content-management/circulars",
      icon: "group_work",
      label: "Circulars",
    },
    {
      link: "/content-management/cms-gallery",
      icon: "photo_library",
      label: "Gallery",
    },
    {
      link: "/content-management/cms-page",
      icon: "description",
      label: "Page",
    },
    {
      link: "/content-management/cms-post",
      icon: "article",
      label: "Post",
    },
    {
      link: "/content-management/department-info",
      icon: "group_work",
      label: "Department Information",
    },
    {
      link: "/content-management/faq",
      icon: "photo_library",
      label: "FAQ",
    },
    {
      link: "/content-management/news",
      icon: "group_work",
      label: "News",
    },
    {
      link: "/content-management/opinion-poll",
      icon: "group_work",
      label: "Opinion Poll",
    },
    {
      link: "/content-management/ms-rti",
      icon: "group_work",
      label: "RTI",
    },
    {
      link: "/content-management/tender",
      icon: "group_work",
      label: "Tender",
    },
    {
      link: "/content-management/online-info",
      icon: "description",
      label: "Online Information",
    },
    {
      link: "/content-management/mst-aadhaar",
      icon: "description",
      label: "Adhaar",
    },
    {
      link: "/content-management/agenda-resolution",
      icon: "description",
      label: "Agenda Resolution",
    },
    {
      link: "/content-management/other-content",
      icon: "description",
      label: "Other Content",
    },
    {
      link: "/content-management/chair-person",
      icon: "description",
      label: "Chair Person",
    },
    {
      link: "/content-management/website",
      icon: "group_work",
      label: "Templates",
    },
    {
      link: "/content-management/menu-label",
      icon: "group_work",
      label: "Menu",
    },
  ];

  workManagementMenuItems = [
    {
      link: "/work-management/additional-charges",
      icon: "store",
      label: "Additional Charges",
    },
    { link: "/work-management/bill-type", icon: "receipt", label: "Bill Type" },
    {
      link: "/work-management/deduction",
      icon: "money",
      label: "Deduction Category",
    },
    {
      link: "/work-management/deduction-group",
      icon: "group",
      label: "Deduction Group",
    },
    {
      link: "/work-management/dsr-grouping",
      icon: "group_work",
      label: "DSR Grouping",
    },
    {
      link: "/work-management/dsr-item",
      icon: "list_alt",
      label: "DSR Item",
    },
    {
      link: "/work-management/emd-master",
      icon: "list_alt",
      label: "Earnest Money Deposit",
    },
    {
      link: "/work-management/security-deposit",
      icon: "list_alt",
      label: "Security Deposit",
    },
    {
      link: "/work-management/additional-security-deposit",
      icon: "list_alt",
      label: "Additional Security Deposit",
    },
    {
      link: "/work-management/tender-price",
      icon: "list_alt",
      label: "Tender Price",
    },
    {
      link: "/work-management/tender-cost",
      icon: "list_alt",
      label: "Tender Cost",
    },
    {
      link: "/work-management/dsr-item-template",
      icon: "list_alt",
      label: "DSR Item Template",
    },
    {
      link: "/work-management/dsr-reference",
      icon: "description",
      label: "DSR Reference Document",
    },
    {
      link: "/work-management/dsr-sub-grouping",
      icon: "layers",
      label: "DSR Sub Grouping",
    },
    {
      link: "/work-management/estimation-type",
      icon: "leaderboard",
      label: "Estimation Type",
    },
    {
      link: "/work-management/identified-by",
      icon: "person_pin",
      label: "Identified By",
    },
    {
      link: "/work-management/penalty-category",
      icon: "store",
      label: "Penalty Category",
    },
    {
      link: "/work-management/penelty-charges",
      icon: "store",
      label: "Penalty Charges",
    },
    {
      link: "/work-management/project-type",
      icon: "construction",
      label: "Project Type",
    },
    {
      link: "/work-management/road-digging",
      icon: "store",
      label: "Road Digging",
    },
    { link: "/work-management/shape", icon: "star", label: "Shape" },
    {
      link: "/work-management/sub-work-type",
      icon: "assignment",
      label: "Sub Work Type",
    },
    { link: "/work-management/vendor", icon: "store", label: "Vendor" },
    {
      link: "/work-management/vendor-grade",
      icon: "store",
      label: "Vendor Grade",
    },
    {
      link: "/work-management/unit-of-measure",
      icon: "straighten",
      label: "Unit of Measure",
    },
    {
      link: "/work-management/work-related",
      icon: "work_outline",
      label: "Work Related To",
    },
    {
      link: "/work-management/work-type",
      icon: "assignment",
      label: "Work Type",
    },
  ];

  landEstateMenuItems = [
    { link: "/land-Estate", icon: "dashboard", label: "Land Estate Dashboard" },
    { link: "/land-Estate/checklist", icon: "checklist", label: "Checklist" },
    {
      link: "/land-Estate/checklist-item",
      icon: "list_alt",
      label: "Checklist Item",
    },
    {
      link: "/land-Estate/contractor",
      icon: "list_alt",
      label: "Contractor",
    },
    {
      link: "/land-Estate/lease-holder",
      icon: "person",
      label: "Lease Holder",
    },
    {
      link: "/land-Estate/plot-number",
      icon: "dashboard",
      label: "Plot Number",
    },
    { link: "/land-Estate/plot-type", icon: "category", label: "Plot Type" },
    { link: "/land-Estate/usage-type", icon: "settings", label: "Usage Type" },
    { link: "/land-Estate/valuator", icon: "person_pin", label: "Valuator" },
    { link: "/land-Estate/land", icon: "terrain", label: "Land" },
    {
      link: "/land-Estate/disposal-type",
      icon: "delete",
      label: "Disposal Type",
    },
    { link: "/land-Estate/purpose-type", icon: "flag", label: "Purpose Type" },
    { link: "/land-Estate/agency", icon: "business", label: "Agency" },
    {
      link: "/land-Estate/structure",
      icon: "architecture",
      label: "Structure",
    },
  ];

  landEstateTransactions = [
    // {
    //   link: "/land-Estate/title-intention",
    //   icon: "architecture",
    //   label: "Title Intention",
    // },
    {
      link: "/land-Estate/plot-details-dashboard",
      icon: "architecture",
      label: "Plot Details",
    },
    {
      link: "/land-Estate/land-acquisition-proposal-dashboard",
      icon: "architecture",
      label: "Land Proposal",
    },
    {
      link: "/land-Estate/land-record-creation-dashboard",
      icon: "architecture",
      label: "Land Record Creation",
    },
    {
      link: "/land-Estate/estate-allotment-agreement-dashboard",
      icon: "architecture",
      label: "Estate Allotment & Agreement",
    },
    {
      link: "/land-Estate/plot-land-development",
      icon: "architecture",
      label: "Plot & Land Development",
    },
  ];

  cwmMenuItems = [
    {
      link: "/central-workshop/cwmDashboard",
      icon: "dashboard",
      label: "Dashboard",
    },
    {
      link: "/central-workshop/vehicleMaster",
      icon: "local_shipping",
      label: "Vehicle",
    },
    {
      link: "/central-workshop/manufacture",
      icon: "assignment_ind",
      label: "Manufacture",
    },
    { link: "/central-workshop/model", icon: "assignment_ind", label: "Model" },
    {
      link: "/central-workshop/cwm-driver",
      icon: "person_apron",
      label: "Driver",
    },
    {
      link: "/central-workshop/repair",
      icon: "construction",
      label: "Repair Category",
    },
    {
      link: "/central-workshop/repair-reason",
      icon: "construction",
      label: "Repair reason",
    },
    {
      link: "/central-workshop/item",
      icon: "category",
      label: "Item Category",
    },
    { link: "/central-workshop/items", icon: "category", label: "Items" },
    {
      link: "/central-workshop/vendor",
      icon: "assignment_ind",
      label: "Vendor",
    },
    {
      link: "/central-workshop/agency",
      icon: "assignment_ind",
      label: "Agency",
    },
    {
      link: "/central-workshop/driver-type",
      icon: "assignment_ind",
      label: "Driver Type",
    },
    {
      link: "/central-workshop/vehicle-type",
      icon: "assignment_ind",
      label: "Vehicle Type",
    },
  ];
  cwmtransactionMenuItems = [
    {
      link: "/central-workshop/vehicle-assigning",
      icon: "card_membership",
      label: "Vehicle assigning",
    },
    {
      link: "/central-workshop/vehicle-aging",
      icon: "card_membership",
      label: "Vehicle aging",
    },
    {
      link: "/central-workshop/cwm-maintenance",
      icon: "card_membership",
      label: "Maintenance Request",
    },
    {
      link: "/central-workshop/fuelRecord",
      icon: "card_membership",
      label: "Fuel",
    },
    {
      link: "/central-workshop/maintenance-estimation",
      icon: "card_membership",
      label: "Vehicle Maintenance & Work Order",
    },
    // { link: '/central-workshop/maintenance-quotation',icon:'card_membership',label:'Maintenance quotation'},
    {
      link: "/central-workshop/vendor-payment",
      icon: "card_membership",
      label: "Vendor Payment",
    },
    {
      link: "/central-workshop/travel-allowance",
      icon: "card_membership",
      label: "Travel Allowance",
    },
    {
      link: "/central-workshop/vehicle-rent-or-driver-pay",
      icon: "card_membership",
      label: "Vehicle Rent",
    },
    {
      link: "/central-workshop/PUC-Insurance-renewal",
      icon: "card_membership",
      label: "PUC/Insurance renewal",
    },
  ];

  grivanceMangementMenuItems = [
    {
      link: "grievance-management/keywords",
      icon: "dashboard",
      label: "Keywords",
    },
    // {
    //   link: "grievance-management/apartment",
    //   icon: "dashboard",
    //   label: "Apartment",
    // },
    {
      link: "grievance-management/application-type",
      icon: "local_shipping",
      label: "Application-type",
    },
    {
      link: "grievance-management/channel",
      icon: "person_apron",
      label: "Channel",
    },
    {
      link: "grievance-management/grievance-flow-escalation",
      icon: "construction",
      label: "Grievance WorkFlow escalation",
    },
    {
      link: "grievance-management/Complaint-type",
      icon: "category",
      label: "Complaint type",
    },
    {
      link: "grievance-management/Complaint-sub-type",
      icon: "assignment_ind",
      label: "Complaint  sub type",
    },
  ];

  grievanceManagementTransactionMenuItems = [
    {
      link: "grievance-management/register",
      icon: "dashboard",
      label: "New Grievance",
    },
    {
      link: "grievance-management/my-grievances",
      icon: "dashboard",
      label: "My Grievances",
    },

    {
      link: "grievance-management/handling",
      icon: "dashboard",
      label: "All Grievances",
    },

    {
      link: "grievance-management/forwarding",
      icon: "dashboard",
      label: "Forwarded Grievances",
    },
  ];

  commonRoutes = [
    { link: "areaMaster", icon: "place", label: "Area" },
    { link: "bank", icon: "device_hub", label: "Bank" },
    { link: "bank-master", icon: "device_hub", label: "Bank Account" },
    { link: "bank-branch", icon: "work", label: "Bank Branch" },
    { link: "cfcCenter", icon: "business", label: "CFC Center" },
    { link: "caste", icon: "people", label: "Caste" },
    { link: "category-master", icon: "category", label: "Category" },
    { link: "city", icon: "location_city", label: "City" },
    {
      link: "common-document-master",
      icon: "account_balance",
      label: "Document",
    },
    {
      link: "common-document-mapping",
      icon: "account_balance",
      label: "Document Mapping",
    },
    { link: "departmentMaster", icon: "folder_open", label: "Department" },
    { link: "designationMaster", icon: "work", label: "Designation" },
    {
      link: "department-designation-mapping",
      icon: "device_hub",
      label: "Department-Designation",
    },

    { link: "district", icon: "location_on", label: "District" },
    {
      link: "electoral-ward",
      icon: "account_balance",
      label: "Electoral Ward",
    },
    {
      link: "financial-year-master",
      icon: "event_note",
      label: "Financial Year",
    },
    { link: "location", icon: "place", label: "Location" },
    { link: "masterDashboard", icon: "dashboard", label: "Master Dashboard" },
    // { link: "nodeMaster", icon: "device_hub", label: "Node" },
    { link: "common-node", icon: "device_hub", label: "Node Master" },
    { link: "node-sector-mapping", icon: "device_hub", label: "Node-Sector" },

    { link: "roleMaster", icon: "security", label: "Role" },
    // { link: "sectorMaster", icon: "layers_clear", label: "Sector" },
    { link: "common-sector", icon: "device_hub", label: "Sector Master" },
    { link: "state", icon: "location_city", label: "State" },
    { link: "sub-caste", icon: "supervised_user_circle", label: "Sub Caste" },
    { link: "subDepartmentMaster", icon: "folder", label: "Sub Department" },
    { link: "taluka", icon: "map", label: "Taluka" },
    { link: "village", icon: "map", label: "Village" },
    { link: "userGroupMaster", icon: "group", label: "User Group" },
    { link: "userMaster", icon: "person", label: "User" },
    // { link: "wardMaster", icon: "layers", label: "Ward" },
    { link: "common-ward", icon: "device_hub", label: "Ward Master" },
    {
      link: "ward-node-mapping",
      icon: "location_searching",
      label: "Ward-Node",
    },

    // { link: "zoneMaster", icon: "location_searching", label: "Zone" },
    { link: "common-zone", icon: "device_hub", label: "Zone Master" },
    { link: "zone-ward-mapping", icon: "device_hub", label: "Zone-Ward" },
  ];

  assetManagementMenuItems = [
    {
      link: "/asset-management/asset-dashboard",
      icon: "your_icon_name",
      label: "Asset Dashboard",
    },

    {
      link: "/asset-management/asset-type-master",
      icon: "your_icon_name",
      label: "Asset Type",
    },

    {
      link: "/asset-management/asset-sub-type-master",
      icon: "your_icon_name",
      label: "Asset Sub Type",
    },
    {
      link: "/asset-management/asset-category",
      icon: "your_icon_name",
      label: "Asset Category",
    },
    {
      link: "/asset-management/asset-sub-category",
      icon: "your_icon_name",
      label: "Asset Sub Category",
    },

    {
      link: "/asset-management/asset-evaluator",
      icon: "your_icon_name",
      label: "Asset Valuator",
    },
    {
      link: "/asset-management/asset-eqpBreakdown",
      icon: "your_icon_name",
      label: " Equipment Breakdown ",
    },
    {
      link: "/asset-management/asset-equipment-part",
      icon: "your_icon_name",
      label: " Equipment Part ",
    },
    {
      link: "/asset-management/asset-checklist",
      icon: "your_icon_name",
      label: "Asset Maintenance checklist",
    },
    {
      link: "/asset-management/asset-checklist-Item",
      icon: "your_icon_name",
      label: "Asset checklist Item",
    },
    {
      link: "/asset-management/asset-status",
      icon: "your_icon_name",
      label: "Asset status",
    },
  ];

  assetManagementTransactionMenuItems = [
    {
      link: "/asset-management/asset-record",
      icon: "card_membership",
      label: "Create-Update Asset Record",
    },
    {
      link: "/asset-management/asset-valuation",
      icon: "card_membership",
      label: "Asset Request Valuation",
    },
    {
      link: "/asset-management/e-breakdown-maintainance",
      icon: "card_membership",
      label: "Equipment Breakdown Maintainance",
    },
    {
      link: "/asset-management/asset-insurance",
      icon: "card_membership",
      label: "Asset Insurance",
    },
    {
      link: "/asset-management/insurance-incident",
      icon: "card_membership",
      label: "Insurance Incident",
    },
  ];

  financialMenuItems = [
    { label: "Dashboard", icon: "dashboard", link: "financial-accounting" },
    {
      label: "Main Group",
      icon: "category",
      link: "financial-accounting/fa-maingroup",
    },
    {
      label: "Main Subgroup",
      icon: "layers",
      link: "financial-accounting/fa-mainsubgroup",
    },
    {
      label: "Account/Ledger Head",
      icon: "account_balance",
      link: "financial-accounting/fa-account",
    },
    {
      label: "Account Particulars",
      icon: "account_balance",
      link: "financial-accounting/fa-account-particulars",
    },
    {
      label: "Budget Head Nature",
      icon: "account_balance",
      link: "financial-accounting/fa-budgettype",
    },
    {
      label: "Budget Annexure",
      icon: "library_add",
      link: "financial-accounting/fa-budgetannexure",
    },
    {
      label: "Budget Service",
      icon: "category",
      link: "financial-accounting/fa-budgetcategory",
    },
    {
      label: "Budget Head",
      icon: "category",
      link: "financial-accounting/fa-budget-head",
    },
    // { label: "TDS", icon: "description", link: "financial-accounting/fa-tds" },
    {
      label: "Opening Balance",
      icon: "account_balance",
      link: "financial-accounting/fa-openingbalance",
    },
    {
      label: "Scheme",
      icon: "developer_board",
      link: "financial-accounting/fa-scheme",
    },
    {
      label: "Work Advance",
      icon: "developer_board",
      link: "financial-accounting/work-advance",
    },
    {
      label: "Fund",
      icon: "local_atm",
      link: "financial-accounting/fund",
    },
    {
      label: "Cheque Book",
      icon: "library_books",
      link: "financial-accounting/fa-chequebook",
    },
    {
      label: "Particulars",
      icon: "category",
      link: "financial-accounting/fa-particulers",
    },
    { label: "Book", icon: "book", link: "financial-accounting/fa-book" },
    {
      label: "Book Department Mapping",
      icon: "map",
      link: "financial-accounting/fa-bookdepartmentmapping",
    },
    {
      label: "Book Sub Department Mapping",
      icon: "map",
      link: "financial-accounting/fa-booksubdepartmentmapping",
    },
    {
      label: "Grant Type",
      icon: "map",
      link: "financial-accounting/fa-grantType",
    },
    {
      label: "Grant Name",
      icon: "local_atm",
      link: "financial-accounting/grant-name",
    },
    {
      label: "Deposit Type",
      icon: "account_balance_wallet",
      link: "financial-accounting/fa-depositetype",
    },

    // {
    //   label: "Fund Type",
    //   icon: "local_atm",
    //   link: "financial-accounting/fa-fund",
    // },
    {
      label: "Voucher Nature",
      icon: "bookmark",
      link: "financial-accounting/fa-voucher",
    },
    {
      label: "GST",
      icon: "description",
      link: "financial-accounting/fa-gst",
    },

    {
      label: "Challan Type",
      icon: "group",
      link: "financial-accounting/fa-challan-type",
    },
    {
      label: "Deduction Group",
      icon: "group",
      link: "financial-accounting/fa-deductiongroup",
    },
    {
      label: "Deduction Category",
      icon: "group",
      link: "financial-accounting/fa-deduction-cat",
    },

    {
      label: "Deduction Type",
      icon: "group",
      link: "financial-accounting/fa-deduction-type",
    },
    // {
    //   label: "Deduction",
    //   icon: "group",
    //   link: "financial-accounting/fa-deduction",
    // },

    //
    {
      label: "Balance Side",
      icon: "account_balance_wallet",
      link: "financial-accounting/fa-balanceside",
    },
    // {
    //   label: "Major Head",
    //   icon: "category",
    //   link: "financial-accounting/fa-majorhead",
    // },
    // {
    //   label: "Minor Head",
    //   icon: "subtitles",
    //   link: "financial-accounting/fa-minorhead",
    // },

    // {
    //   label: "Budget",
    //   icon: "account_balance",
    //   link: "financial-accounting/fa-budget",
    // },
    // {
    //   label: "Budget Main Group",
    //   icon: "category",
    //   link: "financial-accounting/fa-budgetmaingroup",
    // },
    {
      label: "Drawing And Disbursing",
      icon: "category",
      link: "financial-accounting/fa-drawing-disbursing",
    },
    {
      label: "Type Of Variable",
      icon: "category",
      link: "financial-accounting/fa-type-of-variable",
    },
    {
      label: "GPF Interest rate",
      icon: "category",
      link: "financial-accounting/fa-GPF-interest-rate",
    },
    {
      label: "Currency",
      icon: "category",
      link: "financial-accounting/currency-master",
    },
    {
      label: "Advance Payment Restrictions",
      icon: "category",
      link: "financial-accounting/advance-payment-restrictions",
    },
    {
      label: "Note Description",
      icon: "category",

      link: "financial-accounting/note-description",
    },
  ];

  financialTransactionMenuItems = [
    {
      link: "financial-accounting/investment",
      icon: "card_membership",
      label: "Investment",
      subMenuItems: [
        {
          link: "financial-accounting/reinvestment",
          icon: "card_membership",
          label: "Reinvestment",
        },
      ],
    },

    {
      link: "financial-accounting/budget-transaction-table",
      icon: "card_membership",
      label: "Budget",
      subMenuItems: [
        {
          link: "financial-accounting/budget-allocation",
          icon: "card_membership",
          label: "Budget Allocation",
        },
        {
          link: "financial-accounting/budget-transaction-table",
          icon: "card_membership",
          label: "Budget Entry",
        },
        {
          link: "financial-accounting/fa-trn-budget-head",
          icon: "card_membership",
          label: "Budget Head",
        },
        {
          link: "financial-accounting/fa-trn-re-appropriation",
          icon: "card_membership",
          label: "Budget Re appropriation",
        },
      ],
    },

    {
      link: "financial-accounting/fa-grant",
      icon: "card_membership",
      label: "Grants",
      subMenuItems: [
        {
          link: "financial-accounting/fa-grant-refund",
          icon: "card_membership",
          label: "Grants Refund",
        },
      ],
    },
    {
      link: "financial-accounting/bill-process",
      icon: "card_membership",
      label: "Bill Process",
      subMenuItems: [
        {
          link: "financial-accounting/payment-Payables-checklist",
          icon: "card_membership",
          label: "Payable Checklist",
        },
        {
          link: "financial-accounting/salary-payment-voucher/new",
          icon: "card_membership",
          label: "Salary Payment Voucher",
        },
        {
          link: "financial-accounting/fa-add-bill-process",
          icon: "card_membership",
          label: "Bill Process",
        },
        {
          link: "financial-accounting/cashier-payment-entry",
          icon: "card_membership",
          label: "Cashier Payment Entry",
        },

        {
          link: "financial-accounting/payment-details",
          icon: "card_membership",
          label: "Payment Details",
        },
        {
          link: "financial-accounting/stop-payment",
          icon: "card_membership",
          label: "Stop Payment",
        },
      ],
    },
    {
      // link: "financial-accounting/bill-process",
      icon: "card_membership",
      label: "Payment Process",
      subMenuItems: [
        {
          link: "financial-accounting/fa-bank-transfer-table",
          icon: "card_membership",
          label: "Bank To Bank Transfer",
        },
        {
          link: "financial-accounting/deposit-refund-table",
          icon: "card_membership",
          label: "Deposit Refund",
        },
        {
          link: "financial-accounting/deposit-forfeit-table",
          icon: "card_membership",
          label: "Deposit Forfeit",
        },
        {
          link: "financial-accounting/fa-cash-withdrawal",
          icon: "card_membership",
          label: "Cash withdrawal",
        },
        {
          link: "financial-accounting/admin-expense-payment-table",
          icon: "card_membership",
          label: "Admin Expense Payment",
        },
        {
          link: "financial-accounting/cheque-lost-cancellation-table",
          icon: "card_membership",
          label: "Cheque Lost / Cancellation",
        },
      ],
    },
    // {
    //   link: "financial-accounting/deposit-refund-table",
    //   icon: "card_membership",
    //   label: "Deposit Refund",
    // },
    // {
    //   link: "financial-accounting/deduction-details",
    //   icon: "card_membership",
    //   label: "Deduction Details",
    // },
    {
      // link: "financial-accounting/bill-process",
      icon: "card_membership",
      label: "Loan and Advance",
      subMenuItems: [
        {
          link: "financial-accounting/loan-payment-voucher-table",
          icon: "card_membership",
          label: "Employee Loan Payment",
        },
        {
          link: "financial-accounting/advance-payment-table",
          icon: "card_membership",
          label: "Advance Payment",
        },
        {
          link: "financial-accounting/advance-payment-settlement-table",
          icon: "card_membership",
          label: "Advance Payment Settlement",
        },
      ],
    },

    // {
    //   link: "financial-accounting/admin-expense-table",
    //   icon: "card_membership",
    //   label: "Admin Expense",
    // },
    // {
    //   link: "financial-accounting/bank-book-table",
    //   icon: "card_membership",
    //   label: "Bank Book",
    // },
    // {
    //   link: "financial-accounting/jv-book-table",
    //   icon: "card_membership",
    //   label: "JV Book",
    // },
    // {
    //   link: "financial-accounting/multiple-jv-book-table",
    //   icon: "card_membership",
    //   label: "Multiple JV Book",
    // },
    // {
    //   link: "financial-accounting/notice-cum-tax-invoice-table",
    //   icon: "card_membership",
    //   label: "Notice cum tax invoice",
    // },
    {
      // link: "financial-accounting/payment-receipt",
      icon: "card_membership",
      label: "Payment Receipt",
      subMenuItems: [
        {
          link: "financial-accounting/notice-cum-tax-invoice-table",
          icon: "card_membership",
          label: "Notice cum tax invoice",
        },
        {
          link: "financial-accounting/payment-receipt",
          icon: "card_membership",
          label: "Receipt",
        },
        {
          link: "financial-accounting/receipt-cash-deposit-entry",
          icon: "card_membership",
          label: "Cash Deposit",
        },
      ],
    },
    {
      link: "financial-accounting/tax-challan",
      icon: "card_membership",
      label: "Tax Challan",
    },

    // {
    //   link: "financial-accounting/fa-payment-voucher-bank-transfer-table",
    //   icon: "card_membership",
    //   label: "Payment Voucher Bank To Bank Transfer",
    // },

    {
      link: "financial-accounting/remittance-table",
      icon: "card_membership",
      label: "Remittance",
    },
  ];

  // Water Tax Master
  wtMenuItems = [
    // {
    //   link: "/water-tax/master/MstApplicationTypeComponent",
    //   icon: "dashboard",
    //   label: "Application Type",
    // },
    {
      link: "/water-tax/master/MstChargesComponent",
      icon: "dashboard",
      label: "Charges",
    },
    {
      link: "/water-tax/master/MstConnectionSizeComponent",
      icon: "dashboard",
      label: "Connection Size",
    },
    {
      link: "/water-tax/master/MstConnectionTypeComponent",
      icon: "dashboard",
      label: "Connection Type",
    },
    {
      link: "/water-tax/master/MstConsumerTypeComponent",
      icon: "dashboard",
      label: "Consumer Type",
    },
    {
      link: "/water-tax/master/MstConsumerBillTypeMappingComponent",
      icon: "dashboard",
      label: "Consumer & Bill Type",
    },
    {
      link: "/water-tax/master/MstPenaltyMasterComponent",
      icon: "dashboard",
      label: "Penalty",
    },
    {
      link: "/water-tax/master/MstRegularizationPenaltyComponent",
      icon: "dashboard",
      label: "Regularization Penalty",
    },
    {
      link: "/water-tax/master/MstSecurityDepositComponent",
      icon: "dashboard",
      label: "Security Deposit",
    },
    {
      link: "/water-tax/master/MstWaterSourceTypeComponent",
      icon: "dashboard",
      label: "Water Source Type",
    },

    {
      link: "/water-tax/master/MstBulkSanctionsComponent",
      icon: "dashboard",
      label: "Bulk Sanctions",
    },
    {
      link: "/water-tax/master/MstProbableDaysOfApplicationProcessingComponent",
      icon: "dashboard",
      label: "ProbableDays Of Application Processing",
    },
    {
      link: "/water-tax/master/MstDisconnectionTypeComponent",
      icon: "dashboard",
      label: "Disconnection Type",
    },
    {
      link: "/water-tax/master/MstDemandAndPenaltyPeriodComponent",
      icon: "dashboard",
      label: "Demand And Penalty Period",
    },
    {
      link: "/water-tax/master/MstConsumerGroupTypeComponent",
      icon: "dashboard",
      label: "Consumer Group Type",
    },
    {
      link: "/water-tax/master/MstBillingCycleComponent",
      icon: "dashboard",
      label: "Billing Cycle",
    },
    {
      link: "/water-tax/master/MstReassessmentTypeComponent",
      icon: "dashboard",
      label: "Reassessment Type",
    },
    {
      link: "/water-tax/master/MstPropertyTypeComponent",
      icon: "dashboard",
      label: "Property Type",
    },
    {
      link: "/water-tax/master/MstMeterTypeComponent",
      icon: "dashboard",
      label: "Meter Type",
    },
    {
      link: "/water-tax/master/MstPlumberMasterComponent",
      icon: "dashboard",
      label: "Plumber",
    },
    {
      link: "/water-tax/master/MstMeterStatusComponent",
      icon: "dashboard",
      label: "Meter Status",
    },
    {
      link: "/water-tax/master/MstComplaintTypeComponent",
      icon: "dashboard",
      label: "Complaint Type",
    },
    {
      link: "/water-tax/master/MstRateMasterComponent",
      icon: "dashboard",
      label: "Rate",
    },
    {
      link: "/water-tax/master/MstBillTypeComponent",
      icon: "dashboard",
      label: "Bill Type",
    },
    {
      link: "/water-tax/master/MstGesComponent",
      icon: "dashboard",
      label: "GES Master",
    },
    {
      link: "/water-tax/master/MstFeederlineComponent",
      icon: "dashboard",
      label: "Feederline",
    },
    {
      link: "/water-tax/master/MstUnitMeasurementComponent",
      icon: "dashboard",
      label: "Unit Measurement",
    },

    // {link: "/water-tax/master/landmark",icon: "dashboard",label: "Landmark",},

    // {
    //   link: "/water-tax/master/connectionSize",
    //   icon: "location_on",
    //   label: "Connection Size",
    // },
    // {
    //   link: "/water-tax/master/connectionType",
    //   icon: "work",
    //   label: "Connection Type",
    // },
    // {
    //   link: "/water-tax/master/usageType",
    //   icon: "location_city",
    //   label: "Usage Type",
    // },
    // {
    //   link: "/water-tax/master/applicantType",
    //   icon: "delete",
    //   label: "Applicant Type",
    // },
    // {
    //   link: "/water-tax/master/gender",
    //   icon: "local_parking",
    //   label: "Gender",
    // },
    // { link: "/water-tax/master/title", icon: "directions_car", label: "Title" },
  ];

  // Water Tax Transaction
  wtTrMenuItems = [
    {
      link: "/water-tax/ConsumerSuit",
      icon: "dashboard",
      label: "Consumer Data",
    },
    {
      link: "/water-tax/verificatin/dashboard",
      icon: "dashboard",
      label: "New Water Connection",
    },
    {
      link: "/water-tax/search-consumer-no/disconnection",
      icon: "dashboard",
      label: "Disconnection",
    },
    // {
    //   link: "/water-tax/search-consumer-no/refunDeposit",
    //   icon: "dashboard",
    //   label: "Disconnection",
    // },
    {
      link: "/water-tax/search-consumer-no/reconnection",
      icon: "dashboard",
      label: "Reconnection",
    },
    {
      link: "/water-tax/DepartmentalDisconnection",
      icon: "dashboard",
      label: "Departmental Disconnection",
    },
    {
      link: "/water-tax/TrnRefundDeposit",
      icon: "dashboard",
      label: "Refund Deposit",
    },
    {
      link: "/water-tax/TrnF1billGenerationComponent",
      icon: "dashboard",
      label: "Bill Generation",
    },
    {
      link: "/water-tax/BillCollectionComponent",
      icon: "dashboard",
      label: "Bill Collection",
    },
    {
      link: "/water-tax/TrnBillReassessmentComponent",
      icon: "dashboard",
      label: "Bill Reassessment",
    },
    {
      link: "/water-tax/search-consumer-no/change-connection-size",
      icon: "dashboard",
      label: "Change Connection Size",
    },
    {
      link: "/water-tax/search-consumer-no/change-consumer-type",
      icon: "dashboard",
      label: "Change Consumer Type",
    },
    {
      link: "/water-tax/search-consumer-no/change-bill-type",
      icon: "dashboard",
      label: "Change Bill Type",
    },
    {
      link: "/water-tax/search-consumer-no/change-ownership-or-name-transfer",
      icon: "dashboard",
      label: "Name Transfer/Change of Ownership",
    },
    {
      link: "/water-tax/TrnJaldharPaymentComponent",
      // link: "/water-tax/water-tax-dashboard",
      icon: "dashboard",
      label: "Jaldar Payment",
    },
    {
      link: "/water-tax/TrnConnectionComplaintComponent",
      icon: "dashboard",
      label: "Water Connection Complaint",
    },
    {
      link: "/water-tax/TrnPlumberLicenseComponent",
      icon: "dashboard",
      label: "Plumber License",
    },
    {
      link: "/water-tax/TrnNoDueCertificateComponent",
      icon: "dashboard",
      label: "No dues Certificate for Permits and Licenses",
    },
    // {
    //   link: "/water-tax/NewWaterConnection",
    //   icon: "dashboard",
    //   label: "New Water Connection",
    // },
  ];

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
