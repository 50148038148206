
  <div class="relative p-4  w-full  text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5" >
    <button type="button"  (click)="onNoClick()" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="deleteModal">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        <span class="sr-only">Close modal</span>
    </button>
    <h1 class="m_title">{{action}} confirmation</h1>
    <p class="mb-4 text-gray-500 text-md dark:text-gray-300">{{text}}?</p>
    
    <div class="relative z-0 w-full mb-5 pt-4" *ngIf="component == 'Tax scrutiny' && action == 'Approval and site schedule'">
          
        <div class="mb-6 p-3">
            <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">1. Site visit date</label>
           
           <input type="date" name="siteVisitDate"
               id="buildingPermissionDate"
               class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
               placeholder="">

          

           
       </div> 
       
       <div class="mb-6 p-3">
           <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">2. Site visit start time</label>
          
        
              <input type="time" name="siteVisitStartTime"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required />

         

          
      </div> 

      <div class="mb-6 p-3">
       <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">3. Site visit end time</label>
      
    
          <input type="time"  name="siteVisitEndTime"
          class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required />

     

      
       </div> 
    </div>
    <div class="relative z-0 w-full mb-5 pt-4">
          
        <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Remark</label>
        <textarea 
        id="remark"
        name="remark"
        required
        [(ngModel)]="remark"
         rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
         placeholder="Write your remark here..."></textarea>
    </div>
    <div class="relative z-0 w-full mb-5 pt-4" *ngIf="loadActions">
          
        <div >

            <label class="text-sm font-medium text-gray-700" for="payScaleOfRetirement">Upload Attachment</label>
            <input (change)="uploadDocUpload($event)" class="block w-full file:bg-gray-800 file:text-white text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file">
            <p *ngIf="showDocUpload == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                Photo uploaded successfully 
            </p>
          </div>       
       
    </div>
    <div class="flex justify-center items-center space-x-4">
        <button   (click)="onNoClick()" data-modal-toggle="deleteModal" type="button" class="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
            No, cancel
        </button>
        <button [disabled]="remark == ''"  (click)="onConfirm()" type="submit" class="disabled:bg-gray-300 py-2 px-3 text-sm font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900">
            Yes, I'm sure
        </button>
    </div>
</div>
