
 <div class="p-4">
    <h2 class="text-2xl font-semibold">Application Details</h2>
    <form class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <ng-container>
            <div class="mb-4" *ngIf="applicationData">
                <label  class="text-md font-semibold max-w-full">Vehicle Registration Number 
                    :</label>
                <br>
                <ng-container >
                    <label for="" class="text-center text-gray-500 overflow-x-auto max-w-full">{{ applicationData[0].vehicleRegistrationNo }}</label>
                </ng-container>
              
            </div>
        </ng-container>
    </form>
</div>

<div class="p-4  w-full ">
   
    <h1 class="m_title">{{action}}</h1>

        <!-- on quote upload  -->

    <div class="w-full " *ngIf="appStatus == 'APPROVED'">
        <form  [formGroup]="form" (ngSubmit)="saveScrutinyDetailsForQuotes()">

            <div >
            
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                         <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">1. Vendor Name</label>
                        <mat-form-field appearance="outline" class="w-full">
                            <mat-select placeholder="Select Vendor" formControlName="vendorId1" (selectionChange)="selectVendor1($event.value)">
                                <mat-option *ngFor="let option of vendors" [value]="option.id">
                                   {{option.vendorName}}
                                </mat-option>
                                <mat-option *ngIf="vendors.length === 0" [value]="'noOptions'">No options available</mat-option>
                
                            </mat-select>
                        </mat-form-field>
                        <p *ngIf="vendorSelected1 == true" class="text-sm font-light text-red-500 dark:text-gray-400">
                            Vendor already selected
                            </p>

                        <label for="message" class=" block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Competitive Quote</label>
                        <button   (click)="file.click()" [disabled]="showCompetitiveQuote1" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                        Quote upload 
                        </button>
                        <input #file type="file" (change)="uploadQuote1($event)" name="my_file" id="my-file" accept="application/pdf,image/*">
                        <p *ngIf="showCompetitiveQuote1 == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                            Quote uploaded successfully 
                        </p>

                        <div class="mb-6 pt-3">
                            <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amount</label>
                            <input type="number" step="0.01" placeholder="&#8377;" formControlName="ammount1" (keypress)="keyPress($event)" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        </div>
                    </div>   
                   
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">2. Vendor Name</label>
                       <mat-form-field appearance="outline" class="w-full">
                           <mat-select placeholder="Select Vendor" formControlName="vendorId2" (selectionChange)="selectVendor2($event.value)">
                               <mat-option *ngFor="let option of vendors" [value]="option.id"  >
                                  {{option.vendorName}}
                               </mat-option>
                               <mat-option *ngIf="vendors.length === 0" [value]="'noOptions'">No options available</mat-option>
               
                           </mat-select>
                       </mat-form-field>
                       <p *ngIf="vendorSelected2 == true" class="text-sm font-light text-red-500 dark:text-gray-400">
                        Vendor already selected
                        </p>
                       <label for="message" class="block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Competitive Quote</label>
                       <button   (click)="file2.click()" [disabled]="showCompetitiveQuote2" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                       Quote upload 
                       </button>
                       <input #file2 type="file" (change)="uploadQuote2($event)" name="my_file" id="my-file" accept="application/pdf,image/*">
                       <p *ngIf="showCompetitiveQuote2 == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                        Quote uploaded successfully 
                    </p>

                    <div class="mb-6 pt-3">
                        <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amount</label>
                        <input type="number" step="0.01" placeholder="&#8377;" formControlName="ammount2" (keypress)="keyPress($event)" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    </div>
                    </div>   
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">3. Vendor Name</label>
                       <mat-form-field appearance="outline" class="w-full">
                           <mat-select placeholder="Select Vendor" formControlName="vendorId3"  (selectionChange)="selectVendor3($event.value)">
                               <mat-option *ngFor="let option of vendors" [value]="option.id">
                                  {{option.vendorName}}
                               </mat-option>
                               <mat-option *ngIf="vendors.length === 0" [value]="'noOptions'">No options available</mat-option>
               
                           </mat-select>
                           
                       </mat-form-field>
                      
                       <p *ngIf="vendorSelected3 == true" class="text-sm font-light text-red-500 dark:text-gray-400">
                        Vendor already selected
                        </p>
                       <label for="message" class="block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Competitive Quote</label>
                       <button   (click)="file3.click()" type="button" [disabled]="showCompetitiveQuote3" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                       Quote upload 
                       </button>
                       <input #file3 type="file" (change)="uploadQuote3($event)" name="my_file" id="my-file" accept="application/pdf,image/*">
                       <p *ngIf="showCompetitiveQuote3 == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                        Quote uploaded successfully 
                    </p>

                    <div class="mb-6 pt-3">
                        <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amount</label>
                        <input type="number" step="0.01" placeholder="&#8377;" formControlName="ammount3" (keypress)="keyPress($event)" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    </div>
                    </div>   
                </li>
                
            </ul>
            </div>

      

       

           

            <div class="float-right">
                <button
                id="button"
                type="submit"
                [disabled]="!form.valid"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Submit
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="cancel()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Cancel
            </button>
            </div>

        </form>
        
    </div>

    <div class="w-full " *ngIf="appStatus == 'QUOTATION_SUBMITTED'">
        <form  [formGroup]="formWorkUpload" (ngSubmit)="saveScrutinyDetailsForWorkUpload()">

            <div >
            
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                      
                        <input id="horizontal-list-radio-millitary" (change)="handleQuoteChange('Quote1')" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">1. Vendor Name - {{applicationData[0].vendorName1}}</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Quote uploaded</div>
                          </div>
                          <br>
                          <br>

                          <label>Amount : {{applicationData[0].ammount1}}</label>

                    </div>   

                   
                   
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        <input id="horizontal-list-radio-millitary" (change)="handleQuoteChange('Quote2')" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">2. Vendor Name - {{applicationData[0].vendorName2}}</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Quote uploaded</div>
                          </div>
                          <br>
                          <br>

                          <label>Amount : {{applicationData[0].ammount2}}</label>

                    </div>   
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        <input id="horizontal-list-radio-millitary" (change)="handleQuoteChange('Quote3')" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">3. Vendor Name - {{applicationData[0].vendorName3}}</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Quote uploaded</div>
                          </div>
                          <br>
                          <br>

                          <label>Amount : {{applicationData[0].ammount3}}</label>

                    </div>   
                </li>
                
            </ul>
            <div *ngIf="showWorkOrder == true">
                <label for="message" class="block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Work Order</label>
             <button   (click)="file.click()" [disabled]="workOrderUploaded" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
            Order upload 
             </button>
             <input #file type="file" (change)="uploadWorkOrder($event)" name="my_file2" id="my-file2" accept="application/pdf,image/*">
             <p *ngIf="workOrderUploaded == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                Order uploaded successfully 
            </p>
            </div>
            </div>



            <div class="float-right">
                <button
                id="button"
                type="submit"
                [disabled]="!formWorkUpload.valid"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Submit
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="cancel()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Cancel
            </button>
            </div>

        </form>
        
    </div>

    <div class="w-full " *ngIf="appStatus == 'WORKORDER_SUBMITTED'">
        <form  [formGroup]="formInvoiceUpload" (ngSubmit)="saveScrutinyDetailsForInvoiceUpload()">

            <div >
            <label>Selected Quote Details</label>
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                      
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">1. Vendor Name - {{applicationData[0].selectedvendorName}}</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Quote uploaded</div>
                          </div>
                          <br>
                          <br>


                          <label>Amount : {{applicationData[0].selectedammount}}</label>
                        
                    </div>   

                   
                   
                </li>
                <!-- <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">2. Vendor Name - {{applicationData[0].vendorName2}}</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Quote uploaded</div>
                          </div>
                          <br>
                          <br>

                          <label>Amount : {{applicationData[0].ammount2}}</label>

                    
                    </div>   
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">3. Vendor Name - {{applicationData[0].vendorName3}}</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Quote uploaded</div>
                          </div>
                          <br>
                          <br>

                          <label>Amount : {{applicationData[0].ammount3}}</label>

                    </div>   
                </li> -->
                
            </ul>
            <div >
                <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Work Order</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Work order uploaded</div>
                          </div>
            </div>

            <div *ngIf="showInvoiceUpload == true">
                <label for="message" class="block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Invoice</label>
             <button   (click)="file.click()" [disabled]="invoiceUploaded" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
            Invoice upload 
             </button>
             <input #file type="file" (change)="uploadinvoice($event)" name="my_file2" id="my-file2" accept="application/pdf,image/*">
             <p *ngIf="invoiceUploaded == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                invoice uploaded successfully 
            </p>
            </div>
            </div>



            <div class="float-right">
                <button
                id="button"
                type="submit"
                [disabled]="!formInvoiceUpload.valid"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Submit
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="cancel()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Cancel
            </button>
            </div>

        </form>
        
    </div>

    <div class="w-full " *ngIf="appStatus == 'INVOICE_SUBMITTED'">
        <form  [formGroup]="formInvoiceUpload" (ngSubmit)="saveScrutinyDetailsForPaymentUpload()">

            <div >
            <label>Selected Quote Details</label>
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                      
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">1. Vendor Name - {{applicationData[0].selectedvendorName}}</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Quote uploaded</div>
                          </div>
                          <br>
                          <br>


                          <label>Amount : {{applicationData[0].selectedammount}}</label>
                        
                    </div>   

                   
                   
                </li>
             
            </ul>
            <div >
                <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Work Order</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Work order uploaded</div>
                          </div>
            </div>

            <div >
                <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Invoice Upload</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Invoice uploaded</div>
                          </div>
            </div>

            <div >
                <label for="message" class="block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Payment Receipt</label>
             <button   (click)="file.click()" [disabled]="paymentUploaded" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
            Receipt upload 
             </button>
             <input #file type="file" (change)="uploadPaymentReceipt($event)" name="my_file2" id="my-file2" accept="application/pdf,image/*">
             <p *ngIf="paymentUploaded == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                Receipt uploaded successfully 
            </p>
            </div>
            </div>



            <div class="float-right">
                <button
                id="button"
                type="submit"
                [disabled]="!formPaymentUpload.valid"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Submit
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="cancel()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Cancel
            </button>
            </div>

        </form>
        
    </div>
   
</div>


