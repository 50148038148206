<article class="table-header">
    <div>
      <i>First Name is required | Must have a valid Email Address</i>
    </div>
    <div>
      <button
        class="button-remove-rows"
        mat-button
        (click)="removeSelectedRows()"
      >
        Remove Rows
      </button>
      <button class="button-add-row" mat-button (click)="addRow()">
        Add Row
      </button>
    </div>
  </article>
  <table mat-table [dataSource]="dataSource">
    <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
      <th mat-header-cell *matHeaderCellDef [ngSwitch]="col.key">
        <span *ngSwitchCase="'isSelected'">
          <mat-checkbox
            (change)="selectAll($event)"
            [checked]="isAllSelected()"
            [indeterminate]="!isAllSelected() && isAnySelected()"
          ></mat-checkbox>
        </span>
        <span *ngSwitchDefault>{{ col.label }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
          <ng-container *ngSwitchCase="'isSelected'">
            <mat-checkbox
              (change)="element.isSelected = $event.checked"
              [checked]="element.isSelected"
            ></mat-checkbox>
          </ng-container>
          <div class="btn-edit" *ngSwitchCase="'isEdit'">
            <button mat-button (click)="element.isEdit = !element.isEdit">
              Edit
            </button>
            <button
              mat-button
              class="button-remove"
              (click)="removeRow(element.id)"
            >
              Delete
            </button>
          </div>
          <span *ngSwitchCase="'date'">
            {{ element[col.key] | date: 'mediumDate' }}
          </span>
          <span *ngSwitchDefault>
            {{ element[col.key] }}
          </span>
        </div>
        <div [ngSwitch]="col.type" *ngIf="element.isEdit">
          <div *ngSwitchCase="'isSelected'"></div>
          <div class="btn-edit" *ngSwitchCase="'isEdit'">
            <button
              mat-button
              (click)="editRow(element)"
              [disabled]="disableSubmit(element.id)"
            >
              Done
            </button>
          </div>
          <mat-form-field
            class="form-input"
            *ngSwitchCase="'date'"
            appearance="fill"
          >
            <mat-label>Choose a date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [(ngModel)]="element[col.key]"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="form-input" *ngSwitchDefault>
            <input
              matInput
              [required]="col.required"
              [pattern]="col.pattern"
              [type]="col.type"
              [(ngModel)]="element[col.key]"
              (change)="inputHandler($event, element.id, col.key)"
            />
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>