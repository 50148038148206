<div class="flex flex-col shadow-2xl drop-shadow-lg mt-16 p-4 custom-height">
    
  
  
    <!-- Full-Width Card -->
    <div class="center-container">
      <!-- Card Content -->
    
    <!-- //<app-dynamic-form [formData]="formData"></app-dynamic-form> -->

    <!-- <app-document-submission [formData]="formData"></app-document-submission> -->
  
      
    </div>
  
    <!-- add dynamic-form component-->
    
  
  </div>
  