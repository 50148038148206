import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-application-form-documents',
  templateUrl: './application-form-documents.component.html',
  styleUrls: ['./application-form-documents.component.scss']
})
export class ApplicationFormDocumentsComponent {
  formId: string = '';

  formData = [
    {
      id: 1,
      name: 'Document 1',
      description: 'This is the first document.',
      required: true,
    },
    {
      id: 2,
      name: 'Document 2',
      description: 'This is the second document.',
      required: false,
    },
    // Add more documents as needed
  ];

  

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.formId = params['id'];
      //alert(`Form ID: ${this.formId}`);
    });

    // if formId is FORM0001, then the form data will be as follows: else formdata will be blank
    
    

  }

}
