import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { PaymentFailureComponent } from "../payment-failure/payment-failure.component";
import { PaymentSuccessComponent } from "../payment-success/payment-success.component";

@Component({
  selector: "app-check-payment-status",
  templateUrl: "./check-payment-status.component.html",
  styleUrls: ["./check-payment-status.component.scss"],
})
export class CheckPaymentStatusComponent {
  transactionId: any = "";
  constructor(public dialog: MatDialog, private route: ActivatedRoute) {}

  ngOnInit(): void {
    var status: any = "";
    this.route.queryParams.subscribe((params) => {
      if (params != undefined) {
        if (Object.keys(params)[0] == "err") {
          this.openFailureDialog();
        } else {
          status = params["trns"].split("&status=")[1];
          if (!status.toLowerCase().startsWith("success")) {
            this.openFailureDialog();
          } else {
            this.transactionId = params["trns"].split("&stuName=")[0];
            this.openSuccessDialog();
          }
        }
      }
    });
  }

  openSuccessDialog() {
    this.dialog.open(PaymentSuccessComponent, {
      disableClose: true,
      width: "500px",
      height: "400px",
      data: this.transactionId,
    });
  }

  openFailureDialog() {
    this.dialog.open(PaymentFailureComponent, {
      disableClose: true,
      width: "500px",
      height: "380px",
    });
  }
}
