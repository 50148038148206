import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationEnd } from "@angular/router";
import { filter, Observable } from "rxjs";
import { AuthService } from "../_services/auth.service";

@Injectable({
  providedIn: "root",
})
export class TokenAuthGuard implements CanActivate {

  private history: string[] = [];

  constructor(private router: Router,private authService : AuthService,private snackBar: MatSnackBar) {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
    
      this.history.push(event.urlAfterRedirects);
    });
  }

  getHistory(): string[] {
    return this.history;
  }

  getPreviousUrl(): string | null {
    if (this.history.length > 1) {
      return this.history[this.history.length - 2];
    }
    return null;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Check if the token exists
    const token = localStorage.getItem("token");
    const urlToRoute = state.url;
    if (token) {
      // Token exists, allow access
      return true;
    } else {
      // Token doesn't exist, redirect to the login page or any other page
      this.router.navigate(["/"], { queryParams: { returnUrl: urlToRoute } });      return false;
    }
  }

  // added for mapping

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   // Check if the token exists
  //   const token = localStorage.getItem("token");
  //   const urlSegments: string[] = next.url.map(segment => segment.path);
  //   const userHasAccess = this.authService.hasAccess(urlSegments);
  //   if (token) {
  //     // Token exists, allow access
  //     // return true;
  //     if(urlSegments[0] != "mapped-dashboard"){
  //       if (userHasAccess) {
  //         return true;
  //       } else {
  //         this.router.navigate(['/404']);
  //         this.snackBar.open(`You do not have access to the url.`, 'Close', {
  //           duration: 3000
  //         });
  //         return false;
  //       }
  //     }else {
  //       return true;

  //     }
     
  //   } else {
  //     // Token doesn't exist, redirect to the login page or any other page
  //     this.router.navigate(["/"]);
  //     return false;
  //   }

   
  // }


}
