import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PropertyTaxService {
  constructor(private http: HttpClient) {}

  getlocation() {
    return this.http.get(`${environment.taxUrl}/api/master/location/getAll`);
  }

  getlocationAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/location/getAllDashboard`
    );
  }

  savelocation(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/location/save`,
      data
    );
  }

  changeFlaglocation(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/location/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  getplottype() {
    return this.http.get(`${environment.taxUrl}/api/master/plottype/getAll`);
  }

  getplottypeAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/plottype/getAllDashboard`
    );
  }

  saveplottype(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/plottype/save`,
      data
    );
  }

  changeFlagplottype(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/plottype/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  getplotdata() {
    return this.http.get(`${environment.taxUrl}/api/master/plot/getAll`);
  }

  getplotdataAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/plot/getAllDashboard`
    );
  }

  saveplot(data: any) {
    return this.http.post(`${environment.taxUrl}/api/master/plot/save`, data);
  }

  changeFlagplotdata(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/plot/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // apartment service
  getapartment() {
    return this.http.get(`${environment.taxUrl}/api/master/appartment/getAll`);
  }

  getapartmentAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/appartment/getAllDashboard`
    );
  }

  saveapartment(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/appartment/save`,
      data
    );
  }

  changeFlagApartment(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/appartment/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // Usage type
  getusagetype() {
    return this.http.get(`${environment.taxUrl}/api/master/usagetype/getAll`);
  }

  getusagetypeAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/usagetype/getAllDashboard`
    );
  }

  saveusagetype(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/usagetype/save`,
      data
    );
  }

  changeFlagusagetype(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/usagetype/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // sub usage type
  getsubbyusageId(data: any) {
    return this.http.get(
      `${environment.taxUrl}/api/master/subusagetype/getSubByUsageId?usageId=${data}`,
      {}
    );
  }

  getsubusagetype() {
    return this.http.get(
      `${environment.taxUrl}/api/master/subusagetype/getAll`
    );
  }

  getsubusagetypeAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/subusagetype/getAllDashboard`
    );
  }

  savesubusagetype(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/subusagetype/save`,
      data
    );
  }

  changeFlagsubusagetype(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/subusagetype/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  getSubUsagetypeByUsage(id: any) {
    return this.http.get(
      `${environment.taxUrl}/api/master/subusagetype/getSubByUsageId?usageId=${id}`
    );
  }

  // building
  getbuilding() {
    return this.http.get(`${environment.taxUrl}/api/master/building/getAll`);
  }

  getBuildingById(id: any) {
    return this.http.get(
      `${environment.taxUrl}/api/master/building/getById?id=${id}`
    );
  }

  getbuildingAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/building/getAllDashboard`
    );
  }

  savebuilding(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/building/save`,
      data
    );
  }

  changeFlagbuilding(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/building/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // rooftype
  getrooftype() {
    return this.http.get(`${environment.taxUrl}/api/master/rooftype/getAll`);
  }
  getrooftypeAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/rooftype/getAllDashboard`
    );
  }
  saverooftype(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/rooftype/save`,
      data
    );
  }
  changeFlagrooftype(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/rooftype/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // floor type
  getfloortype() {
    return this.http.get(
      `${environment.taxUrl}/api/master/floor/getAllDashboard`
    );
  }

  getfloortypeAll() {
    return this.http.get(`${environment.taxUrl}/api/master/floor/getAll`);
  }

  savefloortype(data) {
    return this.http.post(`${environment.taxUrl}/api/master/floor/save`, data);
  }

  changeFlagfloortype(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/floor/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // grouping tax
  getgroupingtax() {
    return this.http.get(`${environment.taxUrl}/api/master/groupingtax/getAll`);
  }
  getgroupingtaxAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/groupingtax/getAllDashboard`
    );
  }
  savegroupingtax(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/groupingtax/save`,
      data
    );
  }

  changeFlaggroupingtax(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/groupingtax/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // tax master
  gettaxmaster() {
    return this.http.get(`${environment.taxUrl}/api/master/tax/getAll`);
  }

  gettaxmasterAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/tax/getAllDashboard`
    );
  }

  savetaxmaster(data) {
    return this.http.post(`${environment.taxUrl}/api/master/tax/save`, data);
  }

  changeFlagtaxmaster(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/tax/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // tax rate
  gettaxratemaster() {
    return this.http.get(`${environment.taxUrl}/api/master/taxrate/getAll`);
  }

  gettaxratemasterAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/taxrate/getAllDashboard`
    );
  }

  savetaxratemaster(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/taxrate/save`,
      data
    );
  }

  changeFlagtaxratemaster(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/taxrate/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // discount
  getdiscount() {
    return this.http.get(`${environment.taxUrl}/api/master/discount/getAll`);
  }

  getdiscountAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/discount/getAllDashboard`
    );
  }

  savediscount(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/discount/save`,
      data
    );
  }

  changeFlagdiscount(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/discount/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // reassessment
  getreassessment() {
    return this.http.get(
      `${environment.taxUrl}/api/master/reassessmentreason/getAll`
    );
  }

  getreassessmentAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/reassessmentreason/getAllDashboard`
    );
  }

  savereassessment(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/reassessmentreason/save`,
      data
    );
  }

  changeFlagreassessment(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/reassessmentreason/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // exemption
  getexemption() {
    return this.http.get(`${environment.taxUrl}/api/master/exemption/getAll`);
  }

  getexemptionAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/exemption/getAllDashboard`
    );
  }

  saveexemption(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/exemption/save`,
      data
    );
  }

  changeFlagexemption(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/exemption/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // rebate
  getrebate() {
    return this.http.get(`${environment.taxUrl}/api/master/Rebate/getAll`);
  }

  getrebateAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/Rebate/getAllDashboard`
    );
  }
  saverebate(data) {
    return this.http.post(`${environment.taxUrl}/api/master/Rebate/save`, data);
  }
  changeFlagrebate(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/Rebate/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // bank
  getbank() {
    return this.http.get(`${environment.taxUrl}/api/master/Bank/getAll`);
  }

  getbankAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/Bank/getAllDashboard`
    );
  }

  savebank(data) {
    return this.http.post(`${environment.taxUrl}/api/master/Bank/save`, data);
  }

  changeFlagbank(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/Bank/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // structure
  getstructure() {
    return this.http.get(`${environment.taxUrl}/api/master/structure/getAll`);
  }

  getstructureAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/structure/getAllDashboard`
    );
  }

  savestructure(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/structure/save`,
      data
    );
  }

  changeFlagstructure(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/structure/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // transfer-type
  getTransferType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/TransferType/getAll`
    );
  }

  getTransferTypeAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/TransferType/getAllDashboard`
    );
  }

  saveTransferType(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/TransferType/save`,
      data
    );
  }

  changeFlagTransferType(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/TransferType/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // ModificationReason
  getModificationReason() {
    return this.http.get(
      `${environment.taxUrl}/api/master/ModificationReason/getAll`
    );
  }

  getModificationReasonAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/ModificationReason/getAllDashboard`
    );
  }

  saveModificationReason(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ModificationReason/save`,
      data
    );
  }

  changeFlagModificationReason(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ModificationReason/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // billcollector
  getbillcollector() {
    return this.http.get(
      `${environment.taxUrl}/api/master/billcollector/getAll`
    );
  }

  getbillcollectorAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/billcollector/getAllDashboard`
    );
  }

  savebillcollector(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/billcollector/save`,
      data
    );
  }

  changeFlagbillcollector(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/billcollector/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  //  mstDishonouredCheckReason
  getdishonoured() {
    return this.http.get(
      `${environment.taxUrl}/api/master/mstDishonouredCheckReason/getAll`
    );
  }

  getdishonouredAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/mstDishonouredCheckReason/getAllDashboard`
    );
  }

  savedishonoured(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/mstDishonouredCheckReason/save`,
      data
    );
  }

  changeFlagdishonoured(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/mstDishonouredCheckReason/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  //  fees
  getfees() {
    return this.http.get(`${environment.taxUrl}/api/master/mstFees/getAll`);
  }
  getfeesAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/mstFees/getAllDashboard`
    );
  }
  savefees(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/mstFees/save`,
      data
    );
  }

  changeFlagfees(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/mstFees/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  //  property tax
  getPropertyMaster() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/getAll`
    );
  }

  getUserWisePropertyData(status: string[]) {
    const params = new HttpParams().set("status", status.join(","));

    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/getAllByStatus`,
      { params }
    );
  }

  // getUserWiseAndWardPropertyData(status: string[], wardId: any) {
  //   const params = new HttpParams().set("status", status.join(","));

  //   return this.http.get(
  //     `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/getAllByStatus`,
  //     { params }
  //   );
  // }

  getUserWiseAndWardPropertyData(status: string[], wardId: any) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/getAllByStatusAndWardId?status=${status}&wardId=${wardId}`,
      {}
    );
  }

  getPropertyRegistrationByUserId(userCreatedId: any) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/getByUserId?userCreatedId=${userCreatedId}`,
      {}
    );
  }

  savePropertyTaxRegistration(data: FormData) {
    return this.http.post<any>(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/save`,
      data
    );
  }

  savePropertyTaxRegistration1(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/save`,
      data
    );
  }

  // letting  rate category

  getlettingratecategory() {
    return this.http.get(
      `${environment.taxUrl}/api/master/lettingratecategory/getAll`
    );
  }

  getAllForMatrix() {
    return this.http.get(
      `${environment.taxUrl}/api/master/lettingratecategory/getAllForMatrix`
    );
  }

  getlettingratecategoryAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/lettingratecategory/getAllDashboard`
    );
  }

  savelettingratecategory(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/lettingratecategory/save`,
      data
    );
  }

  changeFlaglettingratecategory(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/lettingratecategory/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  getlettingsectorbyward(data: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/sector/getSectorByWardId?wardId=${data}`
    );
  }

  getlettingsectorbynode(data: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/sector/getSectorByNodeId?nodeId=${data}`
    );
  }

  getsectorbyward(data: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/sector/getSectorByWardId?wardId=${data}`
    );
  }

  getlsectorbynode(data: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/sector/getSectorByNodeId?nodeId=${data}`
    );
  }

  // letting rate sub category
  getlettingratesubcategory() {
    return this.http.get(
      `${environment.taxUrl}/api/master/lettingratesubcategory/getAll`
    );
  }

  getlettingratesubcategoryAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/lettingratesubcategory/getAllDashboard`
    );
  }

  savelettingratesubcategory(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/lettingratesubcategory/save`,
      data
    );
  }

  changeFlaglettingratesubcategory(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/lettingratesubcategory/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  getByLettingRateCategory(categoryId: string): Observable<any> {
    return this.http.get(
      `${environment.taxUrl}/api/master/lettingratesubcategory/getByLettingRateCategory?categoryId=${categoryId}`,
      {}
    );
  }

  // Attachment and Auction
  saveAttachmentAndAuction(data: FormData) {
    return this.http.post<any>(
      `${environment.taxUrl}/api/transaction/trnPropertyAttachmentandAuction/save`,
      data
    );
  }

  getAllAtachmentsAndAuction() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnPropertyAttachmentandAuction/getAllDashboard`
    );
  }

  getUserWiseAndWardAttachmentAndAuctionData(status: string[], wardId: any) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnPropertyAttachmentandAuction/getByStatusAndWardId?status=${status}&wardId=${wardId}`,
      {}
    );
  }

  getByAttachmentAndAuctionById(Id: string): Observable<any> {
    const url = `${environment.taxUrl}/api/transaction/trnPropertyAttachmentandAuction/getById?id=${Id}`;
    return this.http.get(url);
  }

  scrutinyForAttachmentApprove(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnPropertyAttachmentandAuction/scrutinyForApprove`,
      data
    );
  }

  // financial year
  getfinancialyear() {
    return this.http.get(
      `${environment.taxUrl}/api/master/financialyear/getAll`
    );
  }

  getfinancialyearAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/financialyear/getAllDashboard`
    );
  }

  savefinancialyear(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/financialyear/save`,
      data
    );
  }

  changeFlagfinancialyear(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/financialyear/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // letting rate master
  getlettingratemaster() {
    return this.http.get(`${environment.taxUrl}/api/master/lettingrate/getAll`);
  }
  getlettingratemasterAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/lettingrate/getAllDashboard`
    );
  }
  savelettingratemaster(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/lettingrate/save`,
      data
    );
  }
  changeFlaglettingratemaster(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/lettingrate/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // delayed payment
  getdelaypayment() {
    return this.http.get(
      `${environment.taxUrl}/api/master/paymentdelaycharges/getAll`
    );
  }

  getdelaypaymentAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/paymentdelaycharges/getAllDashboard`
    );
  }

  savedelaypayment(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/paymentdelaycharges/save`,
      data
    );
  }

  changeFlagdelaypayment(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/paymentdelaycharges/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // tax policy
  gettaxpolicy() {
    return this.http.get(`${environment.taxUrl}/api/master/taxpolicy/getAll`);
  }
  gettaxpolicyAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/taxpolicy/getAllDashboard`
    );
  }

  savetaxpolicy(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/taxpolicy/save`,
      data
    );
  }

  changeFlagtaxpolicy(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/taxpolicy/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // amenstyscheme
  getamenstyscheme() {
    return this.http.get(
      `${environment.taxUrl}/api/master/amenstyscheme/getAll`
    );
  }

  getamenstyschemebyid(id: any) {
    return this.http.get(
      `${environment.taxUrl}/api/master/amenstyscheme/getById?id=${id}`,
      {}
    );
  }

  getamenstyschemeAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/amenstyscheme/getAllDashboard`
    );
  }

  saveamenstyscheme(formData: FormData) {
    return this.http.post(
      `${environment.taxUrl}/api/master/amenstyscheme/save`,
      formData
    );
  }

  changeFlagAmenstyScheme(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/amenstyscheme/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // amenities
  getamenities() {
    return this.http.get(`${environment.taxUrl}/api/master/ammenities/getAll`);
  }

  getamenitiesAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/ammenities/getAllDashboard`
    );
  }

  saveamenities(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ammenities/save`,
      data
    );
  }

  changeFlagamenities(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ammenities/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  getByAmmenitiesTypeId(ammenitiesTypeId: any) {
    return this.http.get(
      `${environment.taxUrl}/api/master/ammenities/getByAmmenitiesTypeId?ammenitiesTypeId=${ammenitiesTypeId}`
    );
  }

  // amenities type

  getamenitiestype() {
    return this.http.get(
      `${environment.taxUrl}/api/master/ammenitiestype/getAll`
    );
  }

  getamenitiestypeAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/ammenitiestype/getAllDashboard`
    );
  }

  saveamenitiestype(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ammenitiestype/save`,
      data
    );
  }

  changeFlagamenitiestype(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ammenitiestype/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // payment type
  getpaymenttype() {
    return this.http.get(`${environment.taxUrl}/api/master/paymenttype/getAll`);
  }

  getpaymenttypeAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/paymenttype/getAllDashboard`
    );
  }

  savepaymenttypetype(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/paymenttype/save`,
      data
    );
  }

  changeFlagapaymenttype(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/paymenttype/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }
  // billing cycle

  getbillingcycle() {
    return this.http.get(`${environment.taxUrl}/api/master/billcycle/getAll`);
  }

  getbillingcycleAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/billcycle/getAllDashboard`
    );
  }

  savebillingcycletype(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/billcycle/save`,
      data
    );
  }

  changeFlagabillingcycle(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/billcycle/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // Property type
  getpropertytype() {
    return this.http.get(
      `${environment.taxUrl}/api/master/propertytype/getAll`
    );
  }

  getpropertytypeAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/propertytype/getAllDashboard`
    );
  }

  savepropertytype(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/propertytype/save`,
      data
    );
  }

  changeFlagpropertytype(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/propertytype/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // Property sub type
  getpropertysubtypebyid(id: any) {
    return this.http.get(
      `${environment.taxUrl}/api/master/propertysubtype/getByPropertytype?propertyTypeId=${id}`,
      {}
    );
  }

  getpropertysubtype() {
    return this.http.get(
      `${environment.taxUrl}/api/master/propertysubtype/getAll`
    );
  }

  getpropertysubtypeAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/propertysubtype/getAllDashboard`
    );
  }

  savepropertysubtype(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/propertysubtype/save`,
      data
    );
  }

  changeFlagpropertysubtype(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/propertysubtype/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // ready reckner rate
  getreadyrecknerrate() {
    return this.http.get(
      `${environment.taxUrl}/api/master/mstReadyRecknerRate/getAll`
    );
  }

  getreadyrecknerrateAll() {
    return this.http.get(
      `${environment.taxUrl}/api/master/mstReadyRecknerRate/getAllDashboard`
    );
  }

  savereadyrecknerrate(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/mstReadyRecknerRate/save`,
      data
    );
  }

  changeFlagreadyrecknerrate(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/mstReadyRecknerRate/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // construction type
  getconstructionType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/constructiontype/getAll`
    );
  }

  getNewPropertyRegistrationScruntiy() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/scrutiny`
    );
  }

  approveApplication(appId: any, approveRemark: any) {
    let body = {};

    body = {
      id: appId,
      approveRemark: approveRemark,
    };

    const formData = new FormData();
    formData.append("requestDtoString", JSON.stringify(body));

    var url =
      `${environment.taxUrl}` +
      "/api/transaction/trnnewpropertyregistration/scrutiny";

    return new Promise((resolve) => {
      this.http
        .post(url, body, { responseType: "text" })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve(err);
        });
    });
  }

  rejectApplication(appId: any, rejectRemark: any) {
    let body = {};

    body = {
      id: appId,
      rejectRemark: rejectRemark,
    };

    const formData = new FormData();
    formData.append("requestDtoString", JSON.stringify(body));

    var url =
      `${environment.taxUrl}` +
      "/api/transaction/trnnewpropertyregistration/scrutiny";

    return new Promise((resolve) => {
      this.http
        .post(url, body, { responseType: "text" })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve(err);
        });
    });
  }

  applicationSubmitPropertyTaxScrutiny(data: any): Observable<any> {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/scrutiny`,
      data
    );
  }

  getByApplicationNo(applicationNo: string): Observable<any> {
    const url = `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/getByApplicationNo?applicationNo=${applicationNo}`;
    return this.http.get(url);
  }

  getByPropertyNo(propertyNo: string): Observable<any> {
    const url = `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/getByPropertyNo?propertyNo=${propertyNo}`;
    return this.http.get(url);
  }

  getPropertyRegistrationById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/getById?id=${id}`
    );
  }

  //Property Tax Cancellation
  savePropertyTaxCancellation(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/propertycancellation/save`,
      data
    );
  }

  getPropertyCancellation() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/propertycancellation/getAll`
    );
  }

  getPropertyCancellationById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/propertycancellation/getById?id=${id}`
    );
  }

  //Property Tax Transfer
  savePropertyTaxTransfer(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/propertytransfer/save`,
      data
    );
  }

  getPropertyTransfer() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/propertytransfer/getAll`
    );
  }

  getPropertyTransferById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/propertytransfer/getById?id=${id}`
    );
  }

  //Property Tax Modification
  savePropertyTaxModification(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnpropertymodification/save`,
      data
    );
  }

  getPropertyodification() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnpropertymodification/getAll`
    );
  }

  getPropertyModificationById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnpropertymodification/getById?id=${id}`
    );
  }

  scrutinyForModificationReject(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnpropertymodification/scrutinyForReject`,
      data
    );
  }

  scrutinyForModificationApprove(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnpropertymodification/scrutinyForApprove`,
      data
    );
  }

  //Property Assessment
  savePropertyAssessment(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnpropertyassesment/save`,
      data
    );
  }

  getPropertyAssesment() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnpropertyassesment/getAll`
    );
  }

  getPropertyAssesmentById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnpropertyassesment/getById?id=${id}`
    );
  }

  //Cheque Dishonour
  getChequeDishounr() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnChequeDishonour/getAll`
    );
  }

  saveChequeDishonour(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnChequeDishonour/save`,
      data
    );
  }

  getChequeDishonourById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnChequeDishonour/getById?id=${id}`
    );
  }

  getChequeDishonourByChequeNo(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/taxcollection/getByChequeNo?chequeOrDdNo=${id}`
    );
  }


  //Objection Entry
  saveObjectionEntry(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/objectionEntry/save`,
      data
    );
  }

  saveObjectionPositiveScrutiny(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/objectionEntry/approveScrutiny`,
      data
    );
  }

  saveObjectionNegativeScrutiny(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/objectionEntry/rejectScrutiny`,
      data
    );
  }

  getObjectionEntry() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/objectionEntry/getAll`
    );
  }

  getObjectionEntryById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/objectionEntry/getById?id=${id}`
    );
  }

  //Hearing on Objection Entry
  saveHearing(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnHearingOnObjectionRaised/save`,
      data
    );
  }

  getObjectionHearings() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnHearingOnObjectionRaised/getAll`
    );
  }

  getAllObjectionsRaised(){
    return this.http.get(`${environment.taxUrl}/api/transaction/trnHearingOnObjectionRaised/getAllObjectionRaised`);
  }

  scrutinyForHearingApprove(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnHearingOnObjectionRaised/approveScrutiny`,
      data
    );
  }

  scrutinyForHearingReject(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnHearingOnObjectionRaised/rejectScrutiny`,
      data
    );
  }
  

  getObjectionHearingsById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnHearingOnObjectionRaised/getById?id=${id}`
    );
  }

  getUserWiseAndWardObjectionHearingData(status: string[], wardId: any) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnHearingOnObjectionRaised/getByStatusAndWardId?status=${status}&wardId=${wardId}`,
      {}
    );
  }

  getStatusWiseObjectionHearingData(status: string[]) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnHearingOnObjectionRaised/getByStatus?status=${status}`,
      {}
    );
  }

  //property reconstruction & demolition
  saveReconstruction(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnpropertyreconstruction/save`,
      data
    );
  }

  getReconstruction() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnpropertyreconstruction/getAll`
    );
  }

  getReconstructionById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnpropertyreconstruction/getById?id=${id}`
    );
  }

  //bill generation
  saveBillGeneration(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnBillGeneration/save`,
      data
    );
  }

  generateBillForCycle(data) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnBillGeneration/generateBillForCycle`,
      data
    );
  }

  getPropertyBillsList() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnBillGeneration/getAll`
    );
  }

  getBillDetailsById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnBillGeneration/getBillDetailsById?id=${id}`
    );
  }

  getPreviousBillById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnBillGeneration/getById?id=${id}`
    );
  }

  getBillDetailsByPropertyNo(propertyNo: string): Observable<any> {
    const url = `${environment.taxUrl}/api/transaction/trnBillGeneration/getBillDetailsByPropertyNo?propertyNo=${propertyNo}`;
    return this.http.get(url);
  }

  getBillDetailsByBillNo(billNo: string): Observable<any> {
    const url = `${environment.taxUrl}/api/transaction/trnBillGeneration/getBillDetailsByBillNo?billNo=${billNo}`;
    return this.http.get(url);
  }

  getAllBillDefaults() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnBillGeneration/getDefaulterList`
    );
  }

  // Tax component
  gettaxComponent() {
    return this.http.get(`${environment.taxUrl}/api/master/tax/getAll`);
  }

  getAlltaxComponent() {
    return this.http.get(
      `${environment.taxUrl}/api/master/tax/getAllDashboard`
    );
  }

  changeFlagtaxComponent(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/taxpolicy/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // Tax Rate Policy
  savetaxratepolicy(formData: FormData) {
    return this.http.post(
      `${environment.taxUrl}/api/master/taxpolicy/save`,
      formData
    );
  }

  // Society

  getSociety() {
    return this.http.get(
      `${environment.taxUrl}/api/master/society/getAllDashboard`
    );
  }

  getActiveSociety() {
    return this.http.get(`${environment.taxUrl}/api/master/society/getAll`);
  }

  getSocietyById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/master/society/getById?id=${id}`
    );
  }

  changeFlagSociety(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/society/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveSociety(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/society/save`,
      data
    );
  }

  getSocietyByPincode(pin) {
    return this.http.get(
      `${environment.taxUrl}/api/master/society/getAllByPincode?pincode=${pin}`
    );
  }

  // Blocks

  getBlocks() {
    return this.http.get(
      `${environment.taxUrl}/api/master/blocks/getAllDashboard`
    );
  }

  changeFlagBlocks(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/blocks/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveBlocks(data) {
    return this.http.post(`${environment.taxUrl}/api/master/blocks/save`, data);
  }

  // Discount Type

  getDiscountType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/discountType/getAllDashboard`
    );
  }

  getActiveDiscountType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/discountType/getAll`
    );
  }

  changeFlagDiscountType(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/discountType/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveDiscountType(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/discountType/save`,
      data
    );
  }

  //Sub Discount Type

  getSubDiscountType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/subDiscountType/getAllDashboard`
    );
  }

  getActiveSubDiscountType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/subDiscountType/getAll`
    );
  }

  getBySubDiscountTypeId(discountTypeId: any) {
    return this.http.get(
      `${environment.taxUrl}/api/master/subDiscountType/getByDiscountTypeId?discountTypeId=${discountTypeId}`,
      {}
    );
  }

  changeFlagSubDiscountType(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/subDiscountType/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveSubDiscountType(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/subDiscountType/save`,
      data
    );
  }

  //Exemption Type

  getExemptionType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/exemptionType/getAllDashboard`
    );
  }

  getActiveExemptionType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/exemptionType/getAll`
    );
  }

  changeFlagExemptionType(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/exemptionType/activeInactive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveExemptionType(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/exemptionType/save`,
      data
    );
  }

  //Sub Exemption Type

  getSubExemptionType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/subExemptionType/getAllDashboard`
    );
  }

  getActiveSubExemptionType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/subExemptionType/getAll`
    );
  }

  changeFlagSubExemptionType(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/subExemptionType/activeInactive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  getByExemptionTypeId(exemptionTypeId: any) {
    return this.http.get(
      `${environment.taxUrl}/api/master/subExemptionType/getByExemptionTypeId?exemptionTypeId=${exemptionTypeId}`,
      {}
    );
  }

  saveSubExemptionType(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/subExemptionType/save`,
      data
    );
  }

  // Rebate Type

  getRebateType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/rebateType/getAllDashboard`
    );
  }

  getActiveRebateType() {
    return this.http.get(`${environment.taxUrl}/api/master/rebateType/getAll`);
  }

  changeFlagRebateType(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/rebateType/activeInactive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveRebateType(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/rebateType/save`,
      data
    );
  }

  //Sub Rebate Type

  getSubRebateType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/subRebateType/getAllDashboard`
    );
  }

  getActiveSubRebateType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/subRebateType/getAll`
    );
  }

  getByRebateTypeId(rebateTypeId: any) {
    return this.http.get(
      `${environment.taxUrl}/api/master/subRebateType/getByRebateTypeId?rebateTypeId=${rebateTypeId}`,
      {}
    );
  }

  changeFlagSubRebateType(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/subRebateType/activeInactive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveSubRebateType(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/subRebateType/save`,
      data
    );
  }

  // Rectification Reason

  getRectification() {
    return this.http.get(
      `${environment.taxUrl}/api/master/rectificationReason/getAllDashboard`
    );
  }

  changeFlagRectification(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/rectificationReason/activeInactive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveRectification(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/rectificationReason/save`,
      data
    );
  }

  //Special Cases
  getAllSpecialCases() {
    return this.http.get(
      `${environment.taxUrl}/api/master/specialCases/getAllDashboard`
    );
  }

  changeFlagSpecialCases(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/specialCases/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveSpecialCases(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/specialCases/save`,
      data
    );
  }

  // Delayed Payment Charges

  getDelayedCharges() {
    return this.http.get(
      `${environment.taxUrl}/api/master/paymentdelaycharges/getAllDashboard`
    );
  }

  getActiveDelayedCharges() {
    return this.http.get(
      `${environment.taxUrl}/api/master/paymentdelaycharges/getAll`
    );
  }

  changeDelayedCharges(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/paymentdelaycharges/activeInactive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveDelayedCharges(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/paymentdelaycharges/save`,
      data
    );
  }

  // Discount Rate

  getDiscountRate() {
    return this.http.get(
      `${environment.taxUrl}/api/master/discountRate/getAllDashboard`
    );
  }

  getActiveDiscountRate() {
    return this.http.get(
      `${environment.taxUrl}/api/master/discountRate/getAll`
    );
  }

  changeFlagDiscountRate(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/discountRate/activeInactive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveDiscountRate(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/discountRate/save`,
      data
    );
  }

  // Exemption Rate
  getExemptionRate() {
    return this.http.get(
      `${environment.taxUrl}/api/master/exemption/getAllDashboard`
    );
  }

  getActiveExemptionRate() {
    return this.http.get(`${environment.taxUrl}/api/master/exemption/getAll`);
  }

  changeFlagExemptionRate(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/exemption/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveExemptionRate(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/exemption/save`,
      data
    );
  }

  // Rebate Rate
  getRebateRate() {
    return this.http.get(
      `${environment.taxUrl}/api/master/Rebate/getAllDashboard`
    );
  }

  getActiveRebateRate() {
    return this.http.get(`${environment.taxUrl}/api/master/Rebate/getAll`);
  }

  changeFlagRebateRate(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/Rebate/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveRebateRate(data) {
    return this.http.post(`${environment.taxUrl}/api/master/Rebate/save`, data);
  }

  // Collection Center

  getCollectionCenter() {
    return this.http.get(
      `${environment.taxUrl}/api/master/collectionCenter/getAllDashboard`
    );
  }

  getActiveCollectionCenter() {
    return this.http.get(
      `${environment.taxUrl}/api/master/collectionCenter/getAll`
    );
  }

  changeFlagCollectionCenter(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/collectionCenter/activeInactive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveCollectionCenter(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/collectionCenter/save`,
      data
    );
  }

  //DPC Waive Off

  getWaiveOff() {
    return this.http.get(
      `${environment.taxUrl}/api/master/dpcWaiveOffRequest/getAllDashboard`
    );
  }

  getActiveWaiveOff() {
    return this.http.get(
      `${environment.taxUrl}/api/master/dpcWaiveOffRequest/getAll`
    );
  }

  changeFlagWaiveOff(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/dpcWaiveOffRequest/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveWaiveOff(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/dpcWaiveOffRequest/save`,
      data
    );
  }

  // Book Adjustments
  getBookAdjustments() {
    return this.http.get(
      `${environment.taxUrl}/api/master/bookAdjustments/getAllDashboard`
    );
  }

  getActiveBookAdjustments() {
    return this.http.get(
      `${environment.taxUrl}/api/master/bookAdjustments/getAll`
    );
  }

  changeFlagBookAdjustments(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/bookAdjustments/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveBookAdjustments(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/bookAdjustments/save`,
      data
    );
  }

  // Buisness Type

  getBuisnessType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/businessType/getAllDashboard`
    );
  }

  getActiveBuisnessType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/businessType/getAll`
    );
  }

  changeFlagBuisnessType(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/businessType/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveBuisnessType(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/businessType/save`,
      data
    );
  }

  // Industry Type

  getIndustryType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/industryType/getAllDashboard`
    );
  }

  getActiveIndustryType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/industryType/getAll`
    );
  }

  changeFlagIndustryType(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/industryType/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveIndustryType(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/industryType/save`,
      data
    );
  }

  // Attachment Status

  getAttachmentStatus() {
    return this.http.get(
      `${environment.taxUrl}/api/master/attachmentStatus/getAllDashboard`
    );
  }

  getActiveAttachmentStatus() {
    return this.http.get(
      `${environment.taxUrl}/api/master/attachmentStatus/getAll`
    );
  }

  changeFlagAttachmentStatus(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/attachmentStatus/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveAttachmentStatus(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/attachmentStatus/save`,
      data
    );
  }

  // Auction Status

  getAuctionStatus() {
    return this.http.get(
      `${environment.taxUrl}/api/master/auctionStatus/getAllDashboard`
    );
  }

  getActiveAuctionStatus() {
    return this.http.get(
      `${environment.taxUrl}/api/master/auctionStatus/getAll`
    );
  }

  changeFlagAuctionStatus(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/auctionStatus/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveAuctionStatus(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/auctionStatus/save`,
      data
    );
  }

  // Court Case Status

  getCourtCaseStatus() {
    return this.http.get(
      `${environment.taxUrl}/api/master/courtCasesStatus/getAllDashboard`
    );
  }

  getActiveCourtCaseStatus() {
    return this.http.get(
      `${environment.taxUrl}/api/master/courtCasesStatus/getAll`
    );
  }

  changeFlagCourtCaseStatus(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/courtCasesStatus/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveCourtCaseStatus(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/courtCasesStatus/save`,
      data
    );
  }

  // Tax Component

  getTaxComponent() {
    return this.http.get(
      `${environment.taxUrl}/api/master/taxcomponent/getAllDashboard`
    );
  }

  getActiveTaxComponent() {
    return this.http.get(
      `${environment.taxUrl}/api/master/taxcomponent/getAll`
    );
  }

  changeFlagTaxComponent(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/taxcomponent/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  getTaxComponentById(id: any) {
    return this.http.get(
      `${environment.taxUrl}/api/master/taxcomponent/getById?id=${id}`
    );
  }

  saveTaxComponent(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/taxcomponent/save`,
      data
    );
  }

  // Tax

  getTax() {
    return this.http.get(`${environment.taxUrl}/api/master/tax/getAll`);
  }

  getActiveTax() {
    return this.http.get(`${environment.taxUrl}/api/master/tax/getAllActive`);
  }

  changeFlagTax(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/tax/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveTax(data) {
    return this.http.post(`${environment.taxUrl}/api/master/tax/save`, data);
  }

  //Letting Rate Matrix

  getMatrix() {
    return this.http.get(
      `${environment.taxUrl}/api/master/mstLettingRateMatrix/getAllDashboard`
    );
  }

  getActiveMatrix() {
    return this.http.get(
      `${environment.taxUrl}/api/master/mstLettingRateMatrix/getAll`
    );
  }

  changeFlagMatrix(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/mstLettingRateMatrix/activeInactive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  getLettingRateAmmount(
    usageTypeId: any,
    usageSubTypeId: any,
    lettingRateCategoryId: any,
    lettingRateSubCategoryId: any
  ) {
    return this.http.get(
      `${environment.taxUrl}/api/master/mstLettingRateMatrix/getLettingRateAmmount?usageTypeId=${usageTypeId}&usageSubTypeId=${usageSubTypeId}&lettingRateCategoryId=${lettingRateCategoryId}&lettingRateSubCategoryId=${lettingRateSubCategoryId}`,
      {}
    );
  }

  getLettingRateAmmount1(
    effectiveFrom: any,
    usageTypeId: any,
    usageSubTypeId: any,
    wardId: any,
    lettingRateCategoryId: any,
    lettingRateSubCategoryId: any
  ){
    return this.http.get(`${environment.taxUrl}/api/master/mstLettingRateMatrix/getLettingRateAmmount1?effectiveFrom=${effectiveFrom}&usageTypeId=${usageTypeId}&usageSubTypeId=${usageSubTypeId}&wardId=${wardId}&lettingRateCategoryId=${lettingRateCategoryId}&lettingRateSubCategoryId=${lettingRateSubCategoryId}`,{});
  }

  getLettingRateAmmountForParking(
    lettingRateCategoryId: any,
    lettingRateSubCategoryId: any
  ) {
    return this.http.get(
      `${environment.taxUrl}/api/master/mstLettingRateMatrix/getLettingRate?lettingRateCategoryId=${lettingRateCategoryId}&lettingRateSubCategoryId=${lettingRateSubCategoryId}`,
      {}
    );
  }

  saveMatrix(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/mstLettingRateMatrix/save`,
      data
    );
  }

  // Ownership

  getOwnership() {
    return this.http.get(
      `${environment.taxUrl}/api/master/ownership/getAllDashboard`
    );
  }

  getActiveOwnership() {
    return this.http.get(`${environment.taxUrl}/api/master/ownership/getAll`);
  }

  changeFlagOwnership(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ownership/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveOwnership(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ownership/save`,
      data
    );
  }

  // Ownership

  getOccupancy() {
    return this.http.get(
      `${environment.taxUrl}/api/master/occupancy/getAllDashboard`
    );
  }

  getActiveOccupancy() {
    return this.http.get(`${environment.taxUrl}/api/master/occupancy/getAll`);
  }

  changeFlagOccupancy(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/occupancy/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveOccupancy(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/occupancy/save`,
      data
    );
  }

  // Property Status

  getPropertyStatus() {
    return this.http.get(
      `${environment.taxUrl}/api/master/propertyStatus/getAllDashboard`
    );
  }

  getActivePropertyStatus() {
    return this.http.get(
      `${environment.taxUrl}/api/master/propertyStatus/getAll`
    );
  }

  changeFlagPropertyStatus(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/propertyStatus/activeInActive?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  savePropertyStatus(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/propertyStatus/save`,
      data
    );
  }

  // bill type
  getActiveBillType() {
    return this.http.get(`${environment.taxUrl}/api/master/billType/getAll`);
  }

  //Registration
  scrutinyForRegistrationApprove(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/scrutinyForApprove`,
      data
    );
  }

  scrutinyForRegistrationReject(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/trnnewpropertyregistration/scrutinyForReject`,
      data
    );
  }

  // Property Multi Service
  saveMultiService(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/multiaction/save`,
      data
    );
  }

  scrutinyForMultiServiceApprove(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/multiaction/scrutinyForApprove`,
      data
    );
  }

  scrutinyForMultiServiceReject(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/multiaction/scrutinyForReject`,
      data
    );
  }

  getServiceData() {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/multiaction/getAll`
    );
  }

  getServiceDataByWardandWorkflow(data: any, wardId: any) {
    const params = new HttpParams().set('wardId', wardId);

    return this.http.post(
        `${environment.taxUrl}/api/transaction/multiaction/getByStatusAndWardId`,
        data,
        { params }
    );
  }

  getMultiServiceByUserId(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/multiaction/getByUserId?userCreatedId=${id}`
    );
  }

  getPropertyMultiServiceById(id) {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/multiaction/getById?id=${id}`
    );
  }

  // Abstract Report
  getAbstractByPropertyNo(propertyNo: string): Observable<any> {
    const url = `${environment.taxUrl}/api/propertyTax/report/getAbstractReport?propertyNo=${propertyNo}`;
    return this.http.get(url);
  }

  // Rateble Value Slab
  saveRatebleValueSlab(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ratebleValueSlab/save`,
      data
    );
  }

  getRatebleValueSlab() {
    return this.http.get(
      `${environment.taxUrl}/api/master/ratebleValueSlab/getAllDashboard`
    );
  }

  getActiveRatebleValueSlab() {
    return this.http.get(
      `${environment.taxUrl}/api/master/ratebleValueSlab/getAll`
    );
  }

  changeFlagRatebleValueSlab(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ratebleValueSlab/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  // House Hold
  saveHouseHold(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/ratebleValueSlab/save`,
      data
    );
  }

  //Fetch Bank Details
  getBankDetailsByIFSC(ifscCode: string): Observable<any> {
    const apiUrl = "https://bank-apis.justinclicks.com/API/V1/IFSC/";
    const url = `${apiUrl}${ifscCode}`;
    return this.http.get<any>(url);
  }

  //Fetch bill Details
  getBillDetailsByPropertyNoAndFinancialYear(
    propertyNo: string,
    financialYear: string,
    billingCycle: string
  ): Observable<any> {
    return this.http.get(
      `${environment.taxUrl}/api/transaction/trnBillGeneration/getBillDetails?propertyNo=${propertyNo}&financialYear=${financialYear}&billingCycle=${billingCycle}`,
      {}
    );
  }

  // pay tax
  payBill(data: any) {
    return this.http.post(
      `${environment.taxUrl}/api/transaction/taxcollection/pay`,
      data
    );
  }


  //generate Vishesh Notice
  // generateVisheshNoticeById(propertyNo: string): Observable<string> {
  //   const url = `${environment.rtsUrl}/api/propertyTax/report/generateVisheshNotice?propertyNo=${propertyNo}`;
    
  //   return this.http.post<string>(url, null, { responseType: 'text' as 'json' });
  // }

  generateVisheshNoticeById(propertyNo: string): Observable<Blob> {
    const url = `${environment.taxUrl}/api/propertyTax/report/generateVisheshNotice?propertyNo=${propertyNo}`;
    return this.http.post<Blob>(url, null, { responseType: 'blob' as 'json' });
  }
  
  getDocPreview(filePath: string): Observable<Blob> {
    const url = `${environment.commonUrl}/api/file/preview?filePath=${filePath}`;
    return this.http.get(url, { responseType: 'blob' });
  }
  

  // generatePaymentReceiptByApplicationNumberAndAmount(applicationNumber: string, amount: number): Observable<string> {
  //   const url = `${environment.rtsUrl}/api/reports/generatePaymentReceipt?applicationNumber=${applicationNumber}&amount=${amount}`;
    
  //   return this.httpClient.post<string>(url, null, { responseType: 'text' as 'json' });
  // }

  // Cideco Rate Type

  getCidcoRateType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/cidco-rate-type/getAllDashboard`
    );
  }

  getActiveCidcoRateType() {
    return this.http.get(
      `${environment.taxUrl}/api/master/cidco-rate-type/getAll`
    );
  }

  changeFlagCidcoRateType(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/cidco-rate-type/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  saveCidcoRateType(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/cidco-rate-type/save`,
      data
    );
  }

  // Cideco Rate

  getCidcoRate() {
    return this.http.get(
      `${environment.taxUrl}/api/master/cidco-rate/getAllDashboard`
    );
  }

  getActiveCidcoRate() {
    return this.http.get(
      `${environment.taxUrl}/api/master/cidco-rate/getAll`
    );
  }

  changeFlagCidcoRate(id: any, flag: any) {
    return this.http.post(
      `${environment.taxUrl}/api/master/cidco-rate/changeFlag?id=${id}&activeFlag=${flag}`,
      {}
    );
  }

  getCidcoRateByFinancialYear(financialYear: string) {
    return this.http.get(
      `${environment.taxUrl}/api/master/cidco-rate/getByFinancialYear?financialYear=${financialYear}`
    );
  }

  saveCidcoRate(data) {
    return this.http.post(
      `${environment.taxUrl}/api/master/cidco-rate/save`,
      data
    );
  }
}
