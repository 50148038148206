import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { AddEditTitleComponent } from 'src/app/WATER-TAX/MASTER/title/add-edit-title/add-edit-title.component';
import { CommonService } from 'src/app/_services/Common-Services/common.service';

@Component({
  selector: 'app-add-edit-title-master',
  templateUrl: './add-edit-title-master.component.html',
  styleUrls: ['./add-edit-title-master.component.scss']
})
export class AddEditTitleMasterComponent {
 
  form!: FormGroup;
  rowData: any
  action: any = ''
  

  constructor(private commonService: CommonService,
    private fb: FormBuilder,
    private _snackBar: SnackbarMsgService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditTitleComponent>) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      id: [""],
      activeFlag: ["Y"],
      titleName: ["", Validators.required],
      titleNameMr: [""],
      valid: [""],
      createdUserId: [""],
      updateUserId: [""],

    });


    if (this.data.isEditMode == true) {
      this.rowData = this.data.row
      this.form.patchValue({ "id": this.rowData.id })
      this.form.patchValue({ "titleName": this.rowData.titleName })
      this.form.patchValue({ "titleNameMr": this.rowData.titleNameMr })
      this.form.patchValue({ "valid": this.rowData.valid })
      this.form.patchValue({ "activeFlag": this.rowData.activeFlag })
     

    
      this.action = 'Edit'
    } else {

      this.action = 'Add'

    }
  }

  saveTitle() {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.action,
        component: 'Title'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.form.disable()

        this.form.patchValue(
          {
            'TitleNameMr' : this.form.get('TitleName')?.value
          }
        ) 

        this.commonService.saveTitle(this.form.value).subscribe((data: any) => {
          if (data['status'] == "Success") {


            if (this.action == 'Add') {
              this._snackBar.success('Success: Title added successfully');

            } else {
              this._snackBar.success('Success: Title updated successfully');

            }

            setTimeout(() => {
              this.dialogRef.close({ data: 'Success' });
            }, 1000);
          } else {
            this.form.enable()
            // this._snackBar.open('Unable to save Title', 'Close', {
            //   horizontalPosition: 'right',
            //   verticalPosition: 'top',
            //   duration: 3000,
            // });
            if (this.action == 'Add') {
              this._snackBar.error('Error: Title addition failed');
              

            } else {
              this._snackBar.error('Error: Title updation failed');

            }
          }
        })
      }
    })
  }


}


