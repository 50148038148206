import { Component, ViewChild } from '@angular/core';
import { AddCastComponent } from './add-cast/add-cast.component';
import { MatTableDataSource } from '@angular/material/table';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-caste-master',
  templateUrl: './caste-master.component.html',
  styleUrls: ['./caste-master.component.scss']
})
export class CasteMasterComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data:any;
  columnNames: any[] = [
    {
      columnDef: 'sno',
      header: 'Sr.No.',
    },
    {
      columnDef: 'religion',
      header: 'Religion',
    },
    {
      columnDef: 'cast',
      header: 'Caste',
    },
    {
      columnDef: 'description',
      header: 'Description',
    },
    {
      columnDef: 'actions',
      header: 'Actions',
    },
  ];
  departments = new MatTableDataSource<any>();
  displayedColumns: any[] = []
  contentLoaded = false;
  constructor(private msmService: MsmamServiceService,
     private dialog: MatDialog,
     private _snackBar: SnackbarMsgService,) {
  
  }
  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));
    this.getCaste()
  }
  
  getCaste() {
    this.msmService.getCast().subscribe(
      data => {
        this.departments.data = data['castMasterList'];
        this.departments.data = this.departments.data.sort((a, b) => a.id - b.id);
        setTimeout(
          () => {
            this.departments.sort = this.sort;
            this.departments.paginator = this.paginator;
          });
  
        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
  
      },
      err => {
        this.contentLoaded = true;
  
      }
    )
  }
  
  addCaste() {
    const dialogRef = this.dialog.open(AddCastComponent, {
      width: "550px",
      data: {
        isEditMode: false,
      },
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getCaste()
      }
    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.departments.filter = filterValue.trim().toLowerCase();
  }

  onSlideToggleChange(row: any, event: any): void {
    
    row.activeFlag = event.checked ? 'Y' : 'N';
    if (event.checked) {
      this.msmService.activeInactiveCast(row.id,row.activeFlag).subscribe(
        res => {
          this._snackBar.success(row.cast+' is Activated');
          },
        err => {
          this._snackBar.error('Activation Flag Failed to update')
        }
      )
      
    } else {
      this.msmService.activeInactiveCast(row.id,row.activeFlag).subscribe(
        res => {
          this._snackBar.error(row.cast+' is Deactivated');
          this.getCaste();
          },
        err => {
          this._snackBar.error('Activation Flag Failed to Delete')
        })
    }
   
  }
  onEdit(row:any){
    const dialogRef = this.dialog.open(AddCastComponent, {
      width: "550px",
      data: {
        isEditMode: true,
        row:row
      },
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getCaste()
      }
    });
  }
}
