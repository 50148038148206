import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private httpClient: HttpClient) { }

  getUsers(){
    var url = `${environment.commonUrl}` + '/api/master/user/getAllDashboard'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }
  getActiveUsers(desigId:any,wardId:any,deptName:any){
    return this.httpClient.get(`${environment.commonUrl}/api/master/user/designationAndDepartment?designationId=${desigId}&wardId=${wardId}&departmentName=${deptName}`)
  }
  
  getCfcUsers(){
    var url = `${environment.commonUrl}` + '/api/master/user/getAllCfcUsers?ugpPrevId=4'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  getDeptUsers(){
    var url = `${environment.commonUrl}` + '/api/master/user/getAllDepartmentUser'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }



  saveUser(userFirstname: any,userLastname : any,userEmail: any,userMobile : any,userPassword : any,
    ugpPrevId : any,dptId : any,subDptId : any,designationId : any,mobileOtp : any,
    emailOtp : any,gender : any,adress : any,state : any,city : any,pinCode : any,activeFlag : any,userName : any,isCfcUsr : any, isDeptUser : any,
     wardId : any,id : any){
    let body = {}
    
   
    body = {
      userFirstName : userFirstname,
      userFirstNameMr : userFirstname,
      userLastName : userLastname,
      userLastNameMr : userLastname,
      userName : userName,
      userEmail : userEmail,
      userMobile : userMobile,
      userPassword : userPassword,
      ugpPrevId : ugpPrevId,
      dptId : dptId,
      subDptId : subDptId,
      designationId : designationId,
      // mobileOtp : mobileOtp,
      // emailOtp : emailOtp,
      gender : gender,
      // adress : adress,
      // state : state,
      // city : city,
      // pinCode : pinCode,
      activeFlag : activeFlag,
      isCfcUser : isCfcUsr,
      isDptUser : isDeptUser,
      // zoneId : zoneId,
      wardId : wardId,
      id : id

    }

    const formData = new FormData();
    formData.append("requestDtoString", JSON.stringify(body));

    var url = `${environment.commonUrl}` + '/api/master/user/saveV1'

    return new Promise(resolve => {
      this.httpClient.post(url,body,{responseType:'text'}).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })

  }

  getUsersByDeptDesg(dptId : any,designationId : any){
    var url = `${environment.commonUrl}` + '/api/master/user/getAllEmployeebyDptIdAndDesId?dptId=' + dptId + '&designationId=' + designationId

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  registerUser(userFirstname: any,userFirstnameMr : any,userLastname: any,userLastnameMr : any,userEmail : any, userMobile : any,
    activeFlag:any,userPassword : any){
    let body = {}

    body = {
      userFirstName : userFirstname,
      userFirstNameMr : userFirstnameMr,
      userLastName : userLastname,
      userLastNameMr : userLastnameMr,
      userEmail : userEmail,
      userMobile : userMobile,
      activeFlag : activeFlag,
      userPassword : userPassword,
    }

    // const formData = new FormData();
    // formData.append("requestDtoString", JSON.stringify(body));
    var url = `${environment.commonUrl}` + '/api/master/user/save' 
    return new Promise(resolve => {
      this.httpClient.post(url,body,{responseType:'text'}).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })

  }

  redirectDigi(usrId: any) {
    const url = `${environment.commonUrl}/api/digilocker/createDigilockerUrl`;
    const params = new HttpParams().set('userId', usrId).set('deviceType', 'browser-local');
  
    // console.log(url, params.toString()); // Optional: Log the URL and parameters for debugging
  
    return this.httpClient.post(url,null, { params });
  }
  
  logindigi(data:any){
    // localhost:8090/digilocker/getEaadharFromDigi
    // const url = `${environment.apiUrl}/api/digilocker/getEaadharFromDigi`,data;
    return this.httpClient.post(`${environment.commonUrl}/api/digilocker/getEaadharFromDigi`,data)
  }

  activateInactivateUser(id,activeFlag){
    return this.httpClient.post(`${environment.commonUrl}/api/master/user/changeFlag?id=${id}&activeFlag=${activeFlag}`,null)
  }

  requestEmailOTP(data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.commonUrl}/api/master/user/generateEmailOtp`,data)
  }

  verifyEmailOTP(data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.commonUrl}/api/master/user/verifyEmailOTP`,data)
  }

  requestMobileOTP(data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.commonUrl}/api/master/user/generateMobileOTP`,data)
  }

  verifyMobileOTP(data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.commonUrl}/api/master/user/verifyMobileOTP`,data)
  }

  requestOTP(data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.commonUrl}/api/master/user/requestOTP`,data)
  }

  updateCitizenPassword(data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.commonUrl}/api/master/user/updatePassword`,data)
  }
}
