import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { AddIdentifiactionproofComponent } from "src/app/MSMAM/identifiactionproof-master/add-identifiactionproof/add-identifiactionproof.component";
import { MsmamServiceService } from "src/app/_services/MSMAM/msmam-service.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";

@Component({
  selector: "app-add-media-channel-master",
  templateUrl: "./add-media-channel-master.component.html",
  styleUrls: ["./add-media-channel-master.component.scss"],
})
export class AddMediaChannelMasterComponent {
  form: FormGroup = new FormGroup({});
  mediaChannelName: string = "";
  addedit: any;
  id = null;
  errorMessage: string = "";
  createdUserId: any;
  updateUserId: any;
  userId: string;
  constructor(
    private msmService: MsmamServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddMediaChannelMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any
  ) {}
  ngOnInit() {
    this.addedit = this.data1["isEditMode"];
    this.userId = localStorage.getItem("userId");
    this.createdUserId = this.userId;
    this.updateUserId = this.userId;
    this.form = this.fb.group({
      // id : [""],
      mediaChannelName: new FormControl(null, [Validators.required]),
      createdUserId: [this.userId],
      updateUserId: [this.userId],
      activeFlag: ["Y"],
    });
    if (this.addedit == true) {
      const data12 = this.data1["row"];
      this.createdUserId = data12["createdUserId"];
      this.updateUserId = this.userId;
      this.mediaChannelName = data12["mediaChannelName"];
      this.form.get("mediaChannelName").setValue(this.mediaChannelName);
      this.id = data12["id"];
    }
  }

  save() {
    if (this.form.valid) {
      const data = {
        id: this.id,
        mediaChannelName: this.form.value.mediaChannelName,
        createdUserId: this.createdUserId,
        updateUserId: this.updateUserId,
        activeFlag: "Y",
      };
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: "550px",
        data: {
          mode: this.addedit ? "Edit" : "Add",
          component: "Media Channel ",
        },
        autoFocus: false,
        restoreFocus: false,
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == "success") {
          this.msmService.saveMediaChannelDetail(data).subscribe(
            (res) => {
              if (res["status"] === "Success") {
                setTimeout(() => {
                  this.dialogRef.close({ data: "Success" });
                }, 1000);
              } else {
                this.errorMessage = res["status"];
              }
            },
            (err) => {
              this._snackBar.error("Media Channel not added");
            }
          );
        }
      });
    }
  }
}
