import { AddIdentifiactionproofComponent } from "../identifiactionproof-master/add-identifiactionproof/add-identifiactionproof.component";
import { Component, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MsmamServiceService } from "src/app/_services/MSMAM/msmam-service.service";
import { MatDialog } from "@angular/material/dialog";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { AddMediaMasterComponent } from "./add-media-master/add-media-master.component";
import { Overlay } from "@angular/cdk/overlay";

@Component({
  selector: "app-media-master",
  templateUrl: "./media-master.component.html",
  styleUrls: ["./media-master.component.scss"],
})
export class MediaMasterComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;
  columnNames: any[] = [
    {
      columnDef: "sno",
      header: "Sr.No.",
    },
    {
      columnDef: "mediaChannelName",
      header: "Media Channel",
    },
    {
      columnDef: "mediaName",
      header: "Media Name",
    },
    {
      columnDef: "address",
      header: "Address",
    },
    {
      columnDef: "concernedPerson",
      header: "Concerned Person",
    },
    {
      columnDef: "contactNumber",
      header: "Contact Number",
    },
    {
      columnDef: "website",
      header: "Website",
    },
    {
      columnDef: "email",
      header: "e-Mail",
    },
    {
      columnDef: "actions",
      header: "Actions",
    },
  ];
  mediaList = new MatTableDataSource<any>();
  displayedColumns: any[] = [];
  contentLoaded = false;
  constructor(
    private msmService: MsmamServiceService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService,
    private overlay: Overlay
  ) {}
  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(
      this.columnNames.map((x) => x.columnDef)
    );
    this.getMediaList();
  }

  getMediaList() {
    this.msmService.getMediaList().subscribe(
      (data) => {
        this.mediaList.data = data["mediaList"];
        this.mediaList.data = this.mediaList.data.sort((a, b) => a.id - b.id);
        setTimeout(() => {
          this.mediaList.sort = this.sort;
          this.mediaList.paginator = this.paginator;
        });

        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      },
      (err) => {
        this.contentLoaded = true;
      }
    );
  }

  add() {
    const dialogRef = this.dialog.open(AddMediaMasterComponent, {
      width: "550px",
      height: "80%",
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        isEditMode: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getMediaList();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.mediaList.filter = filterValue.trim().toLowerCase();
  }

  onSlideToggleChange(row: any, event: any): void {
    row.activeFlag = event.checked ? "Y" : "N";
    if (event.checked) {
      this.msmService.activeInactiveMedia(row.id, row.activeFlag).subscribe(
        (res) => {
          this._snackBar.success(row.mediaName + " is Activated");
          this.getMediaList();
        },
        (err) => {
          this._snackBar.error("Activation Flag Failed to update");
          this.getMediaList();
        }
      );
    } else {
      this.msmService.activeInactiveMedia(row.id, row.activeFlag).subscribe(
        (res) => {
          this._snackBar.error(row.mediaName + " is Deactivated");
          this.getMediaList();
        },
        (err) => {
          this._snackBar.error("Activation Flag Failed to Delete");
          this.getMediaList();
        }
      );
    }
  }
  onEdit(row: any) {
    const dialogRef = this.dialog.open(AddMediaMasterComponent, {
      width: "550px",
      height: "80%",
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        isEditMode: true,
        row: row,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getMediaList();
      }
    });
  }
}
