import { Component, ViewChild } from '@angular/core';
import { AddCorporatorComponent } from './add-corporator/add-corporator.component';
import { MatTableDataSource } from '@angular/material/table';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-corporator-master',
  templateUrl: './corporator-master.component.html',
  styleUrls: ['./corporator-master.component.scss']
})
export class CorporatorMasterComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data:any;
  
  columnNames: any[] = [
    {
      columnDef: 'sno',
      header: 'Sr.No.',
    },
    {
      columnDef: 'nominatedCorporator',
      header: 'Nominated Corporator',
    },
    // {
    //   columnDef: 'nominatedChairperson',
    //   header: 'Nominated Chariperson',
    // },
    {
      columnDef: 'firstName',
      header: 'Name',
    },
    // {
    //   columnDef: 'middleName',
    //   header: 'Middle Name',
    // },
    // {
    //   columnDef: 'lastName',
    //   header: 'Last Name',
    // },
    {
      columnDef: 'castName',
      header: 'Cast Name',
    },
    {
      columnDef: 'subCastName',
      header: 'Sub Cast Name',
    },
    {
      columnDef: 'wardName',
      header: 'Ward Name',
    },
    {
      columnDef: 'electoralWardName',
      header: 'Electoral Ward Name',
    },
    {
      columnDef: 'identificationProofName',
      header: 'Identification Proof Name',
    },
    {
      columnDef: 'religionName',
      header: 'Religion',
    },
    {
      columnDef: 'actions',
      header: 'Actions',
    },
  ];
  departments = new MatTableDataSource<any>();
  displayedColumns: any[] = []
  contentLoaded = false;
  constructor(private msmService: MsmamServiceService,
     private dialog: MatDialog,
     private _snackBar: SnackbarMsgService,) {
  
  }
  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));
    this.getCorporates()
  }
  
  getCorporates() {
    this.msmService.getCorporate().subscribe(
      data => {
        this.departments.data = data['corporateMasterList'];
        this.departments.data = this.departments.data.sort((a, b) => a.id - b.id);
        setTimeout(
          () => {
            this.departments.sort = this.sort;
            this.departments.paginator = this.paginator;
          });
  
        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
  
      },
      err => {
        this.contentLoaded = true;
  
      }
    )
  }
  
  addCorporator() {
    const dialogRef = this.dialog.open(AddCorporatorComponent, {
      width: "1250px",
      data: {
        isEditMode: false,
      },
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getCorporates()
      }
    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.departments.filter = filterValue.trim().toLowerCase();
  }

  onSlideToggleChange(row: any, event: any): void {
    
    row.activeFlag = event.checked ? 'Y' : 'N';
    if (event.checked) {
      this.msmService.activeInactiveCorporate(row.id,row.activeFlag).subscribe(
        res => {
          this._snackBar.success(row.firstName+' is Activated');
          },
        err => {
          this._snackBar.error('Activation Flag Failed to update')
        }
      )
      
    } else {
      this.msmService.activeInactiveCorporate(row.id,row.activeFlag).subscribe(
        res => {
          this._snackBar.error(row.firstName+' is Deactivated');
          this.getCorporates();
          },
        err => {
          this._snackBar.error('Activation Flag Failed to Delete')
        })
    }
   
  }
  onEdit(row:any){
    const dialogRef = this.dialog.open(AddCorporatorComponent, {
      width: "1250px",
      data: {
        isEditMode: true,
        row:row
      },
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getCorporates()
      }
    });
  }
}
