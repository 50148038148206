import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScrutinyService } from 'src/app/_services/CWM/scrutiny.service';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import {Location} from '@angular/common';
import { VendorService } from 'src/app/_services/CWM/vendor.service';

@Component({
  selector: 'app-scrutiny-flow',
  templateUrl: './scrutiny-flow.component.html',
  styleUrls: ['./scrutiny-flow.component.scss']
})
export class ScrutinyFlowComponent {

  allQuotesUploaded : boolean = false
  applicationData : any[] = []
  form!: FormGroup;
  formWorkUpload! : FormGroup
  formInvoiceUpload! : FormGroup
  formPaymentUpload! : FormGroup
  showonAccept : boolean = true
  takeAction : boolean = false
  appId : any = ''
  fileLocation : any = '';
  showPayment:boolean = false;
  status : any = ''
  text : any = ''
  // competitiveQuotes3 : any = ''
  // competitiveQuotes2 : any = ''
  // competitiveQuotes1 : any = ''
  showWorkOrder : boolean = false
  workUpload : any = ''
  formDisabled : boolean = true
  paymentReceipt : any  = ''
  invoice : any = ''
  showReceipt : boolean = false
  showInvoice : boolean = false
  approveRemark : any = ''
  remarkForApprove : any = ''
  vehicleId : any = ''
  driverId: any = ''
  receipt: any = ''
  description: any = ''
  activeFlag: any = ''
  driverName: any = ''
  ammount: any = ''
  appStatus: any = ''
  vehicleRegistrationNo: any = ''
  rejectRemark : any = ''
  contentLoadedSuccess1 : any = true
  loading1 : any = false;
  contentLoadedSuccess2 : any = true
  loading2 : any = false;
  contentLoadedSuccess3 : any = true
  loading3 : boolean = false;
  showImage1 : any = 'N'
  showImage2 : any = 'N'
  showImage3 : any = 'N'
  quote1Url : any = ''
  quote2Url : any = ''
  quote3Url : any = ''
  action : any = ''
  currentStatus : any = ''
  workOrderUrl : any = ''
  loadingWorkOrder : boolean =false
  showImageWorkOrder : any = 'N'
  invoiceUrl : any = ''
  loadinginvoice : boolean =false
  showImageinvoice : any = 'N'
  paymentUrl : any = ''
  paymentInvoice : boolean =false
  showImagePayment : any = 'N'
  loadingPayment : boolean = false

  state : any
  vendors : any[] = []

  showCompetitiveQuote3 : boolean = false
  showCompetitiveQuote2 : boolean = false
  showCompetitiveQuote1 : boolean = false
  venName1 : any = ''
  venName2 : any = ''
  venName3 : any = ''
  selectedQuotes : any = ''
  workOrderUploaded : boolean = false
  invoiceUploaded : boolean = false
  showInvoiceUpload : boolean = true
  paymentUploaded : boolean = false
  showPaymentUpload : boolean = false



  constructor(
  private appScrutinyService: ScrutinyService,
    private fb: FormBuilder,
    private _snackBar : MatSnackBar,
    private dialog : MatDialog,
    private uploadService : FileUploadService,
    private location : Location,
    private vendorsService : VendorService
    ) { }


  ngOnInit(): void {
    this.form = this.fb.group({
      vendorId1: ["",Validators.required],
      vendorId2: ["",Validators.required],
      vendorId3: ["",Validators.required],
      ammount1: ["",Validators.required],
      ammount2: ["",Validators.required],
      ammount3: ["",Validators.required],
      competitiveQuotes1: ["",Validators.required],
      competitiveQuotes2: ["",Validators.required],
      competitiveQuotes3: ["",Validators.required],
      id: [""],
      vendorName1 : [""],
      vendorName2 : [""],
      vendorName3 : [""],
     




    })

    this.formWorkUpload = this.fb.group({
    
      id: [""],
      selectedVendorId : ["",Validators.required],
      selectedvendorName : ["",Validators.required],
      selectedQuote : ["",Validators.required],
      selectedammount : ["",Validators.required],
      workOrder : ["",Validators.required,]




    })

    this.formInvoiceUpload = this.fb.group({
      id: [""],
      invoice : ["",Validators.required]
    })

    this.formPaymentUpload = this.fb.group({
      id: [""],
      paymentReceipt : ["",Validators.required]
    })
    this.state = history.state;
    this.applicationData.push(this.state['applicationData'])

    this.vehicleId = this.applicationData[0]['vehicleId']
    this.driverId = this.applicationData[0]['driverId']
    this.receipt = this.applicationData[0]['receipt']
    this.description = this.applicationData[0]['description']
    this.activeFlag = this.applicationData[0]['activeFlag']
    this.driverName = this.applicationData[0]['driverName']
    this.ammount = this.applicationData[0]['ammount']
    this.appStatus = this.applicationData[0]['status']
    this.vehicleRegistrationNo = this.applicationData[0]['vehicleRegistrationNo']
    this.approveRemark = this.applicationData[0]['approveRemark']
    this.appId = this.applicationData[0]['id']
    // this.competitiveQuotes1 = this.applicationData[0]['competitiveQuotes1']
    // this.competitiveQuotes2 = this.applicationData[0]['competitiveQuotes2']
    // this.competitiveQuotes3 = this.applicationData[0]['competitiveQuotes3']
    this.workUpload= this.applicationData[0]['workOrder']
    this.invoice =  this.applicationData[0]['invoice']
    this.paymentReceipt =  this.applicationData[0]['paymentReceipt']
    this.getVendors()


    if(this.appStatus  == 'APPROVED'){
      this.action = "Upload competitive quotes"
      this.allQuotesUploaded = true
    }


    if(this.appStatus == 'QUOTATION_SUBMITTED'){
      this.action = "Select Quotation and upload work order"
      this.formWorkUpload.patchValue({'vendorId1':this.applicationData[0].vendorId1})
      this.formWorkUpload.patchValue({'vendorId2':this.applicationData[0].vendorId2})
      this.formWorkUpload.patchValue({'vendorId3':this.applicationData[0].vendorId3})
      this.formWorkUpload.patchValue({'vendorName1':this.applicationData[0].vendorName1})
      this.formWorkUpload.patchValue({'vendorName2':this.applicationData[0].vendorName2})
      this.formWorkUpload.patchValue({'vendorName3':this.applicationData[0].vendorName3})
      this.formWorkUpload.patchValue({'competitiveQuotes1':this.applicationData[0].competitiveQuotes1})
      this.formWorkUpload.patchValue({'competitiveQuotes2':this.applicationData[0].competitiveQuotes2})
      this.formWorkUpload.patchValue({'competitiveQuotes3':this.applicationData[0].competitiveQuotes3})
      this.formWorkUpload.patchValue({'ammount1':this.applicationData[0].ammount1})
      this.formWorkUpload.patchValue({'ammount2':this.applicationData[0].ammount2})
      this.formWorkUpload.patchValue({'ammount3':this.applicationData[0].ammount3})
      this.formWorkUpload.patchValue({'id':this.applicationData[0].id})


    }

    if(this.appStatus == 'WORKORDER_SUBMITTED'){
      this.showInvoice = true
      this.action = "Submit Invoice"
      this.formWorkUpload.patchValue({'id':this.applicationData[0].id})

      // this.invoiceUrl = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.invoice
     

    }

    if(this.appStatus == 'INVOICE_SUBMITTED'){
      this.action = "Submit Payment Receipt"
      this.paymentUrl = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.paymentReceipt
      this.formPaymentUpload.patchValue({'id':this.applicationData[0].id})

    }

   

  }

  getVendors(){
    this.vendorsService.getVendors().then((data : any) => {
      this.vendors = JSON.parse(JSON.stringify(data))['vendorList']
    })
  }

  onNoClick(): void {
    // this.dialogRef.close();
  }


  // when status is APPROVED option to upload 3 docs 
  upload(event : any){
    if(event.target.files.length > 3){
      this._snackBar.open('You can select only 3 files', 'Close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
          });
    }else if(event.target.files.length < 3){
      this._snackBar.open('Please select upto 3 files', 'Close', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 3000,
      });
    }else {
      this.loading1 = true
      this.loading2 = true
      this.loading3 = true
      for(var i = 0; i < event.target.files.length;i++){
        let count = i + 1
        if(event.target.files[i].type.includes('image')){
          this.showImage1 = 'Y'
          this.showImage2 = 'Y'
          this.showImage3 = 'Y'

        }else {
          this.showImage1 = 'P'
          this.showImage2 = 'P'
          this.showImage3 = 'P'
          
        }
        this.uploadCompetetiveQuotesFiles(event.target.files[i],count)
      }
    }


  }

  //on upload get path of each uploaded file
  uploadCompetetiveQuotesFiles(file : any,count : any){
 

  this.uploadService.uploadFile(file).then((data : any) => {
         
          // if(count == 1){
          //   // this.competitiveQuotes1 = JSON.parse(data).filePath
          //   this.quote1Url = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.competitiveQuotes1


          // }else if(count == 2){
          //   // this.competitiveQuotes2 = JSON.parse(data).filePath
          //   this.quote2Url = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.competitiveQuotes2

          // }else {
          //   // this.competitiveQuotes3 = JSON.parse(data).filePath
          //   this.quote3Url = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.competitiveQuotes3

          // }

          // if(this.competitiveQuotes1 != '' && this.competitiveQuotes2 != '' && this.competitiveQuotes3 != ''){
           
          //   // this.saveScrutiny()
          //   this.currentStatus = 'DOCS_UPLOADED'
          //   this.formDisabled = false

          // }
        
        })

       

  }

  //when status is QUOTATION_SUBMITTED option to select quotation adn upload work order
  //select quotation
  handleQuoteChange(quote : any){
    
    this.showWorkOrder = true
    this.selectedQuotes = quote
    if(this.selectedQuotes == "Quote1"){
      this.formWorkUpload.patchValue({"id":this.applicationData[0].id})
      this.formWorkUpload.patchValue({"selectedVendorId":this.applicationData[0].vendorId1})
      this.formWorkUpload.patchValue({"selectedvendorName":this.applicationData[0].vendorName1})
      this.formWorkUpload.patchValue({"selectedQuote":this.applicationData[0].competitiveQuotes1})
      this.formWorkUpload.patchValue({"selectedammount":this.applicationData[0].ammount1})

    }

    if(this.selectedQuotes == "Quote2"){
      this.formWorkUpload.patchValue({"id":this.applicationData[0].id})
      this.formWorkUpload.patchValue({"selectedVendorId":this.applicationData[0].vendorId2})
      this.formWorkUpload.patchValue({"selectedvendorName":this.applicationData[0].vendorName2})
      this.formWorkUpload.patchValue({"selectedQuote":this.applicationData[0].competitiveQuotes2})
      this.formWorkUpload.patchValue({"selectedammount":this.applicationData[0].ammount2})

    }

    if(this.selectedQuotes == "Quote3"){
      this.formWorkUpload.patchValue({"id":this.applicationData[0].id})
      this.formWorkUpload.patchValue({"selectedVendorId":this.applicationData[0].vendorId3})
      this.formWorkUpload.patchValue({"selectedvendorName":this.applicationData[0].vendorName3})
      this.formWorkUpload.patchValue({"selectedQuote":this.applicationData[0].competitiveQuotes3})
      this.formWorkUpload.patchValue({"selectedammount":this.applicationData[0].ammount3})

    }

   


  }



  uploadWorkOrder(event : any){
 
    for(var i = 0; i < event.target.files.length; i++){
    
        this.formDisabled = false

      this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
        this.workUpload = JSON.parse(data).filePath
        this.workOrderUrl = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.workUpload
        this.formWorkUpload.patchValue({"workOrder":this.workUpload})

        this.workOrderUploaded = true
      })
    }

  }

  uploadinvoice(event : any){

    for(var i = 0; i < event.target.files.length; i++){
     
        this.formDisabled = false

      this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
       this.invoiceUploaded = true
        this.invoice = JSON.parse(data).filePath
        this.formInvoiceUpload.patchValue({"invoice":this.invoice})
        this.formInvoiceUpload.patchValue({"id":this.applicationData[0].id})


        this.invoiceUrl = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.invoice
        
        
      })
    }

  }

  uploadPaymentReceipt(event : any){
    this.loadingPayment = true

    for(var i = 0; i < event.target.files.length; i++){
      if(event.target.files[i].type.includes('image')){
    
        this.formDisabled = false

      this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
        this.paymentReceipt = JSON.parse(data).filePath
        this.paymentUploaded = true
        this.formPaymentUpload.patchValue({"paymentReceipt" : this.paymentReceipt})
        this.formPaymentUpload.patchValue({"id" : this.applicationData[0].id})
        this.paymentUrl = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.paymentReceipt
       

       
      })
    }
  }

  }

  cancel(){
    this.location.back()

  }

  saveScrutiny(){
    // if(this.appStatus == 'APPROVED'){
    //   this.appScrutinyService.approveApplicationForUploade(this.appId,this.competitiveQuotes1,this.competitiveQuotes2,this.competitiveQuotes3).then((data : any) => {
    //     if(JSON.parse(data).status == "SUCCESS"){
    //       this._snackBar.open('Comptetitive Quotes uploaded successfully', 'Close', {
    //                   horizontalPosition: 'right',
    //                   verticalPosition: 'top',
    //                   duration: 3000,
    //                 });
    //       this.location.back()
    //     }
    //   })
    // }else if(this.appStatus == 'QUOTATION_SUBMITTED'){
    //   this.appScrutinyService.approveApplicationForWorkorder(this.appId,this.selectedQuote,this.workUpload).then((data : any) => {
    //     if(JSON.parse(data).status == "SUCCESS"){
    //       this._snackBar.open('Work Order uploaded successfully', 'Close', {
    //                   horizontalPosition: 'right',
    //                   verticalPosition: 'top',
    //                   duration: 3000,
    //                 });
    //       this.location.back()
    //     }
    //   })
    // }else if(this.appStatus == 'WORKORDER_SUBMITTED'){
    //   this.appScrutinyService.approveApplicationForInvoice(this.appId,this.invoice).then((data : any) => {
    //     if(JSON.parse(data).status == "SUCCESS"){
    //       this._snackBar.open('Invoice uploaded successfully', 'Close', {
    //                   horizontalPosition: 'right',
    //                   verticalPosition: 'top',
    //                   duration: 3000,
    //                 });
    //       this.location.back()
    //     }
    //   })
    // }else if(this.appStatus == 'INVOICE_SUBMITTED'){
    //   this.appScrutinyService.approveApplicationForPayment(this.appId,this.paymentReceipt).then((data : any) => {
    //     if(JSON.parse(data).status == "SUCCESS"){
    //       this._snackBar.open('Payment Receipt uploaded successfully', 'Close', {
    //                   horizontalPosition: 'right',
    //                   verticalPosition: 'top',
    //                   duration: 3000,
    //                 });
    //       this.location.back()
    //     }
    //   })
    // }
  }



  uploadQuote1(event : any){
  
     
      for(var i = 0; i < event.target.files.length;i++){
       
        // this.uploadCompetetiveQuotesFiles(event.target.files[i],count)
        this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
          this.form.patchValue({'competitiveQuotes1' : JSON.parse(data).filePath}) 
            this.showCompetitiveQuote1 = true
            // this.quote1Url = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.competitiveQuotes1
        })
      }
    
  }

  uploadQuote2(event : any){
    for(var i = 0; i < event.target.files.length;i++){
       
      // this.uploadCompetetiveQuotesFiles(event.target.files[i],count)
      this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
        this.form.patchValue({'competitiveQuotes2' : JSON.parse(data).filePath}) 
        this.showCompetitiveQuote2 = true
          // this.quote1Url = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.competitiveQuotes1
      })
    }
  }

  uploadQuote3(event : any){
    for(var i = 0; i < event.target.files.length;i++){
       
      // this.uploadCompetetiveQuotesFiles(event.target.files[i],count)
      this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
        this.form.patchValue({'competitiveQuotes3' : JSON.parse(data).filePath}) 
          this.showCompetitiveQuote3 = true
          // this.quote1Url = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.competitiveQuotes1
      })
    }
  }
  

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  saveScrutinyDetailsForQuotes(){
    // console.log(this.form.value)
    this.form.patchValue({'ammount1':parseFloat(this.form.get('ammount1')?.value).toFixed(2)})
    this.form.patchValue({'ammount2':parseFloat(this.form.get('ammount2')?.value).toFixed(2)})
    this.form.patchValue({'ammount3':parseFloat(this.form.get('ammount3')?.value).toFixed(2)})
    
    this.form.patchValue({'vendorName1':this.venName1})
    this.form.patchValue({'vendorName2':this.venName2})
    this.form.patchValue({'vendorName3':this.venName3})
    this.form.patchValue({'id':this.appId})


if(this.appStatus == 'APPROVED'){
  this.appScrutinyService.applicationApprovalFoeQuotesUpload(this.form.value).subscribe(
    (res) => {
      if(res.status == "SUCCESS"){
        this._snackBar.open('Comptetitive Quotes uploaded successfully', 'Close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 3000,
        });
      this.location.back()
      }
     
    },
    (err) => {

    }
  );
      // this.appScrutinyService.applicationApprovalFoeQuotesUpload(this.form.value).then((data : any) => {
      //   if(JSON.parse(data).status == "SUCCESS"){
      //     this._snackBar.open('Comptetitive Quotes uploaded successfully', 'Close', {
      //                 horizontalPosition: 'right',
      //                 verticalPosition: 'top',
      //                 duration: 3000,
      //               });
      //     this.location.back()
      //   }
      // })
  }
  }

  saveScrutinyDetailsForWorkUpload(){

   

    this.appScrutinyService.approveApplicationForWorkorder(this.formWorkUpload.value).subscribe(
      (res) => {
        if(res.status == "SUCCESS"){
          this._snackBar.open('Work Order uploaded successfully', 'Close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
          });
        this.location.back()
        }
       
      },
      (err) => {
  
      }
    );
    
  }

  saveScrutinyDetailsForInvoiceUpload(){
    this.appScrutinyService.approveApplicationForInvoice(this.formInvoiceUpload.value).subscribe(
      (res) => {
        if(res.status == "SUCCESS"){
          this._snackBar.open('Invoice uploaded successfully', 'Close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
          });
        this.location.back()
        }
       
      },
      (err) => {
  
      }
    );
  }

  saveScrutinyDetailsForPaymentUpload(){
    this.appScrutinyService.approveApplicationForPayment(this.formPaymentUpload.value).subscribe(
      (res) => {
        if(res.status == "SUCCESS"){
          this._snackBar.open('Payment receipt uploaded successfully', 'Close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
          });
        this.location.back()
        }
       
      },
      (err) => {
  
      }
    );
  }

vendorSelected1 : boolean = false
vendorSelected2 : boolean = false
vendorSelected3 : boolean = false

selectVendor1(event : any){
  for(var i = 0; i < this.vendors.length; i++){
    if(event == this.vendors[i].id){
      this.venName1 = this.vendors[i].vendorName
    }
  }

  if(this.form.get('vendorId2')?.value == event || this.form.get('vendorId3')?.value == event){
    this.vendorSelected1 = true
    this.vendorSelected3 = false
    this.vendorSelected2 = false

  }else {
    this.vendorSelected1 = false
    this.vendorSelected2 = false
    this.vendorSelected3 = false


  }

}

selectVendor2(event : any){
  for(var i = 0; i < this.vendors.length; i++){
    if(event == this.vendors[i].id){
      this.venName2 = this.vendors[i].vendorName
    }
  }

  if(this.form.get('vendorId1')?.value == event || this.form.get('vendorId3')?.value == event){
    this.vendorSelected2 = true
    this.vendorSelected1 = false
    this.vendorSelected3 = false
  }else {
    this.vendorSelected1 = false
    this.vendorSelected2 = false
    this.vendorSelected3 = false
  }}

selectVendor3(event : any){
  for(var i = 0; i < this.vendors.length; i++){
    if(event == this.vendors[i].id){
      this.venName3 = this.vendors[i].vendorName
    }
  }

  if(this.form.get('vendorId1')?.value == event || this.form.get('vendorId2')?.value == event){
    this.vendorSelected3 = true
    this.vendorSelected1 = false
    this.vendorSelected2 = false
  }else {
    this.vendorSelected1 = false
    this.vendorSelected2 = false
    this.vendorSelected3 = false
  }
}

  
}
