<div class="flex flex-col">
    <div class="w-full flex flex-row items-center justify-center p-3 bg-[var(--card-header-bg)] text-[var(--card-header-color)]">
        <div class="basis-11/12">
            <div *ngIf="data.title" class="text-2xl font-medium">{{ data.title }}</div>
        </div>
        <div class="basis-1/12 text-right" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>

    <div *ngIf="data.image">
        <img src="{{ data.image }}" alt="Popup Image"/>
    </div>
    <div *ngIf="data.link"
        class="w-full flex flex-row items-center justify-center p-3 bg-[var(--card-header-bg)] text-[var(--card-header-color)]">
        <a href="{{ data.link }}" target="_blank" class="text-[var(--card-header-color)] text-base font-medium">{{ data.linkText }}</a>
    </div>
</div>

