import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { WardService } from 'src/app/_services/ward.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

interface CommitteeMember {
  fromDate: string;
  toDate: string;
  nominatedCorporator: boolean;
  nominatedChairperson: boolean;
  committeeId: number;
  wardId: number;
  wardName:String;
  electoralWardId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  mobile: string;
  email: string;
  dob: string;
  religionId: number;
  casteId: number;
  subCasteId: number;
  partyId: number;
  identificationProofId: number;
  identificationProofNo: number;
  electedDate: string;
  address: string;
  memberChange: string;
  reasonForChange: string;
  activeFlag: string;
  id: null;
  addedit: any;
  createdUserId:any;
  updateUserId:any;

}

@Component({
  selector: 'app-add-committee-members',
  templateUrl: './add-committee-members.component.html',
  styleUrls: ['./add-committee-members.component.scss']
})
export class AddCommitteeMembersComponent implements OnInit {
  committees: any;
  electoralWards: any;
  castes: any;
  wards: any;
  religions: any;
  subCastes: any;
  parties: any;
  identificationProofs:any;
  errorMessage:string="";
  
  userId: string;


  committeeMember: CommitteeMember = {
    fromDate: '',
    toDate: '',
    nominatedCorporator: false,
    nominatedChairperson: false,
    committeeId: 0,
    wardId: 0,
    wardName: '',
    electoralWardId: 0,
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    mobile: '',
    email: '',
    dob: '',
    religionId: 0,
    casteId: 0,
    subCasteId: 0,
    partyId: 0,
    identificationProofId: 0,
    identificationProofNo: undefined,
    electedDate: '',
    address: '',
    memberChange: '',
    reasonForChange: '',
    activeFlag: 'Y',
    createdUserId:null,
    updateUserId:null,
    id: null,
    addedit: null,

  };

  constructor(
    private msmService: MsmamServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCommitteeMembersComponent>,
    public wardService:WardService,
    @Inject(MAT_DIALOG_DATA) public data1: any
  ) {
   
  }

  ngOnInit() {
    this.committeeMember.addedit = this.data1['isEditMode'];
    this.userId = localStorage.getItem("userId");
    this.committeeMember.createdUserId=this.userId;
    this.committeeMember.updateUserId=this.userId;
    if (this.committeeMember.addedit) {
      const data12 = this.data1['row'];
      this.committeeMember.createdUserId=data12['createdUserId'];
    this.committeeMember.updateUserId=this.userId;
      this.loadEditModeData(data12);
    }
    this.getCommittees();
    this.getWards();
    this.getReligion();
    this.getCaste();
    this.getSubCastes();
    this.getElectoralWards();
    this.getParties();
    this.getidentificationProofs();
   
  }
  getCommittees(){
    this.msmService.getByActiveFlagCommittee().subscribe(
      (data) => this.committees = data['committeeMasterList'],
      (err) => this._snackBar.error('committe List is Empty')
    );
  }
  getWards(){
    this.wardService.getWard2().subscribe(
      (data) => this.wards = data['wardMasterList'],
      (err) => this._snackBar.error('Ward List is Empty')
    );
  }
  wardChange(data: any) {
    this.committeeMember.wardId = data;
    this.committeeMember.wardName = this.wards.find((item) => item.id === data)?.wardName || '';
  }
  
  getElectoralWards(){
    this.msmService.getByActiveFlagElectoralward().subscribe(
      (data) => this.electoralWards = data['electoralWardMasterList'],
      (err) => this._snackBar.error('Electoral Ward List is Empty')
    );
  }
  
  getCaste() {
    this.msmService.getByActiveFlagCast().subscribe(
      (data) => this.castes = data['castMasterList'],
      (err) => this._snackBar.error('Cast List is Empty')
    );
  }

  getSubCastes(){
    this.msmService.getByActiveFlagSubcast().subscribe(
      (data) => this.subCastes = data['subCastMasterList'],
      (err) => this._snackBar.error('SubCast List is Empty')
    );
  }
  getParties(){
    this.msmService.getByActiveFlagParty().subscribe(
      (data) => this.parties = data['partyMasterList'],
      (err) => this._snackBar.error('Party List is Empty')
    );
  }
  getidentificationProofs(){
    this.msmService.getByActiveFlagIdentificationproof().subscribe(
      (data) => this.identificationProofs = data['identificationProofMasterList'],
      (err) => this._snackBar.error('Identification Proof List is Empty')
    );
  }
 
  getReligion() {
    this.msmService.getByActiveFlagReligion().subscribe(
      (data) => this.religions = data['religion'],
      (err) => this._snackBar.error('Religion List is Empty')
    );
  }

  religinChange($event) {
    // Handle religion change logic here
  }

  saveMember() {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '550px',
      data: {
        mode: this.committeeMember.addedit ? 'Edit' : 'Add',
        component: 'Committee members'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'success') {
        this.msmService.saveCommitteemembers(this.committeeMember).subscribe(
          res => {
            if (res['status'] === 'Success') {
              setTimeout(() => {
                this.dialogRef.close({ data: 'Success' });
              }, 1000);
            }else{
              this.errorMessage=res['status']
            }
          },
          err => {
            this._snackBar.error('Subcaste not added');
          }
        );
      }
    });
  }

  private loadEditModeData(data12: any): void {
    // Load data in edit mode
    Object.assign(this.committeeMember, data12);
  }
}
