import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class SnackbarMsgService {
  open(arg0: string, arg1: string) {
    throw new Error("Method not implemented.");
  }
  constructor(private _snackBar: MatSnackBar) {}

  error(message: string) {
    return this._snackBar.open(message, undefined, {
      panelClass: ["snackbar-error"],
      horizontalPosition: "end",
      verticalPosition: "top",
      duration: 1500,
    });
  }

  success(message: string) {
    return this._snackBar.open(message, undefined, {
      panelClass: ["snackbar-success"],
      horizontalPosition: "end",
      verticalPosition: "top",
      duration: 1000,
    });
  }

  info(message: string) {
    return this._snackBar.open(message, undefined, {
      panelClass: ["snackbar-info"],
      horizontalPosition: "end",
      verticalPosition: "top",
      duration: 1000,
    });
  }
}
