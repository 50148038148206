import { Component, ViewChild } from '@angular/core';
import { AuthService } from './_services/auth.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nmmc-rts-frontend';
  constructor(private authService: AuthService, private router: Router) {
  }

  toggleSideNav() {
  }
  
  logout() {
    localStorage.clear();
    this.authService.logout();
    this.router.navigate(["/"]);
  }
}


