<div class="bg-gray-100 p-6">
    <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
      close
    </mat-icon>
  
    <div class="mx-auto max-w-md">
      <h1 class="text-2xl font-bold mb-8">{{addedit?'Edit':'Add'}} Committee Establishment</h1>
  
      <form (ngSubmit)="saveIncident()" #commiteeForm="ngForm">
         <mat-form-field class="w-full">
          <mat-label>Enter committee Name Type</mat-label>
          <input matInput [(ngModel)]="committeeName" name="committeeName" required>
        </mat-form-field>

        <div class="relative z-0 w-full mb-5">
          <label class="block text-gray-600">Establishment Date</label>
          <input [(ngModel)]="establishmentDate" type="date" name="establishmentDate"
            class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required="" />           
        </div>

        <div class="relative z-0 w-full mb-5">
          <label class="block text-gray-600">Dismiised Date</label>
          <input [(ngModel)]="dismiisedDate" type="date" name="dismiisedDate"
            class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required="" />           
        </div>
    
        <mat-checkbox [(ngModel)]="isWorking" color="primary" class="example-margin" name="isWorking">IsWorking</mat-checkbox>

        <button type="submit" class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none" 
        [disabled]="!commiteeForm.valid">
          Save
        </button>
        <div class="text-center text-red-500 mt-2">
          {{errorMessage}}
        </div>
      </form>
    </div>
  </div>
  