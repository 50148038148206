import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/_services/Common-Services/common.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-religion-master',
  templateUrl: './add-religion-master.component.html',
  styleUrls: ['./add-religion-master.component.scss']
})
export class AddReligionMasterComponent {

  religionForm: FormGroup;
  addedit: boolean;
  createdUserId: any;
  updateUserId: any;
  userId: string;
  errorMessage: any = '';
  id: any;

  constructor(
    private fb: FormBuilder,
    private religionService: CommonService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddReligionMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.userId = localStorage.getItem('userId');
    this.createdUserId = this.userId;
    this.updateUserId = this.userId;
    this.addedit = this.data['isEditMode'];

    this.religionForm = this.fb.group({
      religionName: ['', Validators.required],
      validYn: ['', Validators.required],
      // Add other form controls as needed
    });

    if (this.addedit) {
      const religionData = this.data['row'];
      this.id = religionData['id'];
      this.createdUserId = religionData['createdUserId'];
      this.updateUserId = this.userId;
      this.religionForm.patchValue({
        religionName: religionData['religionName'],
        validYn: religionData['validYn'],
        // Patch other form controls as needed
      });
    }
  }

  save() {
    if (this.religionForm.valid) {
      const religionData = {
        id: this.id,
        religionName: this.religionForm.value.religionName,
        validYn: this.religionForm.value.validYn,
        // Add other form control values as needed
        createdUserId: this.createdUserId,
        updateUserId: this.updateUserId,
        activeFlag: 'Y'
      };

      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: '550px',
        data: {
          mode: this.addedit ? 'Edit' : 'Add',
          component: 'Religion'
        },
        autoFocus: false,
        restoreFocus: false
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result === 'success') {
          this.religionService.saveReligion(religionData).subscribe(
            (res) => {
              if (res['status'] === 'success') {
                setTimeout(() => {
                  this.dialogRef.close({ data: 'Success' });
                }, 1000);
              } else {
                this.errorMessage = res['status'];
              }
            },
            (err) => {
              this._snackBar.error('Error While adding Religion');
            }
          );
        }
      });
    }
  }
}
