import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-electoral-ward',
  templateUrl: './add-electoral-ward.component.html',
  styleUrls: ['./add-electoral-ward.component.scss']
})
export class AddElectoralWardComponent {
  electoralWardPrefix:string;
  electoralWardName: string = '';
  electoralWardNumber:any;
  fromDate:any;
  toDate:any;
  addedit: any;
  id = '';
  errorMessage:string="";
  createdUserId:any;
  updateUserId:any;
  userId: string;

  constructor(private msmService: MsmamServiceService,
     private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddElectoralWardComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any) {
    
  }
  ngOnInit(){
    this.addedit = this.data1['isEditMode'];
    this.userId = localStorage.getItem("userId");
    this.createdUserId=this.userId;
    this.updateUserId=this.userId;
    if (this.addedit == true) {
      const data12 = this.data1['row'];
      this.createdUserId=data12['createdUserId'];
      this.updateUserId=this.userId;
      this.electoralWardName = data12['electoralWardName'];
      this.electoralWardNumber = data12['electoralWardNumber'];
      this.electoralWardPrefix = data12['electoralWardPrefix'];
      this.fromDate=data12['fromDate'];
      this.toDate=data12['toDate'];
      this.id = data12['id'];
    }


  }
  saveElectoral() {
   
    const data = {
      "id": this.id,
      "electoralWardPrefix": this.electoralWardPrefix,
      "electoralWardName": this.electoralWardName,
      "electoralWardNumber": this.electoralWardNumber,
      "fromDate":this.fromDate ,
      "toDate":this.toDate,
      "createdUserId":this.createdUserId,
      "updateUserId":this.updateUserId,
      "activeFlag": "Y"
    };


    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.addedit ? 'Edit' : 'Add',
        component: 'Electoral Ward'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.msmService.saveElectoralward(data).subscribe(
          res => {
            if (res['status'] === 'Success') {
              setTimeout(() => {
                this.dialogRef.close({ data: 'Success' });
              }, 1000);
            }else{
              this.errorMessage=res['status']
            }
          },
          err => {
            this._snackBar.error('Electoral Ward not added')
          }
        )
      }
    })
  }
}
