import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MsmamServiceService } from "src/app/_services/MSMAM/msmam-service.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";

@Component({
  selector: "app-add-journalist-master",
  templateUrl: "./add-journalist-master.component.html",
  styleUrls: ["./add-journalist-master.component.scss"],
})
export class AddJournalistMasterComponent {
  form: FormGroup = new FormGroup({});
  mediaChannelName: string = "";
  addedit: any;
  id = null;
  mediaChannelNameList: any[] = [];
  mediaList: any[] = [];
  errorMessage: string = "";
  createdUserId: any;
  updateUserId: any;
  userId: string;
  constructor(
    private msmService: MsmamServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddJournalistMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any
  ) {}
  ngOnInit() {
    this.addedit = this.data1["isEditMode"];
    this.userId = localStorage.getItem("userId");
    this.createdUserId = this.userId;
    this.updateUserId = this.userId;
    this.form = this.fb.group({
      // id : [""],
      mediaChannelId: new FormControl(null, [Validators.required]),
      designation: new FormControl(null, [Validators.required]),
      journalistName: new FormControl(null, [Validators.required]),
      contactNumber: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      mediaId: new FormControl(null, [Validators.required]),
      createdUserId: [this.userId],
      updateUserId: [this.userId],
      activeFlag: ["Y"],
    });
    if (this.addedit == true) {
      const data12 = this.data1["row"];
      console.log("data12+++++++++++++", data12);

      this.createdUserId = data12["createdUserId"];
      this.updateUserId = this.userId;
      this.mediaChannelName = data12["mediaChannelId"];
      this.form.get("mediaChannelId").setValue(this.mediaChannelName);
      this.form.get("designation").setValue(data12["designation"]);
      this.form.get("journalistName").setValue(data12["journalistName"]);
      this.form.get("contactNumber").setValue(data12["contactNumber"]);
      this.form.get("address").setValue(data12["address"]);
      this.form.get("mediaId").setValue(data12["mediaId"]);
      this.id = data12["id"];
    }

    this.getMediaAndChannelName();
  }

  getMediaAndChannelName() {
    this.msmService.getMediaAndChannelName().subscribe(
      (res) => {
        this.mediaChannelNameList = res["mediaChannelList"];
        this.mediaList = res["mediaList"];
      },
      (err) => {}
    );
  }

  save() {
    if (this.form.valid) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: "550px",
        data: {
          mode: this.addedit ? "Edit" : "Add",
          component: "Journalist ",
        },
        autoFocus: false,
        restoreFocus: false,
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == "success") {
          let formData = this.form.value;

          if (this.addedit) {
            formData.id = this.id;
          } else {
            formData.id = null;
          }
          this.msmService.saveJournalistDetail(formData).subscribe(
            (res) => {
              if (res["status"] === "Success") {
                setTimeout(() => {
                  this.dialogRef.close({ data: "Success" });
                }, 1000);
              } else {
                this.errorMessage = res["status"];
              }
            },
            (err) => {
              this._snackBar.error("Journalist not added");
            }
          );
        }
      });
    }
  }
}
