import { Component, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MsmamServiceService } from "src/app/_services/MSMAM/msmam-service.service";
import { MatDialog } from "@angular/material/dialog";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { ActivationInactivationConfirmationComponent } from "src/app/master-data/activation-inactivation-confirmation/activation-inactivation-confirmation.component";
import { PropertyTaxService } from "src/app/_services/property-tax/property-tax.service";
import { Overlay } from "@angular/cdk/overlay";
import { AddClerkToCommitteeComponent1 } from "./add-clerk-to-committee/add-clerk-to-committee.component";

@Component({
  selector: "app-clerk-to-committee-master1",
  templateUrl: "./clerk-to-committee-master1.component.html",
  styleUrls: ["./clerk-to-committee-master1.component.scss"],
})
export class ClerkToCommitteeMaster1Component {
  journalistData: any;
  clerkToCommitteeData: any;
  contentLoaded: boolean = false;
  dataSource: MatTableDataSource<any>;
  statusAction: any = "";
  checked: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private propertyService: PropertyTaxService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService,
    private msmService: MsmamServiceService,
    private overlay: Overlay
  ) {}

  columnNames: any[] = [
    {
      columnDef: "sno",
      header: "Sr.No.",
    },
    {
      columnDef: "committeeName",
      header: "Committee Name",
    },
    {
      columnDef: "clerkName",
      header: "Clerk Name",
    },

    {
      columnDef: "action",
      header: "Action",
    },
  ];

  ngOnInit() {
    this.getClerkToCommitteeList();
  }

  getClerkToCommitteeList() {
    this.msmService.getClerkToCommitteeList().subscribe(
      (res) => {
        console.log(res);
        this.clerkToCommitteeData = res["clerkToCommitteeMappingMasterList"];
        this.dataSource = new MatTableDataSource(this.clerkToCommitteeData);
        this.dataSource.paginator = this.paginator;
        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  applyFilter(event: any) {
    const filterValue = event.target.value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  add() {
    const dialogRef = this.dialog.open(AddClerkToCommitteeComponent1, {
      width: "550px",
      height: "80%",
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        isEditMode: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getClerkToCommitteeList();
      }
    });
  }

  onSlideToggleChange(row: any, event: any): void {
    if (event.checked == true) {
      this.statusAction = "Activate";
      row.activeFlag = "Y";
    } else {
      this.statusAction = "Deactivate";
      row.activeFlag = "N";
    }
    const checkFlag = row.activeFlag;
    var formData = {
      activeFlag: row.activeFlag,
    };

    const dialogRef = this.dialog.open(
      ActivationInactivationConfirmationComponent,
      {
        width: "550px",
        data: {
          mode: this.statusAction,
          component: "Clerk to Committee",
        },
        autoFocus: false,
        restoreFocus: false,
      }
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        if (this.statusAction == "Activate") {
          this.msmService
            .activeInactiveClerkToCommittee(row.id, checkFlag)
            .subscribe(
              (res) => {
                this._snackBar.success("Selected Clerk to Committee activated");
                this.getClerkToCommitteeList();
              },
              (err) => {
                this._snackBar.error("Activation Failed");
                this.getClerkToCommitteeList();
              }
            );
        } else {
          this.msmService
            .activeInactiveClerkToCommittee(row.id, checkFlag)
            .subscribe(
              (res) => {
                this._snackBar.success(
                  "Selected Clerk to Committee Deactivated"
                );
                this.checked = !this.checked;
                this.getClerkToCommitteeList();
              },
              (err) => {
                this._snackBar.error("Deactivation Failed");
                this.getClerkToCommitteeList();
              }
            );
        }
      } else {
        this.getClerkToCommitteeList();
      }
    });
  }

  onEdit(row: any) {
    const dialogRef = this.dialog.open(AddClerkToCommitteeComponent1, {
      width: "550px",
      height: "80%",
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        isEditMode: true,
        row: row,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getClerkToCommitteeList();
      }
    });
  }
}
