import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CommonService } from "./Common-Services/common.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentUser = this.isAuthenticatedSubject.asObservable();

  constructor(private commonService : CommonService) {
    this.isAuthenticatedSubject.next(!!localStorage.getItem("token"));
    // this.checkUserRole();  // Ensure to load user roles and menu access on service initialization (added for mapping)

  }

  login(): void {
    this.isAuthenticatedSubject.next(true);
  }

  logout(): void {
    this.isAuthenticatedSubject.next(false);
  }

  isAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  //  added for mapping

  hasAccess(urlSegments: string[]): boolean {
    // Implement your logic to check if the user has access to the route
    // Example logic based on user roles/permissions
    const url = urlSegments.join('/');
    if(this.navigateToMenu(url)){
    // Your actual access check logic here
    // Return true if the user has access, otherwise false
    return true; // Replace with actual access check logic
    }else {
      return false;
    }

    
  
  }

  navigateToMenu(menuUrl: string) {
    const menus = this.getAccessibleMenus(menuUrl);
    if (menus) {
      return true;
    } else {
      return false;

    }
  }

  getAccessibleMenus(menuUrl: string) {
    const menus = this.getMenus();
    this.checkUserRole();
    if (menuUrl && menuUrl !== 'null') {
      const menu = menus.find(menu => menu['url'].includes(menuUrl));
      localStorage.setItem('previousUrl',menuUrl)
      if (menu) {
        return menu;
      }else {
        return false;

      }

   
    } else {
      return false;
    }
   
  }

  getMenus(): string[] {
    const roles = JSON.parse(localStorage.getItem('roles'));
    const menus = roles[0]['menus']

    try {
        return menus ? menus : [];
    } catch (error) {
        return [];
    }
  }

  userMenuAccess : any[] = []
  roleId : any = ''
  userId : any =  localStorage.getItem('userId')

  checkUserRole(){
    // this.commonService.getRolesByUserId(this.userId).subscribe((res : any) => {
    //   let rolesList = []
    //   rolesList.forEach((role => {
    //     this.getMenuListBasedOnRole(role.roleId)
    //   }))

    // })

    this.commonService.getRolesByUserId(this.userId).subscribe((res : any) => {     
        this.checkUserMenuAccess()
    })

  }

  getMenuListBasedOnRole(roleId){
    this.commonService.getMenusByDepartmentIdRoleId(localStorage.getItem('dptId'),roleId).subscribe((res : any) => {
      this.userMenuAccess.push(res)
    })
  }

  checkUserMenuAccess(){
      let menuRoleList = JSON.parse(localStorage.getItem('roles'))
      menuRoleList.forEach(element => {
        element.menus.forEach(menuElement => {
          this.userMenuAccess.push(menuElement)
        })
      });
  }

    //  added for mapping

}
