import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private http: HttpClient) {}
  login(data: any): Observable<any> {
    // Create an HttpParams object to encode the data as query parameters
    const params = new HttpParams()
      .set("userName", data.usrName)
      .set("userPassword", data.usrPassword);

    // Make an HTTP POST request with the query parameters
    return this.http.post(
      `${environment.commonUrl}/api/master/user/authenticateUser?`,
      params
    );
  }

  loginV1(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/user/authenticateUserV1?userName=${data.usrName}&userPassword=${data.usrPassword}`,
      null
    );
  }

  //  added for mapping
  newLogin(data: any): Observable<any> {
    const params = new HttpParams()
      .set("userName", data.usrName)
      .set("userPassword", data.usrPassword);

    return this.http.post(
      `${environment.commonUrl}/api/master/user/authenticateUserMapping?`,
      params
    );
  }

  loginAUthentication(data : any): Observable<any> {
    return this.http.post(
      `${environment.commonUrl}/auth/signin`,
      data
    );
  }
}
