import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivationInactivationConfirmationComponent } from 'src/app/master-data/activation-inactivation-confirmation/activation-inactivation-confirmation.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { MaterialManagementServiceService } from 'src/app/_services/Material-Management/material-management-service.service';
import { AddEditCenterTypeMmComponent } from './add-edit-center-type-mm/add-edit-center-type-mm.component';

@Component({
  selector: 'app-center-type-mm',
  templateUrl: './center-type-mm.component.html',
  styleUrls: ['./center-type-mm.component.scss']
})
export class CenterTypeMmComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  // departments: any[] = [];
  columnNames : any[] = [
    {
      columnDef: 'sno',
      header: 'Sr.No.',
    },
    {
      columnDef : "centerTypeName",
      header : 'Center Type'
    },
    {
      columnDef: 'actions',
      header: 'Actions',
    },
  ]
  centers = new MatTableDataSource<any>();
  displayedColumns : any[] = []
  contentLoaded = false;
  statusAction : any = ''
  checked: boolean = false;



  constructor(private materialManagementService: MaterialManagementServiceService,
    private dialog : MatDialog,
    private _snackBar : SnackbarMsgService
  ) {
  }

  

  ngOnInit(){
    //call this method on component load
    this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));    // pre-fix static
    this.getCenterData();
  }

  ngAfterViewInit() {
    this.centers.sort = this.sort;
  }

  getCenterData(){
    this.materialManagementService.getCenterType().subscribe(res=>{
      this.centers.data = res['centerTypeList'] 
      this.centers.data = this.centers.data.sort((a, b) => a.id - b.id);
        setTimeout(
          () => {
            this.centers.sort = this.sort;
            this.centers.paginator = this.paginator


           });

           setTimeout(() => {
              this.contentLoaded = true;
          }, 2000);
      
  
    })
    // this.DeptService.getDepartment()
    // .subscribe((res)=>{
    //   console.log(res);
    //   this.departments.data = res as DepartmentData[];
    //   console.log(this.departments)

    // })
  }

  capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.centers.filter = filterValue.trim().toLowerCase();
  }

  addCenterType(){

    const dialogRef = this.dialog.open(AddEditCenterTypeMmComponent, {
      width: "550px",
      data: {
        isEditMode: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
          this.getCenterData()
      }
    });

  }

  onEdit(row){
    const dialogRef = this.dialog.open(AddEditCenterTypeMmComponent, {
      width: "550px",
      data: {
        isEditMode: true,
        row:row
      },
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getCenterData()
      }
    });
  }


  onSlideToggleChange(row: any, event: any): void {
  
    if(event.checked == true){
      this.statusAction = 'Activate'
      row.activeFlag = 'Y'
    }else {
      this.statusAction  = 'Deactivate';
      row.activeFlag = 'N'

    }
    var formData = {
      activeFlag : row.activeFlag
    }

    const dialogRef = this.dialog.open(ActivationInactivationConfirmationComponent, {
      width: "550px",
      data: {
        mode : this.statusAction,
        component : 'Center type'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {

      

        if (this.statusAction == 'Activate') {
          this.materialManagementService.changeFlagCenterType(row.id,formData).subscribe(
            res => {
              this._snackBar.success('Selected Center type activated');
              this.getCenterData();

              },
            err => {
              this._snackBar.error('Activation Failed')
              this.getCenterData();

            }
          )
          
        } else {
          this.materialManagementService.changeFlagCenterType(row.id,formData).subscribe(
            res => {
              this._snackBar.success('Selected Center type Deactivated');
              this.checked = !this.checked;
              this.getCenterData();
              
              },
            err => {
              this._snackBar.error('Deactivation Failed')
              this.getCenterData();

            })
        }
      }else {
        this.getCenterData();
      }
    })
   
   
  }
 

  

}







