import { Component, OnInit, ViewChild } from "@angular/core";
import { SnackbarMsgService } from "../shared/Toast-service/snackbar-msg.service";
import { CentralWorkshopServiceService } from "../_services/Central-Workshop-Management/central-workshop-service.service";
import { FileUploadService } from "../_services/file-upload.service";
import { environment } from "src/environments/environment";
import { ViewImgDocComponent } from "../RTS/view-rts-application/view-img-doc/view-img-doc.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { ShowQrCodeComponent } from "./show-qr-code/show-qr-code.component";

@Component({
  selector: "app-qr-code",
  templateUrl: "./qr-code.component.html",
  styleUrls: ["./qr-code.component.scss"],
})
export class QrCodeComponent implements OnInit {
  baseEx =
    "iVBORw0KGgoAAAANSUhEUgAAASwAAAEsAQAAAABRBrPYAAADHklEQVR4Xu2ZPY7jMAyFGbhwmSPoJvHFAjiALxbfxEdIqSIw973HLDDWDDBbbCECZpGxpU8FR/x5ks3/xV7WjvxoJ9bYiTV2Yo2dWGMn1lhg1WD+vD6sXl5WxqcNm6++bBgekmCT+1an18ynZfPdZr1umFuSYDe4BifvJp/1ZMSqXTNh2Kx3sckf5it8HtfXkA3Dz72MDoxRhlUcy4NNjLfbVSMDXx8a3/yHsOwUo3PAFrJff+hzDkyGcbi20lMzpkw4KesfQ5rP27hjEuyDCc9SjOplN5uTYMyWevFHgZOzEyusXsX3JrP6xVRskfrIFj6JQNb73x3rH+PG+PiEV5VOjjvyX6mP168p0zHGN8TW9W3o31YUb0r4PJgM47PKlWOzBjqOwfWwWT1j6nacNGY9kmehGnzbyF3MgbHvjfwBEcNUJJIlh39IvxjKFTy9YbvgmjHoKARRzODuZ1nvGP6q4g7sIUgeCij4/OZRIxHGyTfKrraItXfljjEGU2Dqe07lhwDzjQmPVRj7hF8OzMJJ5D+NrXsPRaL1/WNq2JX9O564YKTjKmYpMB6AovZW5b9qFgswFhwKV8cY3+ApNOBHTyH/5emx9naMUX1IiRNT5CnU+HSIt54xXhBUDa0Q5vpRJWjDsl+Mm7Uh13WwXkJFRcLzyiMHZhLhvF9ivMHgKY+rYx7sEprcqT5mll3qW9beQ7z1jLFVFLzwSIdJnZGwT3eDLMmCweQkdocCCkTBk28Iuq+Z1TPGywB2QDXxIp/ZQ75vVrcYuzaHdC0AT513NVCIun9NgkWRYs1a427gcwmbB4MtEWr8iIJjHsxuBmXFpTkwTjq94s1fUfuQ98fa2zNWqZ3YySloTZEXDdHYV1JgssrrJtigDCqmQyqvblJgldNKfW5bKHG5G7mUApvQKqL5+UueLnF3pgU5MAiozUmEOuftcajzidNpMMVb1efpnRJ90ofREIdJMHq6hRCMI11smywBNlFKXXhLQDmug1IIwcOlX9cYQyv6hT5qoeyyh6Ar+vfi0Cf2q51YYyfW2Ik1dmKNnVhj/xn7A7hiRbR+nOrDAAAAAElFTkSuQmCC";
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  columnNames: any[] = [
    {
      columnDef: "sno",
      header: "Sr.no",
    },
    {
      columnDef: "docName",
      header: "docName",
    },
    {
      columnDef: "docPath",
      header: "Document uploaded",
    },
    {
      columnDef: "qrCode",
      header: "Generated Qr",
    },
  ];

  fileDataList = new MatTableDataSource<any>();
  displayedColumns: any[] = [];
  contentLoaded = false;

  fileLocation: any;
  selectedFile: File = null;
  constructor(
    private snackBar: SnackbarMsgService,
    private cwmService: CentralWorkshopServiceService,
    private uploadService: FileUploadService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(
      this.columnNames.map((x) => x.columnDef)
    );
    this.getPdfList();
  }

  getPdfList() {
    this.uploadService.getPdfAllList().subscribe(
      (res) => {
        console.log(res);
        this.fileDataList.data = res as any;
        this.fileDataList.data = this.fileDataList.data.sort(
          (a, b) => a.id - b.id
        );
        setTimeout(() => {
          this.fileDataList.sort = this.sort;
          this.fileDataList.paginator = this.paginator;
        });
        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.fileDataList.filter = filterValue.trim().toLowerCase();
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.uploadFile();
  }

  uploadFile() {
    const fd = new FormData();
    fd.append("pdfFile", this.selectedFile, this.selectedFile.name);

    this.uploadService.uploadqrFile(this.selectedFile).then((data: any) => {
      console.log(data);
      this.getPdfList();
      this.snackBar.success("PDF uploaded successfully");
      this.fileLocation = JSON.parse(data).filePath;
      console.log(this.fileLocation);
    });
  }

  // openImageModal(imageUrl: any): void {
  //   console.log(this.openImageModal)
  //   const url = environment.commonUrl + '/api/file/preview?filePath=' + imageUrl
  //   const dialogRef = this.dialog.open(ViewImgDocComponent, {
  //     data: url,
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The modal was closed');
  //   });
  // }

  openImageInNewTab(imageUrl: any): void {
    const url =
      environment.commonUrl + "/api/file/preview?filePath=" + imageUrl;
    window.open(url, "_blank");
  }

  openQr(data) {
    console.log(data);
    const dialogRef = this.dialog.open(ShowQrCodeComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
