<div mat-dialog-actions style="float: right;">
  <mat-icon aria-hidden="false" [mat-dialog-close]="true" aria-label="Close" fontIcon="close"></mat-icon>
</div>

<div style="padding: 30px;">
  <h2>Application Details</h2>
  <form class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      <ng-container *ngFor="let item of data | keyvalue">
          <div class="mb-4 text-center" *ngIf="item.value">
              <label for="{{item.key}}" class="text-md font-semibold">{{ item.key | formatkeyvalue }}</label>
              <br>
              <label for="" class="text-center text-gray-500 overflow-y-auto max-w-full">{{item.value}}</label>
          </div>
      </ng-container>
  </form>
</div>
