<footer class="footer text-white p-1">
  <div class="container mx-auto">
    <div class="grid grid-cols-1 text-center">
      <div>
        <!-- <p>Copyright © 2023 NAVI MUMBAI MUNICIPAL CORPORATION</p> -->
         <p>Copyright 2024©NMMC, All Rights Reserved</p>
      </div>
    </div>
  </div>
</footer>
