<div class="flex flex-col drop-shadow-lg p-4 mt-4">
  <div class="flex-shrink-0 w-full sm:w-auto" *ngIf="ugpPrevId === '3'">
    <h1 class="text-2xl font-bold">Welcome , {{ loginName }}</h1>
    <br />
  </div>
  <div class="special-services-container">
    <h1 class="text-2xl font-bold mb-4"><b>Most favourite Services</b></h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-8 gap-4">
      <ng-container>
        <div
          class="bg-white border-l-8 border-l-primary-colors p-8 rounded-lg shadow-md flex flex-col items-center justify-center text-center hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
          [routerLink]="[
            '/online-rts/application-service-form/0192045a-8bc3-7c74-8fe9-a2d180bd3300'
          ]" style="height: 180px">
          <div style="height: 60%; margin-top: 20px">
            <mat-icon svgIcon="navratri-pandal" class="mat-icon1" style="color: #015296"></mat-icon>
          </div>

          <h3 class="text-2xl font-bold mt-4" style="height: 30%">
            NAVRATRI PANDAL PERMISSION
          </h3>
        </div>
      </ng-container>
    </div>
  </div>
  <br />

  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
    <ng-container *ngFor="let item of menuItems">
      <div *ngIf="shouldDisplayItem(item)"
        class="bg-white border-l-8 border-l-primary-colors p-8 rounded-lg shadow-md flex flex-col items-center justify-center text-center hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
        [routerLink]="item.routerLink" style="height: 180px">
        <div style="height: 60%; margin-top: 20px">
          <mat-icon class="mb-2 sm:mb-4" style="color: #015296">
            {{ item.icon }}
          </mat-icon>
        </div>

        <h3 class="text-2xl font-bold mt-4" style="height: 30%">
          {{ item.title }}
        </h3>
      </div>
    </ng-container>
  </div>
  <br />
</div>