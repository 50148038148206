import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TestService {
  constructor(private httpClient: HttpClient) {}

  // serviceWithFormData(param1: any, param2: any) {
  //   const formData = new FormData();
  //   formData.append("param1", param1);
  //   formData.append("param2", param2);
  //   return this.httpClient.patch(environment.apiUrl + "/wsName", formData);
  // }

  // serviceWithBody(param1: any, param2: any) {
  //   let body = {
  //     param1: param1,
  //     param2: param2,
  //   };
  //   return this.httpClient.patch(environment.apiUrl + "/wsName" + body, body);
  // }

  getdepartment(){
    return this.httpClient.get(`${environment.apiUrl}/api/department/getAll`)
  }

  getApplications(data:any){
    return this.httpClient.get(`${environment.apiUrl}/api/application/getAllApplicationsByCitizenId?citizenId=${data}`)
  }

  getServicesDptwise(){
    return this.httpClient.get(`${environment.rtsUrl}/api/master/getServicesDptwiseV1`)
  }
  
}
