import { Component } from '@angular/core';
import { TestService } from '../_services/test.service';
import { MasterService } from '../_services/master.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-master-service',
  templateUrl: './master-service.component.html',
  styleUrls: ['./master-service.component.scss']
})
export class MasterServiceComponent {
  subDept:any;
  deptId:any;
  servicesData:any;
  constructor(private departmentService:MasterService,private route:ActivatedRoute,private router:Router){
    this.getDepartmentData()
  }
  getDepartmentData(){
    this.route.queryParams.subscribe((params) => {
      this.subDept = params['subDept'];
      this.deptId = params['dept'];
    });

    this.departmentService.getservice(this.deptId,this.subDept).subscribe(res=>{
      this.servicesData = res;
    })
    
  }
  
  onCardClick(item: any) {
  const xyz = item.formDetails.toString()
  
  const formDetailsJson = JSON.stringify(item.formDetails);

  // Save the JSON string in localStorage
  localStorage.setItem('formDetails', xyz);
    this.router.navigate(['/application-form', item.id],{ queryParams: { id: item.id } });
    // this.router.navigate(['sub-deptforms'])
  }
}
