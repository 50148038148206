import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { AuthService } from "../_services/auth.service";
import { LoginService } from "../_services/login.service";
import { UsersService } from "../_services/master-data/users.service";
import { SnackbarMsgService } from "../shared/Toast-service/snackbar-msg.service";
import { RtsService } from "../_services/rts.service";
import { MatDialog } from "@angular/material/dialog";
import { InfoPopUpComponent } from "../shared/component/info-pop-up/info-pop-up.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  selectedLoginType: any = "citizen";
  RequestId = localStorage.getItem("reqId");
  userId = localStorage.getItem("userId");
  loginName: any = "";
  isSubmitting: boolean = false;
  services: any[] = [];
  hide: boolean = true;
  returnUrl: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private loginService: LoginService,
    private userService: UsersService,
    private snackbar: SnackbarMsgService,
    private rtsService: RtsService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      usrName: ["", Validators.required],
      usrPassword: ["", Validators.required],
    });

    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl']; // Fetch the returnUrl
    });

    const form1 = {
      requestID: this.RequestId,
      userId: this.userId,
    };
    const formData = new FormData();
    formData.append("requestDtoString", JSON.stringify(form1));
    if (this.RequestId) {
      this.userService.logindigi(formData).subscribe((res) => { });
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.authService.isAuthenticated()) {
          if (event.url === "/login") {
            // this.router.navigate(['/mapped-dashboard']); // Redirect authenticated users from login to dashboard
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(["/dashboard"]); // Stay on dashboard if authenticated
            }
          }
        }
      }
    });

    // Push initial state to prevent back navigation to login page
    history.pushState(null, "", location.href);

    window.onpopstate = () => {
      if (this.authService.isAuthenticated()) {
        // this.router.navigate(['/mapped-dashboard']);
        if (this.returnUrl) {
          this.router.navigate([this.returnUrl]);
        } else {
          this.router.navigate(["/dashboard"]); // Stay on dashboard if authenticated
        }
      } else {
        history.go(1); // Move forward if not authenticated
      }
    };

    if (this.userId) {
      if (this.returnUrl) {
        this.router.navigate([this.returnUrl]);
      } else {
        this.router.navigate(["/dashboard"]); // Stay on dashboard if authenticated
      }      // this.router.navigate(['/mapped-dashboard']);
    }

    this.getAllServices();
    // this.openDialog();
  }

  openDialog() {
    this.dialog.open(InfoPopUpComponent, {
      data: {
        title: 'Ganapati Pandal Registration Closing Soon!',
        image: '/assets/ganpati-banner.jpg',
        // link: 'https://your-link.com', 
        // linkText: 'Click here for more details' 
      },
      width: '800px',
      height: 'auto',
      disableClose: true
    });
  }

  // onSubmit(): void {
  //   if (this.loginForm.valid) {
  //     if (!this.selectedLoginType) {
  //       this._snackBar.open("Please select a login type", "Close", {
  //         horizontalPosition: "right",
  //         verticalPosition: "top",
  //         duration: 3000,
  //       });
  //       return;
  //     }

  //     const formData = {
  //       usrName: this.loginForm.get("usrName")?.value,
  //       usrPassword: this.loginForm.get("usrPassword")?.value,
  //     };

  //     // this.loginService.newLogin(formData).subscribe( //  added for mapping
  //     this.loginService.loginV1(formData).subscribe(
  //       (data) => {
  //         const res = data as any;
  //         if (res["status"] == "Success") {
  //           localStorage.setItem("ugpId", res.data.ugpId);
  //           localStorage.setItem("ugpPrevId", res.data.ugpPrevId);
  //           localStorage.setItem("token", "token");
  //           localStorage.setItem("userId", res.data.usrId);
  //           localStorage.setItem("dptId", res.data.dptId);
  //           localStorage.setItem("wardId", res.data.wardId);
  //           localStorage.setItem("loginName", res.data.usrFullName);
  //           localStorage.setItem("previousDocument", res.data.documents);
  //           localStorage.setItem("designationId", res.data.designationId);
  //           localStorage.setItem("firstName", res.data.firstName);
  //           localStorage.setItem("lastName", res.data.lastName);
  //           localStorage.setItem("mobile", res.data.mobile);
  //           localStorage.setItem("pinCode", res.data.pinCode);
  //           localStorage.setItem("workflow", JSON.stringify(res.workflow));

  //           console.log(res);
  //           // localStorage.setItem('modules',JSON.stringify(res.modules)); //  added for mapping
  //           this.selectedLoginType = res.data.usrName;
  //           this.loginName = res.data.usrFullName;
  //           localStorage.setItem("login", this.selectedLoginType);
  //           this.authService.login();
  //           this.snackbar.success("Logged in");
  //           console.log(res?.data?.isCFCUser);
  //           if (res.data.ugpPrevId == "5") {
  //             // localStorage.setItem('driverId',res.data.driverId)
  //             // this.router.navigateByUrl('/central-workshop/cwm-maintenance')
  //           } else if (res?.data?.isCFCUser == "true") {
  //             this.router.navigateByUrl("/cfc");
  //           } else {
  //             this.router.navigate(["/dashboard"]);
  //             // this.router.navigate(['/mapped-dashboard']); //added for mapping
  //           }

  //           if (res.data.ugpPrevId == "15") {
  //             this.router.navigateByUrl("/qr-code");
  //           }

  //           this.snackbar.success("Login success");
  //         } else {
  //           this.snackbar.error("Invalid Username or Password");
  //         }
  //       },
  //       (err) => {
  //         this.snackbar.error("Login failed");
  //       }
  //     );
  //   }
  // }

  onSubmit(): void {
    this.loginForm.markAllAsTouched();

    if (this.loginForm.valid) {
      if (!this.selectedLoginType) {
        this._snackBar.open("Please select a login type", "Close", {
          horizontalPosition: "right",
          verticalPosition: "top",
          duration: 3000,
        });
        return;
      }

      const formData = {
        userName: this.loginForm.get("usrName")?.value,
        userPassword: this.loginForm.get("usrPassword")?.value,
      };

      this.loginService.loginAUthentication(formData).subscribe(
        //  added for mapping
        // this.loginService.loginV1(formData).subscribe(
        (data) => {
          const res = data as any;
          // if (res["status"] == "Success") {
          localStorage.setItem("ugpId", res.ugpId);
          localStorage.setItem("ugpPrevId", res.ugpPrevId);
          localStorage.setItem("token", res.token);
          localStorage.setItem("userId", res.id);
          localStorage.setItem("dptId", res.dptId);
          localStorage.setItem("wardId", res.wardId);
          localStorage.setItem("loginName", res.fullName);
          localStorage.setItem("previousDocument", res.previousDocument);
          localStorage.setItem("designationId", res.designationId);
          localStorage.setItem("firstName", res.firstName);
          localStorage.setItem("lastName", res.lastName);
          localStorage.setItem("mobile", res.mobile);
          localStorage.setItem("pinCode", res.pinCode);
          localStorage.setItem("workflow", JSON.stringify(res.workflow));



          //  added for mapping
          let roleAccess = res.roles
          if (res.ugpPrevId == "3") {
            localStorage.setItem("showPandalService", "true");

          }
          localStorage.setItem("roles", JSON.stringify(res.roles));

          localStorage.setItem("authorities", JSON.stringify(res.authorities));
          localStorage.setItem("workflow", JSON.stringify(res.workflows));
          this.selectedLoginType = res.username;
          this.loginName = res.fullName;
          localStorage.setItem("login", this.selectedLoginType);
          this.authService.login();
          this.snackbar.success("Logged in");
          let modules = [];
          let dashboard = [];

          if (res.roles.length != 0) {
            let rolePrevId = "";
            res.roles.forEach((element) => {
              element.menus.forEach((menuEelement) => {
                rolePrevId = menuEelement.rolePrevId;
                if (menuEelement.menuFlag == "D") {
                }
                modules.push({
                  moduleId: menuEelement.moduleId,
                  moduleName: menuEelement.moduleName,
                  url: menuEelement.url,
                });
                if (menuEelement.menuFlag == "D") {
                  dashboard.push({
                    moduleId: menuEelement.moduleId,
                    moduleName: menuEelement.moduleName,
                    url: menuEelement.url,
                  });
                }

                localStorage.setItem("dashboard", JSON.stringify(dashboard));
              });
              modules = modules.filter(
                (module, index, self) =>
                  index ===
                  self.findIndex((m) => m.moduleId === module.moduleId)
              );
              localStorage.setItem("modules", JSON.stringify(modules));
            });
            localStorage.setItem("rolePrevId", rolePrevId);
          }

          if (res.ugpPrevId == "5") {
          } else if (res?.data?.isCFCUser == "true") {
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigateByUrl("/cfc");
            }
          } else {
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(["/dashboard"]); // Stay on dashboard if authenticated
            }            // this.router.navigate(['/mapped-dashboard']); //added for mapping
          }

          if (res.ugpPrevId == "15") {
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigateByUrl("/qr-code");
            }
          }

          this.snackbar.success("Login success");
        },
        (err) => {
          this.snackbar.error("Invalid Username or Password");
        }
      );
    }
  }

  oddIndexData: any[] = [];
  evenIndexData: any[] = [];
  getAllServices() {
    this.rtsService.getServiceByActiveFlag().subscribe((res: any) => {
      if (res) {
        this.services = res.service; // Directly assign all services
      }
    });
  }

  togglePasswordVisibility() {
    this.hide = !this.hide;
  }
}
