<mat-card [class.mat-elevation-z8]="true" style="margin: 1.4rem">
  <mat-card-content>
    <div class="w-full p-4">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold">Journalist</h2>
        </div>
        <div class="mt-5 flex lg:ml-4 lg:mt-0">
          <span class="sm:ml-3">
            <button
              type="button"
              (click)="add()"
              class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-lg px-2 py-2.5 text-center inline-flex items-center mr-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5 mr-2 -ml-1 mb-2"
                fill="currentColor"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 18 18"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v12m6-6H6"
                ></path>
              </svg>
              Add Journalist Details
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="w-full p-4">
      <mat-form-field class="searchBox" appearance="outline">
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
          #input
        />
      </mat-form-field>
      <table
        mat-table
        [dataSource]="dataSource"
        *ngIf="journalistData?.length > 0 && contentLoaded"
      >
        <ng-container matColumnDef="srNo">
          <th mat-header-cell *matHeaderCellDef>Sr. No.</th>
          <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
        </ng-container>

        <ng-container matColumnDef="mediaChannelName">
          <th mat-header-cell *matHeaderCellDef>Media Channel Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.mediaChannelName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="mediaName">
          <th mat-header-cell *matHeaderCellDef>Media Name</th>
          <td mat-cell *matCellDef="let element">{{ element.mediaName }}</td>
        </ng-container>
        <ng-container matColumnDef="journalistName">
          <th mat-header-cell *matHeaderCellDef>Journalist Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.journalistName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="designation">
          <th mat-header-cell *matHeaderCellDef>Designation</th>
          <td mat-cell *matCellDef="let element">
            {{ element.designation }}
          </td>
        </ng-container>
        <ng-container matColumnDef="contactNumber">
          <th mat-header-cell *matHeaderCellDef>Contact Number</th>
          <td mat-cell *matCellDef="let element">
            {{ element.contactNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let element">
            {{ element.address }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [checked]="element.activeFlag === 'Y'"
              color="primary"
              (change)="onSlideToggleChange(element, $event)"
            ></mat-slide-toggle>
            <button
              mat-icon-button
              (click)="onEdit(element)"
              matTooltip="Edit Job"
              [disabled]="element.activeFlag === 'N'"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="[
            'srNo',
            'mediaChannelName',
            'mediaName',
            'journalistName',
            'designation',
            'contactNumber',
            'address',
            'Action'
          ]"
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            columns: [
              'srNo',
              'mediaChannelName',
              'mediaName',
              'journalistName',
              'designation',
              'contactNumber',
              'address',
              'Action'
            ]
          "
        ></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[20, 40, 60]"
        showFirstLastButtons
      ></mat-paginator>
      <ngx-skeleton-loader
        *ngIf="!contentLoaded"
        count="4"
        appearance="line"
        animation="progress-dark"
      ></ngx-skeleton-loader>
      <h3 *ngIf="journalistData?.length == 0 && contentLoaded">
        No data available.
      </h3>
    </div>
  </mat-card-content>
</mat-card>
