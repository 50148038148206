<div class="bg-gray-100 p-6">
    <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" (click)="dialogRef.close()">
      close
    </mat-icon>
    <div class="mx-auto max-w-md">
  
      <h1 class="text-2xl font-bold mb-8">{{ addedit ? 'Edit' : 'Add' }} Religion</h1>
  
      <form (ngSubmit)="save()" [formGroup]="religionForm">
        <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-3">
          <mat-form-field class="w-full">
            <mat-label>Religion Name</mat-label>
            <input matInput formControlName="religionName" required />
            <mat-error *ngIf="religionForm.get('religionName').hasError('required')">
              Religion Name is required.
            </mat-error>
          </mat-form-field>
  
          <mat-form-field class="w-full">
            <mat-label>Valid</mat-label>
            <mat-select formControlName="validYn" required>
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
            <mat-error *ngIf="religionForm.get('validYn').hasError('required')">
              Valid is required.
            </mat-error>
          </mat-form-field>
  
          <!-- Add other form controls as needed -->
  
        </div>
        <button type="submit"
                class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none">
          Save
        </button>
        <div class="text-center text-red-500 mt-2">
          {{ errorMessage }}
        </div>
      </form>
    </div>
  </div>
  