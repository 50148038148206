import { Overlay } from '@angular/cdk/overlay';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { CommonService } from 'src/app/_services/Common-Services/common.service';
import { ActivationInactivationConfirmationComponent } from '../activation-inactivation-confirmation/activation-inactivation-confirmation.component';
import { AddEditSectorMasterComponent } from '../sector-master/add-edit-sector-master/add-edit-sector-master.component';
import { AddEditTitleMasterComponent } from './add-edit-title-master/add-edit-title-master.component';

@Component({
  selector: 'app-title-master-common',
  templateUrl: './title-master-common.component.html',
  styleUrls: ['./title-master-common.component.scss']
})
export class TitleMasterCommonComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  columnNames: any[] = [
    {
      columnDef: 'sno',
      header: 'Sr.No.',
    },

    {
      columnDef: "titleName",
      header: "Title"
    },

    {
      columnDef: 'actions',
      header: 'Actions',
    },
  ]

  title = new MatTableDataSource<any>();
  displayedColumns: any[] = []
  contentLoaded = false;
  statusAction: any = ''
  checked: boolean = false;

  constructor(private commonService: CommonService,
    private dialog: MatDialog,
    private overlay: Overlay,
    private _snackBar: SnackbarMsgService) {
  }

  ngOnInit() {
    //call this method on component load
    this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));    // pre-fix static
    this.getTitleData();
  }

  getTitleData() {
    this.commonService.getTitle().subscribe((data: any) => {
      this.title.data = data['titleMasterList']
      this.title.data = this.title.data.sort((a, b) => a.id - b.id);
      // this.sectors.data = this.sectors.data.sort((a, b) => a.id - b.id);
      setTimeout(
        () => {
          this.title.sort = this.sort;
          this.title.paginator = this.paginator;
        });
      setTimeout(() => {
        this.contentLoaded = true;
      }, 2000);

    })

  }

  capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.title.filter = filterValue.trim().toLowerCase();
  }

  addTitle() {

    const dialogRef = this.dialog.open(AddEditTitleMasterComponent, {
      width: "550px",
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        isEditMode: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getTitleData()
      }
    });

  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditTitleMasterComponent, {
      width: "550px",
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        isEditMode: true,
        row: row
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getTitleData()
      }
    });
  }


  onSlideToggleChange(row: any, event: any): void {


    if (event.checked == true) {
      this.statusAction = 'Activate'
      row.activeFlag = 'Y'
    } else {
      this.statusAction = 'Deactivate';
      row.activeFlag = 'N'

    }
    var formData = {
      activeFlag: row.activeFlag
    }

    const dialogRef = this.dialog.open(ActivationInactivationConfirmationComponent, {
      width: "550px",
      data: {
        mode: this.statusAction,
        component: 'Title'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {

        if (this.statusAction == 'Activate') {
          this.commonService.activateInactivateTitle(row.id, formData).subscribe(
            res => {
              this._snackBar.success('Selected Title activated');
              this.getTitleData();

            },
            err => {
              this._snackBar.error('Activation Failed')
              this.getTitleData();

            }
          )

        } else {
          this.commonService.activateInactivateTitle(row.id, formData).subscribe(
            res => {
              this._snackBar.info('Selected Title Deactivated');
              this.checked = !this.checked;
              this.getTitleData();

            },
            err => {
              this._snackBar.error('Deactivation Failed')
              this.getTitleData();

            })
        }
      } else {
        this.getTitleData();
      }
    })


  }

}





