import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScrutinyService {

  constructor(private httpClient : HttpClient) { }

  getApplications(){
    var url = `${environment.cwmUrl}` + '/api/transaction/vehicle/getAllMaintainance'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  getMaintenanceApplicationsForDriver(driverId : any){
    var url = `${environment.cwmUrl}` + '/api/transaction/vehicle/getAllMaintainanceByDriverId?driverId=' + driverId

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  
  

  saveScrutiny(appId : any,vehicleId : any,driverId : any,receipt : any,description : any,
    activeFlag : any,driverName : any,ammount : any,approveRemark : any,
    status : any,vehicleRegistrationNo : any,competitiveQuotes1 : any,competitiveQuotes2 : any,
    competitiveQuotes3 : any,selectedQuote : any,workOrder : any,invoice : any,paymentReceipt : any,rejectRemark : any){
    let body = {}
    
    body = {
      id : appId,
      vehicleId : vehicleId,
      driverId : driverId,
      receipt : receipt,
      description : description,
      activeFlag : activeFlag,
      driverName : driverName,
      ammount : ammount,
      approveRemark : approveRemark,
      status : status,
      vehicleRegistrationNo : vehicleRegistrationNo,
      competitiveQuotes1 : competitiveQuotes1,
      competitiveQuotes2 :competitiveQuotes2,
      competitiveQuotes3 : competitiveQuotes3,
      selectedQuote : selectedQuote,
      workOrder : workOrder,
      invoice : invoice,
      paymentReceipt : paymentReceipt,
      rejectRemark : rejectRemark
    }

    // const formData = new FormData();
    // formData.append("mstDepartmentDao", JSON.stringify(body));

    var url = `${environment.cwmUrl}` + '/api/transaction/vehicle/saveMaintainanceRecords'

    return new Promise(resolve => {
      this.httpClient.post(url,body).toPromise().then(res => {
        resolve(res);

      }).catch((err : any) => {
        resolve(err);

      });
    })

  }

  approveApplication(appId : any,approveRemark : any){
    let body = {}

   
    body = {
      id : appId,
      approveRemark : approveRemark          
    }

    const formData = new FormData();
    formData.append("requestDtoString", JSON.stringify(body));

    var url = `${environment.cwmUrl}` + '/api/transaction/vehicle/approveApplication'

    return new Promise(resolve => {
      this.httpClient.post(url,body,{responseType:'text'}).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })

  }

  rejectApplication(appId : any,rejectRemark : any){
    let body = {}

   
    body = {
      id : appId,
      rejectRemark : rejectRemark          
    }

    const formData = new FormData();
    formData.append("requestDtoString", JSON.stringify(body));

    var url = `${environment.cwmUrl}` + '/api/transaction/vehicle/rejectApplication'

    return new Promise(resolve => {
      this.httpClient.post(url,body,{responseType:'text'}).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })


  }

  applicationApprovalFoeQuotesUpload(data: any): Observable<any> {
   
    return this.httpClient.post(`${environment.cwmUrl}/api/transaction/vehicle/approveApplication`, data);
  }

  approveApplicationForUploade(appId : any,competitiveQuotes1 : any,competitiveQuotes2 : any,competitiveQuotes3 : any){
    let body = {}

   
    body = {
      id : appId,
      competitiveQuotes1 : competitiveQuotes1,
      competitiveQuotes2 : competitiveQuotes2,
      competitiveQuotes3 : competitiveQuotes3        
    }

    const formData = new FormData();
    formData.append("requestDtoString", JSON.stringify(body));

    var url = `${environment.cwmUrl}` + '/api/transaction/vehicle/approveApplication'

    return new Promise(resolve => {
      this.httpClient.post(url,body,{responseType:'text'}).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })

  }

  approveApplicationForWorkorder(data: any): Observable<any>{
    return this.httpClient.post(`${environment.cwmUrl}/api/transaction/vehicle/approveApplication`, data);


  }

  approveApplicationForInvoice(data: any): Observable<any>{
    return this.httpClient.post(`${environment.cwmUrl}/api/transaction/vehicle/approveApplication`, data);


  }

  approveApplicationForPayment(data: any): Observable<any>{
    return this.httpClient.post(`${environment.cwmUrl}/api/transaction/vehicle/approveApplication`, data);


  }

  // approveApplicationForInvoice(appId : any,invoice : any){
  //   let body = {}

   
  //   body = {
  //     id : appId,
  //     invoice : invoice,
  //   }

  //   const formData = new FormData();
  //   formData.append("requestDtoString", JSON.stringify(body));

  //   var url = `${environment.cwmUrl}` + '/api/transaction/vehicle/approveApplication'

  //   return new Promise(resolve => {
  //     this.httpClient.post(url,body,{responseType:'text'}).toPromise().then(res => {
  //       resolve(res);
  //     }).catch((err : any) => {
  //       resolve(err);
  //     });
  //   })

  // }

  // approveApplicationForPayment(appId : any,paymentReceipt : any){
  //   let body = {}

   
  //   body = {
  //     id : appId,
  //     paymentReceipt : paymentReceipt,
  //   }

  //   const formData = new FormData();
  //   formData.append("requestDtoString", JSON.stringify(body));

  //   var url = `${environment.cwmUrl}` + '/api/transaction/vehicle/approveApplication'

  //   return new Promise(resolve => {
  //     this.httpClient.post(url,body,{responseType:'text'}).toPromise().then(res => {
  //       resolve(res);
  //     }).catch((err : any) => {
  //       resolve(err);
  //     });
  //   })

  // }
}
